import { useTranslation } from 'react-i18next';
import { Button, Image, Modal } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import DataTable from 'components/DataTable';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ButtonDataTableTypeItems} from 'constant/Types/DataTableConfigType';
import { useLocation, useNavigate } from 'react-router';
import { ListPayloadType } from 'constant/Types/PermitRequest';
import Toast from 'components/Toast';
import { transformError } from 'utils/ErrorTransformer';
import { hasPermission } from 'utils/Permission';
import { EditFilled } from '@ant-design/icons';
import { IoMdTrash } from 'react-icons/io';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useDelPermitTypeMutation, useListPermitTypePaginationMutation } from 'api/PermitType';
import ModalForm from './ModalForm';

const PermitType = () => {
  const [permitTypeList, {
    error: errorPermitTypeList,
    isError: isErrorPermitTypeList,
    isLoading,
  }] = useListPermitTypePaginationMutation();
  const [delPermitTypeMutation] = useDelPermitTypeMutation();

  const { pathname } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState({
    total: 0,
    permitTypes: []
  });
  const [payload, setPayload] = useState<ListPayloadType>({
    page: 1,
    pageSize: 50,
    search: '',
    sort: 'name'
  });
  const [canCreate, setCanCreate] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [dataUpdate, setDataUpdate] = useState<any>(null);

  const fetchList = async () => {
    let convertPayload:any = payload;
    let newPayload:any = {};
    for (const key in convertPayload) {
      if (Array.isArray(convertPayload[key])) {
        if (convertPayload[key].length) {
          newPayload[key] = convertPayload[key];
        }
      } else if (convertPayload[key]){
        newPayload[key] = convertPayload[key];
      }
    }

    let listPermitName = await permitTypeList(newPayload).unwrap();
    listPermitName = {
      ...listPermitName, 
      permitTypes: listPermitName?.rows?.map((el: any, idx: any) => ({...el, key: `${idx}a`}))
    };
    setData(listPermitName);
  };

  const handleMutationDelete = async (record: any) => {
    await delPermitTypeMutation(record.id).unwrap()
      .then(() => {
        const dataAfterDelete = data.permitTypes?.filter((item: any) => item.id !== record.id);
        setData((prev: any) => ({
          ...prev,
          total: prev.total - 1,
          permitTypes: dataAfterDelete,
        }));
        toast.success(
          <Toast
            message={t('general.success_text')}
            detailedMessage={t('permit_type.success_delete')}
          />
        );
      })
      .catch((e: any) => {
        toast.error(
          <Toast
            message={t('general.error_text')}
            detailedMessage={e?.data?.message || t('permit_type.failed_delete')}
          />
        );
      });
  };

  const handleClickDelete = (record: any) => {
    Modal.confirm({
      title: t('permit_type.delete_permit_type'),
      icon: <ExclamationCircleFilled />,
      okText: t('manage_group.yes_btn'),
      okType: 'danger',
      cancelText: t('manage_group.no_btn'),
      async onOk() {
        handleMutationDelete(record);
      },
      onCancel() { },
    });
  };

  const handleShowModal = (record: any = null) => {
    if(record){
      setDataUpdate(record);
      setShowModal(false);
      setShowModalUpdate(true);
    }
    else{
      setShowModalUpdate(false);
      setShowModal(true);
    }
  };

  useEffect(() => {
    const paths = pathname.split('/');
    if (!hasPermission(paths[1]).can_view) {
      navigate('/403');
    }
    setCanCreate(hasPermission(paths[1]).can_create);
    setCanEdit(hasPermission(paths[1]).can_update);
    setCanDelete(hasPermission(paths[1]).can_delete);
  }, []);

  useEffect(() => {
    fetchList();
  }, [payload]);

  useEffect(() => {
    if (isErrorPermitTypeList){
      const errorMsg:any = errorPermitTypeList;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorPermitTypeList]);

  const buttonConfig: ButtonDataTableTypeItems = [
    {
      name: t('general.add_permit_type'),
      link: '',
      className: 'bg-[#55A853] text-white font-bold',
      onClick: () => handleShowModal()
    }
  ];

  const columns: ColumnsType<any> = [
    {
      title: 'Action',
      key: 'action',
      width: 100,
      render: (_, record) => (
        <div className='flex'>
          {canEdit && (
            <Button
              type='text'
              className='shadow-md shadow-gray-400 rounded-sm mr-3 p-0'
              onClick={() => handleShowModal(record)}
            >
              <EditFilled style={{ fontSize: '20px' }} className='text-[#55A853] p-1' />
            </Button>
          )}
          {canDelete && record?.total_permit_name <= 0 && (
            <Button
              type='text'
              className='shadow-md shadow-gray-400 rounded-sm mr-3 p-0'
              onClick={() => handleClickDelete(record)}
            >
              <IoMdTrash style={{ fontSize: '28px' }} className='text-[#55A853] p-1' />
            </Button>
          )}
        </div>
      ),
    },
    {
      title: 'Permit Type Name',
      dataIndex: 'name',
      key: 'name',
      render: (data) => {
        return (<div>{data}</div>);
      }
    },
    {
      title: 'File',
      dataIndex: 'image',
      key: 'image',
      render: (_data, record: any) => {
        return (
          <div className='flex gap-2 items-center'>
            {record?.image && (
              <Image
                width={75}
                height={75}
                src={`${process.env.REACT_APP_BASE_URL}images/${record?.image}`}
                alt={record?.name}
              />
            )}
          </div>
        );
      }
    },
    {
      title: 'Total Permit Name',
      dataIndex: 'total_permit_name',
      key: 'total_permit_name',
      render: (data) => {
        return (<div>{data}</div>);
      }
    },
  ];

  return (
    <div className='w-full'>
      <DataTable
        className=''
        columns={columns}
        data={data.permitTypes}
        buttonConfig={canCreate ? buttonConfig : []}
        loading={isLoading}
        totalData={data.total}
        payload={{
          payload: payload,
          setPayload: setPayload
        }}
        modalOpen={false}
      />
      <ModalForm
        modalAction={{
          showModal: showModal,
          setShowModal: setShowModal
        }}
        callbackSuccess={fetchList}
      />
      <ModalForm
        dataUpdate={dataUpdate}
        modalAction={{
          showModal: showModalUpdate,
          setShowModal: setShowModalUpdate
        }}
        callbackSuccess={fetchList}
      />
    </div>
  );
};

export default PermitType;