import { Base } from './Base';
import { FeedbackForm, GetFeedbackParams } from 'constant/Types/FeedbackQuestion';

const FeedbackQuestion = Base.injectEndpoints({
  endpoints: (build) => ({
    listFeedbackQuestion: build.mutation({
      query: (params) => ({
        url: '/feedback-questions/current',
        method: 'GET',
        params
      })
    }),
    getFeedbackQuestion: build.mutation({
      query: (params: GetFeedbackParams) => ({
        url: '/feedback-questions',
        method: 'GET',
        params
      })
    }),
    postFeedbackQuestion: build.mutation({
      query: (body: FeedbackForm) => ({
        url: '/feedback-questions',
        method: 'POST',
        body
      })
    }),
  })
});

export const { 
  useListFeedbackQuestionMutation,
  useGetFeedbackQuestionMutation,
  usePostFeedbackQuestionMutation
} = FeedbackQuestion;