import { Base } from './Base';
import { ListPayloadType } from 'constant/Types/Notification';

const Notification = Base.injectEndpoints({
  endpoints: (build) => ({
    totalUnread: build.mutation({
      query: () => ({
        url: '/notifications/unread',
        method: 'GET'
      })
    }),
    notificationList: build.mutation({
      query: (params: ListPayloadType) => ({
        url: '/notifications',
        method: 'GET',
        params
      })
    }),
    markRead: build.mutation({
      query: (body: any) => ({
        url: '/notifications/is-read',
        method: 'PUT',
        body
      })
    }),
  })
});

export const { 
  useTotalUnreadMutation,
  useNotificationListMutation,
  useMarkReadMutation } = Notification;