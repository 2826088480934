import Toast from 'components/Toast';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Dragger from 'antd/es/upload/Dragger';
import { downloadFile, downloadFileUrl, getBorderAttachmentStep, getColorAttachmentStep } from 'utils/Utility';
import { FileOutlined, CloseOutlined } from '@ant-design/icons';
import { Form, type UploadFile, Spin } from 'antd';
import { TYPEUPLOAD, MAXFILESIZE } from 'constant';
import { useEffect, useState } from 'react';
import { RiShareBoxFill } from 'react-icons/ri';
import { useDelAttachmentMutation, useGetDetailAttachmentMutation, usePostAttachmentMutation } from 'api/ComplianceMonitoring';
import { transformError } from 'utils/ErrorTransformer';
import { useNavigate } from 'react-router';

interface IDocumentSectionProps{
  id: string;
}

const DocumentSection = ({id}: IDocumentSectionProps) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const attachments = Form.useWatch('attachments', form);
  const existing = Form.useWatch('existing', form);
  const initialForm = {
    attachments: [],
    existing: [],
  };
  const [dataDetail, setDataDetail] = useState<any>(null);

  const [getDetail, {
    isLoading: isLoadingGetDetail,
  }] = useGetDetailAttachmentMutation();
  const [postAttachment] = usePostAttachmentMutation();
  const [delAttachment] = useDelAttachmentMutation();

  const uploadImageReqDocs = async (options: any) => {
    const reader = new FileReader();
    const { onSuccess, file } = options;
    let fileUpload;
    let newDynamicReqDocs = form.getFieldsValue()?.attachments;

    const idxC = newDynamicReqDocs?.length;
    // step 1: uploading, step 3: success
    newDynamicReqDocs[idxC] = {file: file, step: 1, id: null, isLoading: true};
    form.setFieldsValue({
      ...form,
      attachments: newDynamicReqDocs
    });
    setTimeout(() => {
      reader.onload = async (e) => {
        fileUpload = e?.target?.result || null;
        const payload = {
          permit_request_compliance_attachment: {
            permit_request_compliance_id: id,
            attachment_file: fileUpload,
            filename: file.name
          }
        };
        await postAttachment(payload).unwrap().then((response: any) => {
          let newDynamicReqDocsSuccess = form.getFieldsValue()?.attachments;
          newDynamicReqDocsSuccess[idxC].step = 3;
          newDynamicReqDocsSuccess[idxC].id = response?.id;
          newDynamicReqDocsSuccess[idxC].isLoading = false;
          form.setFieldsValue({
            ...form,
            attachments: newDynamicReqDocsSuccess
          });
          onSuccess('Ok');
        }).catch((e: any) => {
          const errorMsg:any = e;
          toast.error(
            <Toast 
              message={`${t('permit_request.list_doc.failed_upload')} ${file?.name}`} 
              detailedMessage={`${transformError(errorMsg?.data).message}`} 
            />);

          let newDynamicReqDocsError = form.getFieldsValue();
          newDynamicReqDocsError.attachments?.splice(idxC, 1);
          newDynamicReqDocsError.attachmentFileData?.fileList.splice(idxC, 1);
          if (!newDynamicReqDocsError.attachmentFileData?.fileList.length) {
            newDynamicReqDocsError.attachmentFileData = [];
          }
          form.setFieldsValue(newDynamicReqDocsError);
          form.setFieldsValue({
            ...form,
            attachments: newDynamicReqDocsError.attachments,
            attachmentFileData: newDynamicReqDocsError.attachmentFileData
          });
        });
        
      };
      reader.readAsDataURL(file);
    }, 100);
  };

  const beforeUploadFile = (file: UploadFile) => {
    const { type, name, size } = file;
    let specifiecType = type?.substring((type?.lastIndexOf('/') || 0) + 1);
    if (specifiecType === '' || specifiecType === 'x-zip-compressed' || specifiecType?.includes('officedocument')) {
      specifiecType = name.split('.').pop();
    }
    const isAllowedType = TYPEUPLOAD.includes(specifiecType || '');
    if (!isAllowedType) {
      toast.error(
        <Toast
          message={t('general.error_text')}
          detailedMessage={`${t('permit_request.list_doc.upload_failed_type_one') + name + t('permit_request.list_doc.upload_failed_type_two')} (${TYPEUPLOAD})`}
        />);
      return false;
    }

    if ((size || 0) > MAXFILESIZE.VAL_ON_BYTE) {
      toast.error(
        <Toast
          message={t('general.error_text')}
          detailedMessage={`
            ${t('permit_request.list_doc.upload_failed_type_one') +
            name + t('permit_request.list_doc.upload_failed_type_three') +
            MAXFILESIZE.VAL_CONVERT_BYTE_TO_CURRENT +
            MAXFILESIZE.CURRENT_MEMORY}`
          }
        />);
      return false;
    }

    return true;
  };

  const delReqDocs = async (idxC: any) => {
    let newDynamicReqDocs = form.getFieldsValue()?.attachments;
    const idDocs = newDynamicReqDocs[idxC].id;
    if (idDocs) {
      newDynamicReqDocs[idxC].isLoading = true;
      await delAttachment(idDocs).unwrap().then((resp: any) => {
        newDynamicReqDocs?.splice(idxC, 1);
        form.setFieldsValue({
          ...form,
          attachments: newDynamicReqDocs
        });
      }).catch((e:any) => {
        newDynamicReqDocs[idxC].isLoading = false;
        const errorMsg:any = e;
        toast.error(
          <Toast 
            message={`${t('permit_request.list_doc.failed_delete_file')} ${newDynamicReqDocs[idxC]?.file?.name}`} 
            detailedMessage={`${transformError(errorMsg?.data).message}`} 
          />);
      });
    } else {
      newDynamicReqDocs?.splice(idxC, 1);
      form.setFieldsValue({
        ...form,
        attachments: newDynamicReqDocs
      });
    }
  };

  const delReqDocsExisting = async (idxC: any) => {
    let newDynamicReqDocs = form.getFieldsValue()?.existing;
    const idDocs = newDynamicReqDocs[idxC].id;
    if (idDocs) {
      newDynamicReqDocs[idxC].isLoading = true;
      await delAttachment(idDocs).unwrap().then((resp: any) => {
        newDynamicReqDocs?.splice(idxC, 1);
        form.setFieldsValue({
          ...form,
          existing: newDynamicReqDocs
        });
      }).catch((e:any) => {
        newDynamicReqDocs[idxC].isLoading = false;
        const errorMsg:any = e;
        toast.error(
          <Toast 
            message={`${t('permit_request.list_doc.failed_delete_file')} ${newDynamicReqDocs[idxC]?.file?.name}`} 
            detailedMessage={`${transformError(errorMsg?.data).message}`} 
          />);
      });
    } else {
      newDynamicReqDocs?.splice(idxC, 1);
      form.setFieldsValue({
        ...form,
        existing: newDynamicReqDocs
      });
    }
  };

  const fetchDetail = async (id: any) => {
    let dataDetail = await getDetail(id).unwrap();
    setDataDetail({
      existing: dataDetail,
      attachments: []
    });
  };

  useEffect(() => {
    if(dataDetail){
      form.setFieldsValue(dataDetail);
    }
    else{
      form.setFieldsValue(initialForm);
    }
  }, [dataDetail]);

  useEffect(() => {
    if (id) {
      fetchDetail(id);
    }
  }, [id]);

  return (
    <Spin tip="Loading" size="large" spinning={isLoadingGetDetail}>
      <div>
        <div className='bg-[#FDF0D8] p-3 rounded-lg flex items-center justify-between'>
          <span className='font-bold text-base'>{ t('compliance_monitoring.document') }</span>
          <div 
            className='flex items-center gap-2 text-[#54A853] cursor-pointer'
            onClick={() => {
              navigate(`/permit-request/edit/${id}?tab=2`);
            }}
          >
            <span className='font-bold text-base'>{ t('compliance_monitoring.open_link') }</span>
            <RiShareBoxFill className='text-lg'/>
          </div>
        </div>
        <div className="mt-4">
          <Form form={form} onFinish={() => {}} requiredMark={false}>
            <div>
              <Form.Item
                name={['attachments']}
              />
              <Form.Item
                name={['existing']}
              />
              <Form.Item
                name={['attachmentFileData']}
                labelCol={{ span: 24 }}
                label={
                  <div className='mb-4'>
                    {t('permit_request.list_doc.attach_label')}
                  </div>
                }
              >
                <Dragger
                  multiple
                  className='font-normal'
                  customRequest={(opt) => uploadImageReqDocs(opt)}
                  showUploadList={false}
                  beforeUpload={beforeUploadFile}
                  fileList={form.getFieldsValue()?.attachmentFileData?.fileList}
                >
                  <div>
                    <span>
                      {t('permit_request.list_doc.attach_two_placeholder')}
                      <span className='font-bold text-[#55A853] underline underline-offset-2'>
                        {t('permit_request.list_doc.browser_txt')}
                      </span>
                    </span>
                  </div>
                </Dragger>
              </Form.Item>
              <div className='mt-16 grid grid-cols-3 md:grid-cols-5 xl:grid-cols-7 gap-5'>
                {
                  existing?.map((dataC: any, idxC: any) => (
                    <div key={idxC}>
                      <div className={`border ${getBorderAttachmentStep(3)} rounded-md p-2`}>
                        <div className='flex justify-end'>
                          {
                            dataC?.isLoading ? (<Spin></Spin>) : (
                              <CloseOutlined
                                className={'text-[#ED3F3F] text-bold cursor-pointer'}
                                onClick={() => delReqDocsExisting(idxC)}
                              />
                            )
                          }
                        </div>
                        <div className='cursor-pointer'>
                          <div key={idxC} className='w-full flex justify-center'>
                            <FileOutlined className={`text-[30px] text-[${getColorAttachmentStep(3)}]`} onClick={() => downloadFileUrl(dataC?.attachment?.downloadUrl)} />
                          </div>
                          <p className='text-center mt-3 text-[10px] text-[#8B849B] truncate'>
                            {dataC?.attachment?.name}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                }
                {
                  attachments?.map((dataC: any, idxC: any) => (
                    <div key={idxC}>
                      <div className={`border ${getBorderAttachmentStep(dataC?.step)} rounded-md p-2`}>
                        <div className='flex justify-end'>
                          {
                            dataC?.isLoading ? (<Spin></Spin>) : (
                              <CloseOutlined
                                className={'text-[#ED3F3F] text-bold cursor-pointer'}
                                onClick={() => delReqDocs(idxC)}
                              />
                            )
                          }
                        </div>
                        <div className='cursor-pointer'>
                          <div key={idxC} className='w-full flex justify-center'>
                            <FileOutlined className={`text-[30px] text-[${getColorAttachmentStep(dataC?.step)}]`} onClick={() => downloadFile(dataC?.file)} />
                          </div>
                          <p className='text-center mt-3 text-[10px] text-[#8B849B] truncate'>
                            {dataC?.step === 1 ? t('permit_request.list_doc.uploading') : dataC?.step === 2 ? t('permit_request.list_doc.failed') : dataC?.file?.name}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Spin>
  );
};

export default DocumentSection;