import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  valueLang: 'English'
};

const language = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.valueLang = action.payload;
    }
  },
});

export const { setLanguage } = language.actions;
export default language.reducer;
