import { Base } from './Base';

const ErPicPerformances = Base.injectEndpoints({
  endpoints: (build) => ({
    getErPicPerformances: build.mutation({
      query: (params: any) => {
        return {
          url: '/er-pic-performances',
          method: 'GET',
          params: params,
        };
      },
    }),
    getAdjustmentHistories: build.mutation({
      query: (params: any) => {
        return {
          url: '/er-pic-performance-adjustment-histories/by-pic',
          method: 'GET',
          params: params,
        };
      },
    }),
    getResetDate: build.mutation({
      query: () => ({
        url: '/er-pic-performances/reset-date',
        method: 'GET',
      }),
    }),
    postResetScore: build.mutation({
      query: () => ({
        url: '/er-pic-performances/reset',
        method: 'POST',
      }),
    }),
    postAdjustment: build.mutation({
      query: (body: any) => ({
        url: '/er-pic-performance-adjustment-histories',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetErPicPerformancesMutation,
  useGetAdjustmentHistoriesMutation,
  useGetResetDateMutation,
  usePostResetScoreMutation,
  usePostAdjustmentMutation,
} = ErPicPerformances;
