import { 
  Button,
  Form, 
  Input, 
  Modal, 
  Spin, 
  Upload,
  UploadFile,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { ExclamationCircleFilled, UploadOutlined } from '@ant-design/icons';
import { MAXFILESIZE, TYPEUPLOAD } from 'constant';
import Toast from 'components/Toast';
import { toast } from 'react-toastify';
import { usePostAdjustmentMutation } from 'api/ErPicPerformances';

const ModalAdjustment = (props: any) => {
  const { modalAction, detail, callbackSuccess } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const initialForm = {
    permit_request_no: '',
    permit_name: '',
    adjust_delay_score: 0,
    reason: '',
    evidance: null,
  };

  const [postAdjustment] = usePostAdjustmentMutation();

  const handleCancel = () => {
    modalAction.setModalFormOpen(false);
    form.resetFields();
  };

  const beforeUploadFile = (file: UploadFile) => {
    const { type, name, size } = file;
    let specifiecType = type?.substring((type?.lastIndexOf('/') || 0) + 1);
    if (specifiecType === '' || specifiecType === 'x-zip-compressed' || specifiecType?.includes('officedocument')) {
      specifiecType = name.split('.').pop();
    }
    const isAllowedType = TYPEUPLOAD.includes(specifiecType || '');
    if (!isAllowedType) {
      toast.error(
        <Toast
          message={t('general.error_text')}
          detailedMessage={`${t('permit_request.list_doc.upload_failed_type_one') + name + t('permit_request.list_doc.upload_failed_type_two')} (${TYPEUPLOAD})`}
        />);
      return false;
    }

    if ((size || 0) > MAXFILESIZE.VAL_ON_BYTE) {
      toast.error(
        <Toast
          message={t('general.error_text')}
          detailedMessage={`
            ${t('permit_request.list_doc.upload_failed_type_one') +
            name + t('permit_request.list_doc.upload_failed_type_three') +
            MAXFILESIZE.VAL_CONVERT_BYTE_TO_CURRENT +
            MAXFILESIZE.CURRENT_MEMORY}`
          }
        />);
      return false;
    }

    return true;
  };

  const handleUploadChange = (info: any) => {
    const file = info.file.originFileObj;
    
    const reader = new FileReader();
    reader.onload = () => {
      const base64 = reader.result as string;
      form.setFieldsValue({
        evidance: {
          attachment_file: base64,
          filename: file.name,
        },
        evidance_name: file.name,
      });
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (values: any) => {
    const payload = {
      adjustment: {
        permit_request_id: detail?.permit_request_id || '',
        pic_id: detail?.pic_id || '',
        reason: values?.reason || '',
        adjust_score: +(values?.adjust_delay_score || 0),
        evidence: values?.evidance,
      }
    };
    await postAdjustment(payload).unwrap().then(() => {
      toast.success(
        <Toast
          message={t('general.success_text')}
          detailedMessage={`${t('report_er_performance.success.adjustment_delay_score')}`}
        />
      );
      handleCancel();
      if(callbackSuccess){
        callbackSuccess();
      }
    })
      .catch(() => {
        toast.error(
          <Toast
            message={t('general.error_text')}
            detailedMessage={t('report_er_performance.error.adjustment_delay_score')}
          />
        );
      });
  };

  const handleClickSubmit = (values: any) => {
    Modal.confirm({
      title: t('report_er_performance.adjustment_delay_score'),
      content: `${t('report_er_performance.confirm_submit_content.first')} ${detail?.score || 0} ${t('report_er_performance.confirm_submit_content.second')} ${+(values?.adjust_delay_score || 0)} ${t('report_er_performance.confirm_submit_content.third')}`,
      icon: <ExclamationCircleFilled />,
      okText: t('manage_group.yes_btn'),
      okType: 'danger',
      cancelText: t('manage_group.no_btn'),
      async onOk() {
        await handleSubmit(values);
      },
      onCancel() { },
    });
  };

  useEffect(() => {
    if (detail) {
      form.setFieldsValue({
        ...form,
      });
    } else {
      form.resetFields();
    }
  }, [detail]);

  useEffect(() => {
    if(detail){
      form.setFieldsValue({
        ...initialForm,
        permit_request_no: detail.request_number || '',
        permit_name: detail.permit_name || '',
      });
    }
    else{
      form.setFieldsValue(initialForm);
    }
  }, [detail]);

  return (
    <Modal
      title={null}
      open={modalAction.modalFormOpen}
      footer={null}
      width={500}
      onOk={handleCancel}
      onCancel={handleCancel}
    >
      <div className="bg-white p-[10px] rounded-lg">
        <p className='font-bold text-[16px] mb-5'>{t('report_er_performance.adjustment_delay_score')}</p>
        <Form
          layout="vertical"
          autoComplete="off"
          form={form}
          requiredMark={false}
          onFinish={handleClickSubmit}
        >
          <div className='flex flex-col gap-3 mt-5'>
            <div>
              <Form.Item
                name='permit_request_no'
                label={t('report_er_performance.permit_request_no')}
              >
                <Input disabled />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                name='permit_name'
                label={t('report_er_performance.permit_name')}
              >
                <Input disabled />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                name='adjust_delay_score'
                label={t('report_er_performance.adjust_delay_score')}
                rules={[
                  { required: true, message: `${t('report_er_performance.adjust_delay_score')} ${t('general.required_field')}` },
                  {
                    type: 'number',
                    min: 0,
                    max: (detail?.score || 0),
                    transform: (value) => Number(value),
                    message: `${t('report_er_performance.reach_max_score')}`
                  }
                ]}
              >
                <Input type='number' min={0} max={6} placeholder={t('report_er_performance.adjust_delay_score_placeholder') || ''} addonAfter={`${t('report_er_performance.max')}: ${detail?.score || 0} ${t('report_er_performance.days')}`} />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                name='reason'
                label={t('report_er_performance.reason')}
                rules={[{ required: true, message: `${t('report_er_performance.reason')} ${t('general.required_field')}` }]}
              >
                <TextArea
                  style={{ height: 60, resize: 'none' }}
                  placeholder={t('report_er_performance.reason_placeholder') || ''}
                />
              </Form.Item>
            </div>
            <Form.Item name='evidance' className='hidden' />
            <div>
              <Form.Item
                name='evidance_name'
                label={t('report_er_performance.evidance')}
                rules={[{ required: true, message: `${t('report_er_performance.evidance')} ${t('general.required_field')}` }]}
              >
                <Input
                  placeholder={t('report_er_performance.evidance_placeholder') || ''}
                  readOnly
                  suffix={
                    <Upload
                      showUploadList={false}
                      beforeUpload={beforeUploadFile}
                      onChange={handleUploadChange}
                    >
                      <Button icon={<UploadOutlined />}>{t('report_er_performance.browse')}</Button>
                    </Upload>
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div className='flex justify-end items-center mt-6 gap-2'>
            <Button
              type='text'
              className='bg-white border-[#55A853] text-[#55A853] font-bold'
              onClick={handleCancel}
            >
              {t('general.cancel_btn') || ''}
            </Button>
            <Button
              className='bg-[#55A853] text-white font-bold'
              onClick={() => ''}
              htmlType="submit"
            >
              {false ? <Spin></Spin> : t('general.save_btn')}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ModalAdjustment;