import { Base } from './Base';
import { ProductDocConversationForm } from 'constant/Types/ProductDocConversation';

const ProductDocConversation = Base.injectEndpoints({
  endpoints: (build) => ({
    getProductDocConversation: build.mutation({
      query: (id:string) => ({
        url: '/product-doc-conversations/'+id,
        method: 'GET'
      })
    }),
    postProductDocConversation: build.mutation({
      query: (body:ProductDocConversationForm) => ({
        url: '/product-doc-conversations',
        method: 'POST',
        body
      })
    }),
    getProductDocConversationMentions: build.mutation({
      query: (data) => ({
        url: `/product-doc-conversations/${data.id}/mentions`,
        method: 'GET',
        params: data?.params
      })
    })
  })
});

export const { 
  useGetProductDocConversationMutation,
  usePostProductDocConversationMutation,
  useGetProductDocConversationMentionsMutation
} = ProductDocConversation;
