import { Button, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { CloseOutlined, DeleteFilled } from '@ant-design/icons';
import { useGetFeedbackQuestionMutation, usePostFeedbackQuestionMutation } from 'api/FeedbackQuestion';
import Toast from 'components/Toast';
import { toast } from 'react-toastify';
import { GetFeedbackResponse } from 'constant/Types/FeedbackQuestion';

const FeedbackDocument = (props:any) => {
  const { validYear } = props;
  const { t } = useTranslation();
  const [yearBefore, setYearBefore] = useState<boolean>(false);
  const [form] = Form.useForm();
  const initialForm = {
    dynamicCategory: [
      {
        name: '',
        questions: [
          {
            question: '',
          },
        ],
      },
    ],
  };
  const [feedbackGetResponse, setFeedbackGetResponse] = useState<GetFeedbackResponse | null>(null);

  const [feedbackGetMutation] = useGetFeedbackQuestionMutation();
  const [postFeedback, { isLoading: isLoadingSubmitFeedback }] =
		usePostFeedbackQuestionMutation();

  const addCategory = () => {
    const newReqAttach = {
      name: '',
      questions: [
        {
          question: '',
        },
      ],
    };
    const newDynamicCategory = [...form.getFieldsValue()?.dynamicCategory];
    form.setFieldsValue({
      ...form,
      dynamicCategory: [...newDynamicCategory, newReqAttach],
    });
  };

  const addQuestion = (categoryIndex: number) => {
    const categories = form.getFieldValue('dynamicCategory');
    categories[categoryIndex].questions.push({ question: '' });
    form.setFieldsValue({ dynamicCategory: categories });
  };

  const removeCategory = (index: number) => {
    const categories = form.getFieldValue('dynamicCategory');
    categories.splice(index, 1);
    form.setFieldsValue({ dynamicCategory: categories });
  };

  const removeQuestion = (categoryIndex: number, questionIndex: number) => {
    const categories = form.getFieldValue('dynamicCategory');
    categories[categoryIndex].questions.splice(questionIndex, 1);
    form.setFieldsValue({ dynamicCategory: categories });
  };

  const transformFeedbackQuestionsToInitialForm = (data: any) => {
    if (!data.feedback_questions) {
      return { dynamicCategory: [] };
    }

    return {
      dynamicCategory: data.feedback_questions.map((category: any) => ({
        id: category.id,
        name: category.name,
        questions: category.questions.map((question: any) => ({
          id: question.id,
          question: question.question,
        })),
      })),
    };
  };

  const fetchFeedback = async () => {
    const yearInString = validYear.format('YYYY');
    if(!yearInString) return;

    const payload = {
      year: yearInString,
      feedback_type: 'feedback document'
    };
    let response= await feedbackGetMutation(payload).unwrap();
    setFeedbackGetResponse(response);
  };

  const onFinish = async () => {
    const dataForm = form.getFieldsValue().dynamicCategory;
    if (!dataForm || dataForm.length < 1) {
      toast.error(
        <Toast
          message={t('general.error_text')}
          detailedMessage={t(
            'manage_group.fill_form_validation_text'
          )}
        />
      );
      return;
    }

    const filteredCategories = form
      .getFieldsValue()
      .dynamicCategory.map((category: any) => ({
        ...category,
        questions: category.questions.filter(
          (question: any) => question.question.trim() !== ''
        ),
      }));

    const yearInString = validYear.format('YYYY');
    const submitForm = {
      feedback_questions: filteredCategories.map((category: any) => ({
        ...(category.id && { category_id: category.id }),
        category_name: category.name,
        questions: category.questions.map((question: any) => ({
          ...(question.id && { id: question.id }),
          question: question.question,
        })),
      })),
      year: parseInt(yearInString, 10),
      feedback_type: 'feedback document'
    };

    await postFeedback(submitForm).unwrap()
      .then(() => {
        toast.success(
          <Toast
            message={t('general.success_text')}
            detailedMessage={`${t('feedback.success_message_submit_edit_feedback')}`}
          />
        );
        fetchFeedback();
      })
      .catch(() => {
        toast.error(
          <Toast
            message={t('general.error_text')}
            detailedMessage={t(
              'feedback.failed_message_submit_edit_feedback'
            )}
          />
        );
      });
  };

  useEffect(() => {
    if (validYear.isBefore(dayjs(), 'year')) {
      setYearBefore(true);
    } else {
      setYearBefore(false);
    }

    fetchFeedback();
  }, [validYear]);

  useEffect(() => {
    if (feedbackGetResponse?.feedback_questions?.length > 0) {
      form.setFieldsValue(
        transformFeedbackQuestionsToInitialForm(feedbackGetResponse)
      );
    }
    else if(feedbackGetResponse?.feedback_questions?.length === 0){
      form.setFieldsValue(initialForm);
    }
  }, [feedbackGetResponse]);

  return (
    <>
      <Form form={form} onFinish={onFinish}>
        <Form.List name="dynamicCategory">
          {(fields) => (
            <>
              <div className="flex justify-between items-center mb-4">
                <span className="text-lg">
                  {t('feedback.list_of_feedback_question')}
                </span>
                {!yearBefore && (
                  <Button
                    className="submit bg-[#55A853] text-white font-bold md:col-span-4 xl:col-span-3 2xl:col-span-2"
                    onClick={addCategory}
                  >
                    {t('feedback.add_category')}
                  </Button>
                )}
              </div>
              {fields.map(
                (
                  { key, name, ...restField },
                  categoryIndex
                ) => (
                  <div key={key} className="mb-5">
                    <div className=" bg-white rounded-lg p-5">
                      <div className="bg-[#FDF0D8] p-3 rounded-lg mb-4 flex items-center gap-4">
                        <Form.Item
                          {...restField}
                          key={key}
                          name={[name, 'name']}
                          rules={[
                            {
                              required: true,
                              message: `${t(
                                'feedback.category_label'
                              )} ${t(
                                'general.required_field'
                              )}`,
                            },
                          ]}
                          label={t(
                            'feedback.category_label'
                          )}
                          className="m-0 font-bold flex-grow"
                        >
                          <Input
                            className="font-normal m-0"
                            placeholder={
                              t(
                                'feedback.category_placeholder'
                              ) || ''
                            }
                            disabled={yearBefore}
                          />
                        </Form.Item>
                        {fields.length > 1 && !yearBefore && (
                          <CloseOutlined
                            className="font-bold text-[#F00] text-lg cursor-pointer"
                            onClick={() =>
                              removeCategory(
                                categoryIndex
                              )
                            }
                          />
                        )}
                      </div>
                      <div className="flex justify-between items-center mb-4">
                        <span>
                          {t(
                            'feedback.question_label'
                          )}
                        </span>
                        {!yearBefore && (
                          <Button
                            className="bg-white border-[#55A853] text-[#55A853] font-bold"
                            onClick={() =>
                              addQuestion(
                                categoryIndex
                              )
                            }
                          >
                            {t(
                              'feedback.add_question'
                            )}
                          </Button>
                        )}
                      </div>
                      <Form.List
                        name={[name, 'questions']}
                      >
                        {(questionFields) => (
                          <div className="flex flex-col gap-2">
                            {questionFields.map(
                              (
                                {
                                  key: questionKey,
                                  name: questionName,
                                  ...restQuestionField
                                },
                                questionIndex
                              ) => (
                                <div
                                  key={
                                    questionKey
                                  }
                                  className="flex gap-4"
                                >
                                  <Form.Item
                                    {...restQuestionField}
                                    name={[
                                      questionName,
                                      'question',
                                    ]}
                                    rules={[
                                      {
                                        required: true,
                                        message: `${t(
                                          'feedback.question_label'
                                        )} ${t(
                                          'general.required_field'
                                        )}`,
                                      },
                                    ]}
                                    className="flex-grow"
                                  >
                                    <Input
                                      placeholder={
                                        t(
                                          'feedback.question_placeholder'
                                        ) ||
																				''
                                      }
                                      disabled={
                                        yearBefore
                                      }
                                    />
                                  </Form.Item>
                                  {questionFields.length >
																		1 && !yearBefore && (
                                    <DeleteFilled
                                      className={
                                        'text-[25px] self-end pb-[5px] cursor-pointer'
                                      }
                                      onClick={() =>
                                        removeQuestion(
                                          categoryIndex,
                                          questionIndex
                                        )
                                      }
                                    />
                                  )}
                                </div>
                              )
                            )}
                          </div>
                        )}
                      </Form.List>
                    </div>
                  </div>
                )
              )}
            </>
          )}
        </Form.List>
        {!yearBefore && (
          <div className="flex justify-end">
            <Button
              type="text"
              className="bg-[#55A853] text-white font-bold"
              htmlType="submit"
              disabled={isLoadingSubmitFeedback}
            >
              {t('general.submit_btn')} { t('feedback.feedback_document')}
            </Button>
          </div>
        )}
      </Form>
    </>
  );
};

export default FeedbackDocument;
