import { Input, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';
import { FaFilter } from 'react-icons/fa';
import { useState } from 'react';

const CardList = (props: any) => {
  const { viewCardList, payloadMobile, onSearchMobile, onFilterMobile, modalOpen, buttonConfig } = props;
  const {t} = useTranslation();
  const [search, setSearch] = useState('');

  const onChangeSearch = (event: any) => {
    onSearchMobile.setOnSearchMobile(true);
    if (event.target.value.length > 2 || event.target.value.length === 0){
      payloadMobile.setPayloadMobile({...payloadMobile.payloadMobile, search: event.target.value, page: 1});
    }
    setSearch(event.target.value);
  };

  return(
    <div>
      <div className='flex fixed top-[149px] py-[15px] z-10 bg-[#F4F5FD] w-full'>
        {buttonConfig && buttonConfig.map((data: any, idx: any) => (
          <Button type='text' key={idx} className={data.className} onClick={data.onClick}>{data.name}</Button>
        ))}
      </div>
      <div className={`flex fixed ${modalOpen ? 'px-[21px]' : 'pl-[21px]'} ${buttonConfig ? 'top-[210px] pb-[20px]' : 'top-[140px] py-[20px]'} left-0 right-0 z-10 bg-[#F4F5FD]`}>
        <Input value={search} onChange={onChangeSearch}  className='md:w-[70%] mr-5' size="large" placeholder={t('data_table.search_placeholder') || ''} prefix={<SearchOutlined className='text-[#55A853]' />} />
        {
          modalOpen ? 
            (
              <Button
                type='text'
                className='shadow-md shadow-gray-400 rounded-sm p-0 bg-white'
                onClick={() => {
                  onFilterMobile.setOnFilterMobile(true);
                  modalOpen.setIsModalOpen(!modalOpen.isModalOpen);
                }}
              >
                <FaFilter style={{fontSize: '30px'}} className='text-[#55A853] p-1' />
              </Button>
            )
            : (<></>)
        }
      </div>
      <div className={`${buttonConfig ? 'mt-[110px]' : 'mt-[60px]'}`}>
        {viewCardList()}
      </div>
    </div>
  );
};

export default CardList;