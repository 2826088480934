const ToastNotification = (props: { 
	message: any, 
	detailedMessage: any
	handleOnClickNotif: any
}) => {
  const { message, detailedMessage, handleOnClickNotif } = props;

  return (
    <div onClick={handleOnClickNotif} className="text-white">
		  <div className="font-semibold text-[16px] text-black">{message}</div>
		  <div className="text-[14px] text-black">{detailedMessage}</div>
    </div>
	  );
};

export default ToastNotification;  