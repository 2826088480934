import { ListPayloadType } from 'constant/Types/OptionType';
import { Base } from './Base';

const Options = Base.injectEndpoints({
  endpoints: (build) => ({
    userHrisList: build.mutation({
      query: (params: ListPayloadType) => ({
        url: '/user-hris',
        method: 'GET',
        params
      })
    }),
    departmentSearch: build.mutation({
      query: (params: ListPayloadType) => ({
        url: '/user-hris/departments',
        method: 'GET',
        params
      })
    }),
    companySearch: build.mutation({
      query: (params: ListPayloadType) => ({
        url: '/user-hris/companies',
        method: 'GET',
        params
      })
    }),
    worklocSearch: build.mutation({
      query: (params: ListPayloadType) => ({
        url: '/work-locations',
        method: 'GET',
        params
      })
    }),
    workLocationHris: build.mutation({
      query: (params: ListPayloadType) => ({
        url: '/user-hris/work-locations',
        method: 'GET',
        params
      })
    }),
    worklocHrisSearch: build.mutation({
      query: (params: ListPayloadType) => ({
        url: '/user-hris/work-locations',
        method: 'GET',
        params
      })
    }),
    issuerInstitutionsList: build.mutation({
      query: (params: ListPayloadType) => ({
        url: '/permit-issuer-institutions',
        method: 'GET',
        params
      })
    }),
    permitNamesList: build.mutation({
      query: (params: ListPayloadType) => ({
        url: '/permit-names',
        method: 'GET',
        params
      })
    }),
    permitTypesList: build.mutation({
      query: (params: ListPayloadType) => ({
        url: '/permit-types',
        method: 'GET',
        params
      })
    }),
    requestTypesList: build.mutation({
      query: (params: ListPayloadType) => ({
        url: '/request-types',
        method: 'GET',
        params
      })
    }),
    statusPermitList: build.mutation({
      query: (params: ListPayloadType) => ({
        url: '/permit-request/status',
        method: 'GET',
        params
      })
    }),
    productDocsList: build.mutation({
      query: (params: any) => ({
        url: '/product-docs',
        method: 'GET',
        params
      })
    }),
    timezoneList: build.mutation({
      query: (params: any) => ({
        url: '/timezones',
        method: 'GET',
        params
      })
    }),
    erPicList: build.mutation({
      query: (params: any) => ({
        url: '/permit-request/get-pic',
        method: 'GET',
        params
      })
    }),
    statusPermitListWfe: build.mutation({
      query: (params: ListPayloadType) => ({
        url: '/map-wfe-status',
        method: 'GET',
        params
      })
    })
  })
});

export const {
  useUserHrisListMutation,
  useDepartmentSearchMutation,
  useCompanySearchMutation,
  useWorklocSearchMutation,
  useWorkLocationHrisMutation,
  useIssuerInstitutionsListMutation,
  usePermitNamesListMutation,
  usePermitTypesListMutation,
  useRequestTypesListMutation,
  useWorklocHrisSearchMutation,
  useStatusPermitListMutation,
  useProductDocsListMutation,
  useTimezoneListMutation,
  useErPicListMutation,
  useStatusPermitListWfeMutation
} = Options;