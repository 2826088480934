import type { CredentialType } from 'constant/Types/CredentialType';
import Base from 'database/Base';

import { encodeAES, decodeAES } from 'utils/Encoder';

class Credential extends Base<CredentialType> {
  constructor() {
    super(`${process.env.REACT_APP_DATABASE_NAME}`);
  }

  putData(data: CredentialType): Promise<CredentialType> {
    const encodedSecret: CredentialType = {
      ...data,
      value: encodeAES(data.value)
    };

    return super.putData(encodedSecret);
  }

  getData(id: CredentialType['_id']): Promise<CredentialType> {
    return new Promise((resolve, reject) => {
      super.getData(id)
        .then((value: CredentialType) => {
          const decodedSecret: CredentialType = {
            ...value,
            value: decodeAES(value.value)
          };

          resolve(decodedSecret);
        })
        .catch((error: any) => reject(error));
    });
  }

  deleteData(id: CredentialType['_id']): Promise<CredentialType> {
    return super.deleteData(id);
  }
}

export default Credential;

