import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft, FaTasks, FaCheck } from 'react-icons/fa';
import { RiHandbagLine } from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router';
import Statistic from './Component/Statistic';
import IssuerInstitution from './Component/IssuerInstitution';
import { Spin, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import AlignmentTab from './AlignmentTab';
import PermitStatusTab from './PermitStatusTab';
import CrmTab from './CrmTab';
import { useGetByIdWorkLocMutation } from 'api/WorkLocation';
import Toast from 'components/Toast';
import { transformError } from 'utils/ErrorTransformer';
import { toast } from 'react-toastify';
import { useBlockDashboardMutation, useGetAlignmentMutation, useIssuerInstitutionMutation } from 'api/Dashboard';

const DetailCompany = () => {
  interface Company {
    id?: string,
    name: string,
    code: string,
    image: string
  }
  
  const { id, year } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [totalRequest, setTotalRequest] = useState(0);
  const [percentPermitGranted, setPercentPermitGranted] = useState(0);
  const [url, setUrl] = useState('');
  const [detail, setDetail] = useState<Company>({
    id: '',
    name: '',
    code: '',
    image: ''
  });
  const [statistic, setStatistic] = useState({
    series: [],
    categories: []
  });
  const [issuerInstitutions, setIssuerInstitutions] = useState({
    total_issuer: 0,
    series: [],
    categories: []
  });

  const [getByIdWorkLoc, {
    error: errorGetByIdWorkLoc,
    isError: isErrorGetByIdWorkLoc,
    isLoading: isLoadingGetByIdWorkLoc
  }] = useGetByIdWorkLocMutation();

  const [getBlockDashboard, {
    error: errorGetBlockDashboard,
    isError: isErrorGetBlockDashboard,
    isLoading: isLoadingGetBlockDashboard
  }] = useBlockDashboardMutation();
  
  const [getIssuerInstitutions, {
    error: errorGetIssuerInstitutions,
    isError: isErrorGetIssuerInstitutions,
    isLoading: isLoadingGetIssuerInstitutions
  }] = useIssuerInstitutionMutation();
  
  const [getAlignment, {
    error: errorGetAlignment,
    isError: isErrorGetAlignment,
    isLoading: isLoadingGetAlignment
  }] = useGetAlignmentMutation();

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('tab.alignment'),
      children: <AlignmentTab url={url} isLoading={isLoadingGetAlignment}/>
    },
    {
      key: '2',
      label: t('tab.permit_status'),
      children: <PermitStatusTab/>
    },
    {
      key: '3',
      label: t('tab.crm'),
      children: <CrmTab/>
    },
  ];

  const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => (
    <div>
      <DefaultTabBar
        {...props}
        className='px-10 rounded-xl text-[#AAAAAA] border-2 border-zinc-200'
        style={{ background: '#FFF' }}
      />
    </div>
  );

  const fetchWorkLocation = async () => {
    const data = await getByIdWorkLoc(id).unwrap();
    setDetail({
      code: data?.code,
      image: data?.logo_image || null,
      name: data?.name,
      id: data?.id
    });
    fetchAlignment(data?.code);
  };

  const fetchBlockDashboard = async () => {
    const payload = {
      location_id: id,
      year
    };
    const data = await getBlockDashboard(payload).unwrap();

    const dataStatistic:any = {
      series: [
        {
          name: t('dashboard.companies.request_submit'),
          data: [data?.permit_submit]
        },
        {
          name: t('dashboard.companies.request_guaranted'),
          data: [data?.permit_granted]
        },
      ],
      categories: [t('dashboard.companies.statistic_label')]
    };
    setStatistic(dataStatistic);
    setTotalRequest(data?.total_request);
    setPercentPermitGranted(data?.percent_permit_granted?.toFixed(1) || 0);
  };

  const fetchIssuerInstitutions = async () => {
    const payload = {
      location_id: id,
      top: 5,
      year
    };
    const data = await getIssuerInstitutions(payload).unwrap();
    setIssuerInstitutions(data);
  };

  const fetchAlignment = async (code:string) => {
    const data = await getAlignment(code).unwrap();
    setUrl(data?.alignment_link);
  };

  useEffect(() => {
    fetchWorkLocation();
    fetchBlockDashboard();
    fetchIssuerInstitutions();
  }, []);

  useEffect(() => {
    if (isErrorGetByIdWorkLoc){
      const errorMsg:any = errorGetByIdWorkLoc;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorGetByIdWorkLoc]);

  useEffect(() => {
    if (isErrorGetBlockDashboard){
      const errorMsg:any = errorGetBlockDashboard;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorGetBlockDashboard]);

  useEffect(() => {
    if (isErrorGetIssuerInstitutions){
      const errorMsg:any = errorGetIssuerInstitutions;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorGetIssuerInstitutions]);

  useEffect(() => {
    if (isErrorGetAlignment){
      const errorMsg:any = errorGetAlignment;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorGetAlignment]);

  return(
    <div className='p-2'>
      <div className='flex text-[#54A853] cursor-pointer mb-5' onClick={() => {navigate('/dashboard');}}>
        <FaArrowLeft className='mr-2 my-auto' />
        <p>{t('general.return_label')}</p>
      </div>
      <Spin tip="Loading" size='large' spinning={isLoadingGetByIdWorkLoc}>
        {
          detail.image ? 
            (
              <div className='flex mb-5'>
                <img 
                  src={(process.env.REACT_APP_BASE_URL + 'images/' + detail.image)} 
                  alt=''
                  loading='lazy'
                  width={120}
                />
                <p className='ml-5 font-bold mt-[16px]'>{detail.name}</p>
              </div>
            ) 
            : 
            (
              <div className='flex'>
                <p className='font-bold text-[40px] mb-3'>{detail.code}</p>
                <p className='ml-5 font-bold mt-[10px]'>{detail.name}</p>
              </div>
            )
        }
      </Spin>
      <div className='grid lg:grid-cols-12 gap-5 mb-3'>
        <div className='lg:col-span-5'>
          <Spin tip="Loading" size='large' spinning={isLoadingGetBlockDashboard}>
            <div className='bg-white rounded-lg mb-3 py-4 px-5'>
              <div className='flex'>
                <div className='bg-[#FFF5D9] w-[75px] h-[75px] flex justify-center items-center rounded-[100px] my-auto'>
                  <FaTasks className='text-[40px] border-2 border-[#C29619] p-[8px] rounded-xl' color='#C29619' fill='#C29619'/>
                </div>
                <div className='ml-5'>
                  <p className='m-0'>{t('dashboard.title_block_2')}</p>
                  <p className='text-[40px] font-bold'>{totalRequest}</p>
                </div>
              </div>
              <Statistic data={statistic}/>
            </div>
            <div className='bg-white rounded-lg py-4 px-5'>
              <div className='flex'>
                <div className='bg-[#DDEEDD] w-[75px] h-[75px] flex justify-center items-center rounded-[100px] my-auto'>
                  <FaCheck className='text-[50px] p-[8px] rounded-xl' color='#54A853' fill='#54A853'/>
                </div>
                <div className='ml-5'>
                  <p className='m-0'>{t('dashboard.companies.percentage_label')}</p>
                  <p className='text-[40px] font-bold'>{percentPermitGranted}%</p>
                </div>
              </div>
            </div>
          </Spin>
        </div>
        <div className='lg:col-span-7'>
          <div className='bg-white rounded-lg h-[380px] py-4 px-5 overflow-auto'>
            <Spin tip="Loading" size='large' spinning={isLoadingGetIssuerInstitutions}>
              <div className='flex'>
                <RiHandbagLine className='text-[25px]'/>
                <p className='uppercase ml-1 my-auto font-bold'>{issuerInstitutions.total_issuer} {t('dashboard.issuer_institution')}</p>
              </div>
              <IssuerInstitution data={issuerInstitutions} />
            </Spin>
          </div>
        </div>
      </div>
      <div className='bg-white p-5 rounded-lg overflow-auto'>
        <Tabs
          tabPosition='top'
          className='w-fit sm:w-full'
          renderTabBar={renderTabBar}
          items={items}
        />
      </div>
    </div>
  );
};

export default DetailCompany;