import { 
  Button, 
  DatePicker, 
  Empty, 
  Form, 
  Input, 
  Modal, 
  Select, 
  Spin, 
  Upload,
  AutoComplete,
  DatePickerProps
} from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import TextArea from 'antd/es/input/TextArea';
import { useTranslation } from 'react-i18next';
import { OptionType } from 'constant/Types/OptionType';
import { useEffect, useState } from 'react';
import {
  useIssuerInstitutionsListMutation,
  usePermitNamesListMutation,
  usePermitTypesListMutation,
  useProductDocsListMutation,
  useWorklocSearchMutation
} from 'api/Options';
import {
  useStatusPermitMonitoringMutation,
  usePostPermitMonitoringMutation,
  usePutPermitMonitoringMutation
} from 'api/PermitMonitoring';
import Toast from 'components/Toast';
import { toast } from 'react-toastify';
import { transformError } from 'utils/ErrorTransformer';
import dayjs from 'dayjs';
import moment from 'moment';
import type { UploadFile } from 'antd';
import { TYPEUPLOAD, MAXFILESIZE } from 'constant';
import {
  usePostUploadProductDocMutation,
  useDelUploadProductDocMutation
} from 'api/PermitRequest';
import { FileOutlined, CloseOutlined } from '@ant-design/icons';
import { MdLink } from 'react-icons/md';


const ModalForm = (props: any) => {
  const { modalAction, fetchList, isView, isEdit, detail } = props;
  const { t, i18n } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const { Option } = Select;
  const [form] = Form.useForm();
  const { Dragger } = Upload;
  const [isAutoReferencePermit, setIsAutoReferencePermit] = useState(false);
  const [options, setOptions] = useState<{ value: string }[]>([]);
  const [issuerInstitutions, setIssuerInstitutions] = useState<OptionType[]>([]);
  const [permitNames, setPermitNames] = useState<OptionType[]>([]);
  const [defaultPermitNames, setDefaultPermitNames] = useState([]);
  const [permitTypes, setPermitTypes] = useState<OptionType[]>([]);
  const [defaultPermitTypes, setDefaultPermitTypes] = useState([]);
  const [companies, setCompanies] = useState<OptionType[]>([]);
  const [statuses, setStatuses] = useState<OptionType[]>([]);
  const [selectedPermitReference, setSelectedPermitReference] = useState('');
  const [prodDocAttach, setProdDocAttach] = useState({
    detail: [],
    create: []
  });
  const [postUploadProdDocs] = usePostUploadProductDocMutation();
  const [delUploadProdDocs] = useDelUploadProductDocMutation();

  const [submit, {
    error: errorSubmit,
    isError: isErrorSubmit,
    isSuccess: isSuccessSubmit,
    isLoading: isLoadingSubmit
  }] = usePostPermitMonitoringMutation();

  const [put, {
    error: errorPut,
    isError: isErrorPut,
    isSuccess: isSuccessPut,
    isLoading: isLoadingPut
  }] = usePutPermitMonitoringMutation();

  const [issuerInstitutionList] = useIssuerInstitutionsListMutation();
  const [permitNameList, {
    isLoading: isLoadingPermitNames
  }] = usePermitNamesListMutation();
  const [permitTypeList, {
    isLoading: isLoadingPermitTypes
  }] = usePermitTypesListMutation();
  const [worklocationList, {
    isLoading: isLoadingWorklocation
  }] = useWorklocSearchMutation();
  const [statusList] = useStatusPermitMonitoringMutation();
  const [productDocsList] = useProductDocsListMutation();

  const fetchStatus = async (value: any) => {
    if (value.length > 2 || !value) {
      let rows = await statusList({}).unwrap();
      const newDataStatus = rows.map((el: any) => {
        if (el) {
          return {
            label: el.value,
            value: el.value
          };
        }
        return null;
      });

      setStatuses(newDataStatus.filter((v: any) => v.label));
    }
  };

  const fetchIssuerInstitutions = async (value: any) => {
    if (value?.length > 2 || !value) {
      const payload = {
        search: value ?? '',
        searchFields: 'name'
      };
      let { rows } = await issuerInstitutionList(payload).unwrap();
      const newDataIssuerInstitution = rows.map((el: any) => {
        if (el) {
          return {
            label: el.name,
            value: el.id
          };
        }
        return null;
      });

      setIssuerInstitutions(newDataIssuerInstitution.filter((v: any) => v));
    }
  };

  const fetchPermitNames = async (value: any) => {
    if (value?.length > 2 || !value) {
      const payload = {
        search: value ?? '',
        searchFields: 'name',
        page: 1,
        pageSize: 1000
      };
      let { rows } = await permitNameList(payload).unwrap();
      setDefaultPermitNames(rows);
      const newDataPermitName = rows.map((el: any) => {
        if (el) {
          return {
            label: el.name,
            value: el.id
          };
        }
        return null;
      });

      setPermitNames(newDataPermitName.filter((v: any) => v));
    }
  };

  const fetchPermitTypes = async (value: any) => {
    if (value?.length > 2 || !value) {
      const payload = {
        search: value ?? '',
        searchFields: 'name'
      };
      let { rows } = await permitTypeList(payload).unwrap();
      setDefaultPermitTypes(rows);
      const newDataPermitType = rows.map((el: any) => {
        if (el) {
          return {
            label: el.name,
            value: el.id
          };
        }
        return null;
      });

      setPermitTypes(newDataPermitType.filter((v: any) => v));
    }
  };

  const fetchWorklocations = async (value: any) => {
    if (value?.length > 2 || !value) {
      const payload = {
        search: value ?? '',
        searchFields: 'name',
        page: 1,
        pageSize: 1000
      };
      let { rows } = await worklocationList(payload).unwrap();
      const newDataCompany = rows.map((el: any) => {
        if (el) {
          return {
            label: el?.name,
            value: el?.id
          };
        }
        return null;
      });

      setCompanies(newDataCompany.filter((v: any) => v));
    }
  };

  const beforeUploadFile = (file: UploadFile) => {
    const { type, name, size } = file;
    let specifiecType = type?.substring((type?.lastIndexOf('/') || 0) + 1);
    if (specifiecType === '' || specifiecType === 'x-zip-compressed' || specifiecType?.includes('officedocument')) {
      specifiecType = name.split('.').pop();
    }
    const isAllowedType = TYPEUPLOAD.includes(specifiecType || '');
    if (!isAllowedType) {
      toast.error(
        <Toast
          message={t('general.error_text')}
          detailedMessage={`${t('permit_request.list_doc.upload_failed_type_one') + name + t('permit_request.list_doc.upload_failed_type_two')} (${TYPEUPLOAD})`}
        />);
      return false;
    }

    if ((size || 0) > MAXFILESIZE.VAL_ON_BYTE) {
      toast.error(
        <Toast
          message={t('general.error_text')}
          detailedMessage={`
            ${t('permit_request.list_doc.upload_failed_type_one') +
            name + t('permit_request.list_doc.upload_failed_type_three') +
            MAXFILESIZE.VAL_CONVERT_BYTE_TO_CURRENT +
            MAXFILESIZE.CURRENT_MEMORY}`
          }
        />);
      return false;
    }

    if (prodDocAttach?.create?.length >= 5 || (prodDocAttach?.create?.length + prodDocAttach?.detail?.length) >= 5) {
      toast.error(
        <Toast
          message={t('general.error_text')}
          detailedMessage={t('permit_request.product_doc.error_limit_file')}
        />);
      return false;
    }

    return true;
  };

  const uploadImageReqDocs = async (options: any) => {
    const reader = new FileReader();
    const { onSuccess, file } = options;
    let fileUpload;

    let newProductDoc: any = prodDocAttach.create;
    // step 1: uploading, step 3: success
    newProductDoc.push({ file: file, step: 1, id: null, isLoading: true });
    setProdDocAttach({
      ...prodDocAttach,
      create: newProductDoc
    });

    if (prodDocAttach?.create?.length > 5) {
      toast.error(
        <Toast
          message={t('general.error_text')}
          detailedMessage={t('permit_request.product_doc.error_limit_file')}
        />);
      return false;
    }

    const idxC = prodDocAttach.create.length - 1;
    setTimeout(() => {
      reader.onload = async (e) => {
        fileUpload = e?.target?.result || null;
        const payload = {
          body: {
            product_doc_attachment: {
              attachment_file: fileUpload,
              filename: file.name
            }
          }
        };

        await postUploadProdDocs(payload).unwrap().then((response: any) => {
          let newDynamicReqDocsSuccess: any = prodDocAttach.create;
          newDynamicReqDocsSuccess[idxC].step = 3;
          newDynamicReqDocsSuccess[idxC].id = response?.id;
          newDynamicReqDocsSuccess[idxC].isLoading = false;
          setProdDocAttach({
            ...prodDocAttach,
            create: newDynamicReqDocsSuccess
          });
          onSuccess('Ok');
        }).catch((e: any) => {
          const errorMsg: any = e;
          toast.error(
            <Toast
              message={`${t('permit_request.list_doc.failed_upload')} ${file?.name}`}
              detailedMessage={`${transformError(errorMsg?.data).message}`}
            />
          );

          let newDynamicReqDocsError = [...prodDocAttach.create];
          newDynamicReqDocsError?.splice(idxC, 1);
          if (!newDynamicReqDocsError?.length) {
            newDynamicReqDocsError = [];
          }
          setProdDocAttach({
            ...prodDocAttach,
            create: newDynamicReqDocsError
          });
        });
      };
      reader.readAsDataURL(file);
    }, 100);
  };

  const getBorderStep = (data: any) => {
    switch (data) {
      case 1:
        return 'border-zinc-400';
      case 2:
        return 'border-red-400';
      case 3:
        return 'border-green-400';
    }
  };

  const getColorStep = (data: any) => {
    switch (data) {
      case 1:
        return '#bbbbbb';
      case 2:
        return '#ED3F3F';
      case 3:
        return '#55A853';
    }
  };

  const delReqDocs = async (idxC: any) => {
    let newDynamicReqDocs: any = prodDocAttach?.create;
    const idDocs = newDynamicReqDocs[idxC].id;
    if (idDocs) {
      newDynamicReqDocs[idxC].isLoading = true;
      await delUploadProdDocs(idDocs).unwrap().then((resp: any) => {
        newDynamicReqDocs?.splice(idxC, 1);
        setProdDocAttach({
          ...prodDocAttach,
          create: newDynamicReqDocs
        });
      }).catch((e: any) => {
        newDynamicReqDocs[idxC].isLoading = false;
        const errorMsg: any = e;
        toast.error(
          <Toast
            message={`${t('permit_request.list_doc.failed_delete_file')} ${newDynamicReqDocs[idxC]?.file?.name}`}
            detailedMessage={`${transformError(errorMsg?.data).message}`}
          />);
      });
    } else {
      newDynamicReqDocs?.splice(idxC, 1);
      setProdDocAttach({
        ...prodDocAttach,
        create: newDynamicReqDocs
      });
    }
  };

  const downloadFile = (file: any) => {
    let csvURL = window.URL.createObjectURL(file);
    let tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', `${file?.name}`);
    tempLink.click();
  };

  const downloadFileUrl = (url: any) => {
    window.open(url);
  };

  const delReqDocsExisting = async (idxC: any) => {
    let newDynamicReqDocs: any = prodDocAttach?.detail;
    const idDocs = newDynamicReqDocs[idxC].id;
    if (idDocs) {
      newDynamicReqDocs[idxC].isLoading = true;
      await delUploadProdDocs(idDocs).unwrap().then((resp: any) => {
        newDynamicReqDocs?.splice(idxC, 1);
        setProdDocAttach({
          ...prodDocAttach,
          detail: newDynamicReqDocs
        });
      }).catch((e: any) => {
        newDynamicReqDocs[idxC].isLoading = false;
        const errorMsg: any = e;
        toast.error(
          <Toast
            message={`${t('permit_request.list_doc.failed_delete_file')} ${newDynamicReqDocs[idxC]?.file?.name}`}
            detailedMessage={`${transformError(errorMsg?.data).message}`}
          />);
      });
    } else {
      newDynamicReqDocs.existing?.splice(idxC, 1);
      setProdDocAttach({
        ...prodDocAttach,
        detail: newDynamicReqDocs
      });
    }
  };

  const calculateRemainingDays = (expiredDate: string) => {
    if (!expiredDate) return '-';

    const today = dayjs().startOf('day');
    const date = dayjs(expiredDate).startOf('day').add(1, 'day');
    const diff = date.diff(today, 'day');

    return diff < 0 ? 0 : diff;
  };  

  const handleChangeExpireDate = (date: any) => {
    const dateString = date ? date.toISOString() : '';
    const remainingDays = calculateRemainingDays(dateString);
    form.setFieldValue('remaining_days', remainingDays);

    if(form.getFieldValue('issued_date')){
      form.validateFields(['issued_date']);
    }

    if(form.getFieldValue('released_date')){
      form.validateFields(['released_date']);
    }
  };

  useEffect(() => {
    fetchIssuerInstitutions('');
    fetchPermitNames('');
    fetchPermitTypes('');
    fetchWorklocations('');
    fetchStatus('');
  }, []);

  useEffect(() => {
    setProdDocAttach({
      detail: [],
      create: []
    });
    setIsAutoReferencePermit(false);
    setSelectedPermitReference('');
    if (detail) {
      form.setFieldsValue({
        ...form,
        registration_number: detail.registration_number,
        permit_name_id: detail.permit_name_id,
        permit_type_id: detail.permit_type_id,
        permit_issuer_institution_id: detail.permit_issuer_institution_id,
        work_location_id: detail.work_location_id,
        description: detail.description || '',
        area_ha: detail.area_ha || '',
        processed_by: detail.processed_by,
        status: detail.status,
        expired_date: detail.expired_date ? dayjs(detail.expired_date) : null,
        issued_date: detail.issued_date ? dayjs(detail.issued_date) : null,
        released_date: detail.released_date ? dayjs(detail.released_date) : null,
        remaining_days: calculateRemainingDays(detail.expired_date),
        permit_reference: detail?.permit_reference,
        expire_permit_reference: detail.expire_permit_reference ? dayjs(detail.expire_permit_reference) : null,

      });
      if (detail?.attachments?.length) {
        setProdDocAttach({
          ...prodDocAttach,
          detail: detail?.attachments?.map((el: any) => el?.attachment)
        });
      }
      if (detail?.permit_reference){
        getProductDocsInfo();
      }
    } else {
      form.resetFields();
    }
  }, [modalAction.modalFormOpen, detail]);

  const getProductDocsInfo = async () => {
    setIsAutoReferencePermit(false);
    const payload = {
      registration_number: detail?.permit_reference ?? '',
    }; 
    let data = await productDocsList(payload).unwrap();
    if (data.length) {
      setSelectedPermitReference(data[0]?.id);
      setIsAutoReferencePermit(true);
    }
  };

  const onFinish = async (values: any) => {
    let prodAttach: Object[] = [];
    prodDocAttach.create.map((v: any) => prodAttach.push({ id: v.id, name: v.file.name }));

    if (detail) {
      const formPut = {
        id: detail.id,
        product_doc: {
          registration_number: values.registration_number,
          permit_name_id: values.permit_name_id,
          permit_type_id: values.permit_type_id,
          permit_issuer_institution_id: values.permit_issuer_institution_id,
          work_location_id: values.work_location_id,
          description: values.description || '',
          area_ha: values.area_ha || '',
          processed_by: values.processed_by,
          status: values.status,
          expired_date: dayjs(values.expired_date).format('YYYY-MM-DD'),
          issued_date: values.issued_date ? dayjs(values.issued_date).format('YYYY-MM-DD') : null,
          released_date: values.released_date ? dayjs(values.released_date).format('YYYY-MM-DD') : null,
          attachments: prodAttach,
          expire_permit_reference: values.expire_permit_reference ? dayjs(values.expire_permit_reference).format('YYYY-MM-DD') : null,
          permit_reference: values.permit_reference || ''
        }
      };
      await put(formPut).unwrap();
    } else {
      const formSubmit = {
        product_doc: {
          registration_number: values.registration_number,
          permit_name_id: values.permit_name_id,
          permit_type_id: values.permit_type_id,
          permit_issuer_institution_id: values.permit_issuer_institution_id,
          work_location_id: values.work_location_id,
          description: values.description || '',
          area_ha: values.area_ha || '',
          processed_by: values.processed_by,
          status: values.status,
          expired_date: dayjs(values.expired_date).format('YYYY-MM-DD'),
          issued_date: values.issued_date ? dayjs(values.issued_date).format('YYYY-MM-DD') : null,
          released_date: values.released_date ? dayjs(values.released_date).format('YYYY-MM-DD') : null,
          attachments: prodAttach,
          expire_permit_reference: values.expire_permit_reference ? dayjs(values.expire_permit_reference).format('YYYY-MM-DD') : null,
          permit_reference: values.permit_reference || ''
        }
      };
      await submit(formSubmit).unwrap();
    }
  };

  const getRulesUpload = () => {
    if (prodDocAttach?.detail?.length) {
      return false;
    }

    return true;
  };

  const onChangeExpired = (
    value: DatePickerProps['value'] | RangePickerProps['value'],
    dateString: [string, string] | string,
  ) => {
    setIsAutoReferencePermit(false);
  };

  const fetchReferencePermit = async (value: any) => {
    setIsAutoReferencePermit(false);
    if (value) {
      const payload = {
        registration_number: value ?? '',
      }; 
      let data = await productDocsList(payload).unwrap();
      setOptions([...data?.map((el:any) =>  ({value: el?.registration_number, id: el?.id, expire_permit_reference: el?.expired_date}))]);
      return;
    }

    setOptions([]);
  };

  const selectReferencePermit = (_:any, value: any) => {
    setIsAutoReferencePermit(true);
    if (!value?.expire_permit_reference) {
      toast.warning(<Toast message={t('general.warning_text')} detailedMessage={t('permit_request.request_information.empty_expired_date')} />);
      return;
    }

    setSelectedPermitReference(value?.id);
    form.setFieldsValue({
      ...form, 
      expire_permit_reference: dayjs(moment(new Date(value?.expire_permit_reference)).format('DD-MMM-YYYY'), 'DD-MMM-YYYY')
    });
  };

  const handleOnClickPermitReference = () => {
    if (!selectedPermitReference) {
      return;
    }

    window.open(window.location.origin + '/permit-monitoring?id=' + selectedPermitReference, '_blank');
  };

  const validateBeforeExpireDate = (fieldName: string) => (_: any, value: any) => {
    const expiredDate = form.getFieldValue('expired_date')?.startOf('day');
    const fieldDate = value?.startOf('day');
    
    if (expiredDate && fieldDate && (fieldDate.isSame(expiredDate) || fieldDate.isAfter(expiredDate))) {
      return Promise.reject(new Error(`${fieldName} ${t('permit_request.request_information.must_before')} ${t('permit_request.request_information.expired_date')}`));
    }

    return Promise.resolve();
  };

  const disabledDateActiveAndRelease = (current: any) => {
    const expireDate = form.getFieldValue('expired_date');
    return current && expireDate && current > expireDate.startOf('day');
  };

  useEffect(() => {
    if (isErrorPut) {
      const errorMsg: any = errorPut;
      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
    }
    if (isErrorSubmit) {
      const errorMsg: any = errorSubmit;
      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
    }
  }, [isErrorPut, isErrorSubmit]);

  useEffect(() => {
    setProdDocAttach({
      detail: [],
      create: []
    });
    if (isSuccessSubmit) {
      toast.success(
        <Toast
          message={t('general.success_text')}
          detailedMessage={`${t('permit_monitoring.success_message_submit')}`}
        />
      );
      modalAction.setModalFormOpen(false);
      fetchList();
      return;
    }
  }, [isSuccessSubmit]);

  useEffect(() => {
    setProdDocAttach({
      detail: [],
      create: []
    });
    if (isSuccessPut) {
      toast.success(
        <Toast
          message={t('general.success_text')}
          detailedMessage={`${t('permit_monitoring.success_message_update')}`}
        />
      );
      modalAction.setModalFormOpen(false);
      fetchList();
    }
  }, [isSuccessPut]);

  return (
    <Modal
      title={null}
      open={modalAction.modalFormOpen}
      footer={null}
      width={1000}
      onOk={() => modalAction.setModalFormOpen(false)}
      onCancel={() => modalAction.setModalFormOpen(false)}
    >
      <div className="bg-white p-[10px] rounded-lg">
        <p className='font-bold text-[16px] mb-5'>
          {
            isView
              ? t('permit_monitoring.detail_permit_monitoring')
              : isEdit
                ? t('permit_monitoring.edit_permit_monitoring')
                : t('permit_monitoring.create_permit_monitoring')
          }
        </p>
        <Form
          layout="vertical"
          autoComplete="off"
          form={form}
          requiredMark={false}
          onFinish={onFinish}
        >
          <div className='grid grid-cols-2 gap-3 mt-5'>
            <div className='md:col-span-1 max-md:col-span-2'>
              <Form.Item
                name='registration_number'
                label={t('permit_monitoring.permit_no')}
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={t('permit_monitoring.permit_no_placeholder') || ''}
                  disabled={isView || isEdit}
                />
              </Form.Item>
            </div>
            <div className='md:col-span-1 max-md:col-span-2'>
              <Form.Item
                name='permit_name_id'
                label={t('permit_monitoring.permit_name')}
                rules={[{ required: true }]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder={t('permit_monitoring.permit_name_placeholder')}
                  onClear={() => {
                    fetchPermitNames('');
                    fetchPermitTypes('');
                    form.setFieldsValue({
                      ...form,
                      permit_type_id: ''
                    });
                  }}
                  onChange={(e) => {
                    const permitNameData: any = defaultPermitNames.find((v: any) => v.id === e);
                    fetchPermitTypes(permitNameData?.permit_type?.name);
                    fetchIssuerInstitutions(permitNameData?.permit_issuer_institution?.name);
                    form.setFieldsValue({
                      ...form,
                      required_time: permitNameData?.required_time,
                      permit_issuer_institution_id: permitNameData?.permit_issuer_institution_id,
                      permit_type_id: permitNameData?.permit_type_id
                    });
                  }}
                  filterOption={false}
                  onSearch={fetchPermitNames}
                  notFoundContent={isLoadingPermitNames ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                  disabled={isView || isEdit}
                >
                  {
                    permitNames.map(item => (
                      <Option key={item.value} value={item.value} label={item.label[getLanguage()]}>
                        <div>
                          {item.label[getLanguage()]}
                        </div>
                      </Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </div>
            <div className='md:col-span-1 max-md:col-span-2'>
              <Form.Item
                name='work_location_id'
                label={t('permit_monitoring.permit_location')}
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder={t('permit_monitoring.permit_location_placeholder')}
                  filterOption={false}
                  onSearch={fetchWorklocations}
                  notFoundContent={isLoadingWorklocation ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                  options={companies}
                  disabled={isView || isEdit}
                />
              </Form.Item>
            </div>
            <div className='md:col-span-1 max-md:col-span-2'>
              <Form.Item
                name='area_ha'
                label={t('permit_monitoring.area')}
              >
                <Input
                  placeholder={t('permit_monitoring.area_placeholder') || ''}
                  disabled={isView}
                />
              </Form.Item>
            </div>
            <div className='md:col-span-1 max-md:col-span-2'>
              <Form.Item
                name='permit_type_id'
                label={t('permit_monitoring.permit_type')}
                rules={[{ required: true }]}
              >
                <Select
                  allowClear
                  showSearch
                  onClear={() => {
                    fetchPermitNames('');
                    fetchPermitTypes('');
                    form.setFieldsValue({
                      ...form,
                      permit_name_id: ''
                    });
                  }}
                  onChange={(e) => {
                    if (e) {
                      const permitTypeData: any = defaultPermitTypes.find((v: any) => v.id === e);
                      const newPermitType = permitTypeData.permit_names.map((el: any) => {
                        if (el) {
                          return {
                            label: el.name,
                            value: el.id
                          };
                        }
                        return null;
                      });

                      setPermitNames(newPermitType.filter((v: any) => v));
                    }
                  }}
                  placeholder={t('permit_monitoring.permit_type_placeholder')}
                  options={permitTypes}
                  filterOption={false}
                  onSearch={fetchPermitTypes}
                  notFoundContent={isLoadingPermitTypes ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                  disabled={isView || isEdit}
                />
              </Form.Item>
            </div>
            <div className='md:col-span-1 max-md:col-span-2'>
              <Form.Item
                name='permit_issuer_institution_id'
                label={t('permit_monitoring.institution')}
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder={t('permit_monitoring.institution_placeholder')}
                  options={issuerInstitutions}
                  filterOption={false}
                  disabled
                />
              </Form.Item>
            </div>
            <div className='col-span-2'>
              <Form.Item
                name='description'
                label={t('permit_monitoring.description')}
              >
                <TextArea
                  style={{ height: 100, resize: 'none' }}
                  placeholder={t('permit_monitoring.description_placeholder') || ''}
                  disabled={isView}
                />
              </Form.Item>
            </div>
            <div className='md:col-span-1 max-md:col-span-2'>
              <Form.Item
                name='issued_date'
                label={t('permit_monitoring.active_date')}
                rules={[
                  { required: true },
                  { validator: validateBeforeExpireDate(t('permit_monitoring.active_date')) }
                ]}
                tooltip={t('permit_request.request_information.hint_active_date')}
              >
                <DatePicker
                  className='w-full'
                  placeholder={t('permit_monitoring.active_date_placeholder') || ''}
                  format={'DD-MMM-YYYY'}
                  disabled={isView}
                  disabledDate={disabledDateActiveAndRelease}
                />
              </Form.Item>
            </div>
            <div className='md:col-span-1 max-md:col-span-2'>
              <Form.Item
                name='released_date'
                label={t('permit_monitoring.release_date')}
                rules={[
                  { required: true },
                  { validator: validateBeforeExpireDate(t('permit_monitoring.release_date')) }
                ]}
                tooltip={t('permit_request.request_information.hint_release_date')}
              >
                <DatePicker
                  className='w-full'
                  placeholder={t('permit_monitoring.release_date_placeholder') || ''}
                  format={'DD-MMM-YYYY'}
                  disabled={isView}
                  disabledDate={disabledDateActiveAndRelease}
                />
              </Form.Item>
            </div>
            <div className='md:col-span-1 max-md:col-span-2'>
              <Form.Item
                name='expired_date'
                label={t('permit_monitoring.expired_date')}
                rules={[{ required: true }]}
              >
                <DatePicker
                  className='w-full'
                  placeholder={t('permit_monitoring.expired_date_placeholder') || ''}
                  format={'DD-MMM-YYYY'}
                  onChange={handleChangeExpireDate}
                />
              </Form.Item>
            </div>
            <div className='md:col-span-1 max-md:col-span-2'>
              <Form.Item
                name='processed_by'
                label={t('permit_monitoring.processed_by')}
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder={t('permit_monitoring.processed_by_placeholder')}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: 'ER Jakarta',
                      label: 'ER Jakarta',
                    },
                    {
                      value: 'Mine Site',
                      label: 'Mine Site',
                    }
                  ]}
                  disabled={isView || isEdit}
                />
              </Form.Item>
            </div>
            <div className='md:col-span-1 max-md:col-span-2'>
              <Form.Item
                name='status'
                label={t('permit_monitoring.status')}
                rules={[{ required: true }]}
              >
                <Select
                  allowClear
                  options={statuses}
                  placeholder={t('permit_monitoring.status_placeholder')}
                  disabled={isView}
                />
              </Form.Item>
            </div>
            <div className='md:col-span-1 max-md:col-span-2'>
              <Form.Item
                name='remaining_days'
                label={t('permit_monitoring.remaining_days')}
              >
                <Input
                  placeholder={t('permit_monitoring.remaining_days') || ''}
                  disabled
                />
              </Form.Item>
            </div>
            <div className='md:col-span-1 max-md:col-span-2'>
              <Form.Item
                name='permit_reference'
                label={t('permit_monitoring.permit_reference')}
              >
                <AutoComplete
                  options={options}
                  onSearch={fetchReferencePermit}
                  onChange={() => setSelectedPermitReference('')}
                  onSelect={selectReferencePermit}
                  placeholder={t('permit_monitoring.permit_reference_placeholder') || ''}
                  disabled={isView}
                >
                  <Input 
                    suffix={
                      <MdLink
                        size={20}
                        className={`${selectedPermitReference ? 'cursor-pointer' : 'cursor-disabled'}`}
                        fill="#55A853"
                        onClick={handleOnClickPermitReference}
                      />
                    }
                    className='w-[10px]'
                    disabled
                  />
                </AutoComplete>
              </Form.Item>
            </div>
            <div className='md:col-span-1 max-md:col-span-2'>
              <Form.Item
                name='expire_permit_reference'
                label={t('permit_monitoring.expire_reference')}
                rules={[{ required: form.getFieldsValue()?.permit_reference }]}
              >
                <DatePicker
                  className='w-full'
                  placeholder={t('permit_monitoring.expire_reference_placeholder') || ''}
                  format={'DD-MMM-YYYY'}
                  onChange={onChangeExpired}
                  disabled={isAutoReferencePermit || isView}
                />
              </Form.Item>
            </div>
          </div>
          <div className='mt-[44px]'>
            <Form.Item
              className='mt-3 font-bold'
              name="productDocAttachment"
              rules={[{ required: getRulesUpload(), message: `${t('permit_request.list_doc.attach_label')} ${t('general.required_field')}` }]}
              label={t('permit_monitoring.title_upload_docs')}
            >
              <Dragger
                multiple
                className='font-normal'
                customRequest={(opt) => uploadImageReqDocs(opt)}
                showUploadList={false}
                beforeUpload={beforeUploadFile}
                fileList={form.getFieldsValue()?.productDocAttachment?.fileList}
                disabled={detail?.permit_request_id}
                maxCount={5}
              >
                <span>{t('permit_request.list_doc.attach_two_placeholder')}
                  <span className='font-bold text-[#55A853] underline underline-offset-2'>
                    {t('permit_request.list_doc.browser_txt')}
                  </span>
                </span>
              </Dragger>
            </Form.Item>
            <div className='mt-5 grid grid-cols-3 md:grid-cols-5 xl:grid-cols-7 gap-5'>
              {
                prodDocAttach?.detail?.map((dataC: any, idxC: any) => (
                  <div key={idxC}>
                    <div className={`border ${getBorderStep(3)} rounded-md p-2`}>
                      <div className='flex justify-end'>
                        {
                          dataC?.isLoading ? (<Spin></Spin>) : (
                            <CloseOutlined
                              className={`text-[#ED3F3F] text-bold ${detail?.permit_request_id ? 'cursor-disabled' : 'cursor-pointer'}`}
                              onClick={() => detail?.permit_request_id ? '' : delReqDocsExisting(idxC)}
                            />
                          )
                        }
                      </div>
                      <div className='cursor-pointer'>
                        <div key={idxC} className='w-full flex justify-center'>
                          <FileOutlined className={`text-[30px] text-[${getColorStep(3)}]`} onClick={() => downloadFileUrl(dataC?.downloadUrl)} />
                        </div>
                        <p className='text-center mt-3 text-[10px] text-[#8B849B] truncate'>
                          {dataC?.name}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              }
              {
                prodDocAttach?.create?.map((dataC: any, idxC: any) => (
                  <div key={idxC}>
                    <div className={`border ${getBorderStep(dataC?.step)} rounded-md p-2`}>
                      <div className='flex justify-end'>
                        {
                          dataC?.isLoading ? (<Spin></Spin>) : (
                            <CloseOutlined
                              className='text-[#ED3F3F] text-bold cursor-pointer'
                              onClick={() => delReqDocs(idxC)}
                            />
                          )
                        }
                      </div>
                      <div className='cursor-pointer'>
                        <div key={idxC} className='w-full flex justify-center'>
                          <FileOutlined className={`text-[30px] text-[${getColorStep(dataC?.step)}]`} onClick={() => downloadFile(dataC?.file)} />
                        </div>
                        <p className='text-center mt-3 text-[10px] text-[#8B849B] truncate'>
                          {dataC?.step === 1 ? t('permit_request.list_doc.uploading') : dataC?.step === 2 ? t('permit_request.list_doc.failed') : dataC?.file?.name}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
          {
            !isView &&
            <div className='col-span-2 grid grid-cols-2 gap-3 content-end mt-4'>
              <Button
                type='text'
                className='bg-white border-[#55A853] text-[#55A853] font-bold'
                onClick={() => modalAction.setModalFormOpen(false)}
                disabled={isLoadingPut || isLoadingSubmit}
              >
                {t('general.cancel_btn') || ''}
              </Button>
              <Button
                className='bg-[#55A853] text-white font-bold'
                onClick={() => ''}
                htmlType="submit"
                disabled={isLoadingSubmit || isLoadingPut}
              >
                {
                  isLoadingSubmit || isLoadingPut
                    ? <Spin></Spin>
                    : isEdit
                      ? t('general.update_btn') || ''
                      : t('general.submit_btn') || ''
                }
              </Button>
            </div>
          }
        </Form>
      </div>
    </Modal>
  );
};

export default ModalForm;