import { Button, Modal } from 'antd';
import { useGetPermitRequestReportIndicatorByIdMutation } from 'api/PermitRequestReport';
import HistoryConversation from 'pages/Create/PermitLicenseRequest/HistoryConversation';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Toast from 'components/Toast';
import { toast } from 'react-toastify';
import { transformError } from 'utils/ErrorTransformer';

const ModalHistory = (props: any) => {
  const { modalAction, id, detail } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [dataReport, setDataReport] = useState({});
  const [activeTab, setActiveTab] = useState('2');

  const [getPermitRequestReportIndicatorById, {
    error: errorGetPermitRequestReportIndicatorById,
    isError: isErrorGetPermitRequestReportIndicatorById,
    isLoading: isLoadingGetPermitRequestReportIndicatorById
  }] = useGetPermitRequestReportIndicatorByIdMutation();

  const fetchReport = async () => {
    if (id) {
      const data = await getPermitRequestReportIndicatorById(id).unwrap();
      setDataReport(data);
    }
  };

  useEffect(() => {
    if (isErrorGetPermitRequestReportIndicatorById) {
      const errorMsg: any = errorGetPermitRequestReportIndicatorById;
      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
    }
  }, [isErrorGetPermitRequestReportIndicatorById]);

  useEffect(() => {
    if (id) {
      fetchReport();
    }
  }, [id]);

  useEffect(() => {
    setActiveTab('2');
  }, [modalAction?.isOpenHistory]);

  return(
    <Modal
      title={null}
      style={{ top: 20 }}
      open={modalAction.isOpenHistory}
      footer={null}
      width={1000}
      onOk={() => modalAction.setIsOpenHistory(false)}
      onCancel={() => modalAction.setIsOpenHistory(false)}
    >
      <div className='pt-7 lg:pt-0'>
        <HistoryConversation 
          id={id} 
          activeTab={activeTab}
          setActiveTab={setActiveTab} 
          detail={detail}
          fetchReport={fetchReport}
          detailReport={{
            data: dataReport,
            isLoading: isLoadingGetPermitRequestReportIndicatorById
          }}
        />
      </div>
      <div className='text-center'>
        <Button
          className='submit ml-5 bg-[#55A853] text-white'
          htmlType="button"
          onClick={() => navigate(`/permit-request/detail/${id}`)}
        >
          {t('permit_request.open_detail_permit')}
        </Button>
      </div>
    </Modal>
  );
};

export default ModalHistory;