import { Collapse, theme } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { FaUserEdit } from 'react-icons/fa';
import { FiFileText, FiHome, FiSettings } from 'react-icons/fi';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { BsListCheck } from 'react-icons/bs';
import { NavLink, useLocation } from 'react-router-dom';
import { hasPermission } from 'utils/Permission';
import img_logo_plis from 'icon/icon_logo_plis.svg';
import { VscFeedback } from 'react-icons/vsc';

const Sidebar = (props: any) => {
  const { isMouseInside, setIsMouseInside, isActive, setIsActive } = props;
  const { token } = theme.useToken();
  const { Panel } = Collapse;
  const { pathname } = useLocation();
  const [activeKey, setActiveKey] = useState('');

  const menuItem = [
    {
      path: '/dashboard',
      name: 'Dashboard',
      icon: <FiHome size={20} />,
      permission: hasPermission('dashboard').can_view
    },
    {
      path: '',
      name: 'Create',
      icon: <AiOutlineFileAdd size={20} />,
      subMenu: [
        {
          path: '/create-permit-request',
          name: 'Permit License Request',
          permission: hasPermission('create permit request').can_view
        },
        {
          path: '/permit-flow-process',
          name: 'Permit Flow Process',
          permission: hasPermission('permit flow process').can_view
        },
        {
          path: '/obligation-create',
          name: 'Obligation',
          permission: hasPermission('obligation').can_view
        },
        {
          path: '/initiate-permit-request',
          name: 'Initiate Permit Request',
          permission: hasPermission('initiate permit request').can_view
        },
      ],
      permission: hasPermission('create').can_view
    },
    {
      path: '/my-activity',
      name: 'My Activity',
      icon: <BsListCheck size={20} />,
      permission: hasPermission('my activity').can_view
    },
    {
      path: '',
      name: 'Document Library',
      icon: <FiFileText size={20} />,
      subMenu: [
        {
          path: '/permit-request',
          name: 'Permit Request',
          permission: hasPermission('permit request').can_view
        },
        {
          path: '/tracker',
          name: 'Permit Tracker',
          permission: hasPermission('tracker').can_view
        },
        {
          path: '/compliance-monitoring',
          name: 'Compliance Monitoring',
          permission: hasPermission('compliance monitoring').can_view
        },
        {
          path: '/permit-monitoring',
          name: 'Permit Monitoring ER',
          permission: hasPermission('permit monitoring').can_view
        },
        {
          path: '/permit-monitoring-crm',
          name: 'Permit Monitoring CRM',
          permission: hasPermission('permit monitoring crm').can_view
        },
        {
          path: '/report-feedback',
          name: 'Report Feedback',
          permission: hasPermission('report feedback').can_view
        },
        {
          path: '/report-er-performance',
          name: 'Report ER Performance',
          permission: hasPermission('report er performance').can_view
        },
        {
          path: '/obligation',
          name: 'Permit Obligation',
          permission: hasPermission('permit obligation').can_view
        },
      ],
      permission: hasPermission('document library').can_view
    },
    {
      path: '',
      name: 'Settings',
      icon: <FiSettings size={20} />,
      subMenu: [
        {
          path: '/application-profile',
          name: 'Application Profile',
          permission: hasPermission('application profile').can_view
        },
        {
          path: '/setting-permit-name',
          name: 'Setting Permit Name',
          permission: hasPermission('setting permit name').can_view
        },
        {
          path: '/setting-permit-type',
          name: 'Setting Permit Type',
          permission: hasPermission('setting permit type').can_view
        },
        {
          path: '/setting-feedback',
          name: 'Setting Feedback',
          permission: hasPermission('setting feedback').can_view
        },
        {
          path: '/clusters',
          name: 'Clusters',
          permission: hasPermission('clusters').can_view
        },
        {
          path: '/manage-cronjob',
          name: 'Manage Cronjob',
          permission: hasPermission('manage cronjob').can_view,
        },
        {
          path: '/email-notification',
          name: 'Email Notification',
          permission: hasPermission('email notification').can_view,
        }
      ],
      permission: hasPermission('setting').can_view
    },
    {
      path: '',
      name: 'User Management',
      icon: <FaUserEdit size={20} />,
      subMenu: [
        {
          path: '/manage-user',
          name: 'Manage User',
          permission: hasPermission('manage user').can_view
        },
        {
          path: '/manage-group',
          name: 'Manage Security Group',
          permission: hasPermission('manage group').can_view
        },
      ],
      permission: hasPermission('user management').can_view
    },
    {
      path: '/feedback',
      name: 'Feedback',
      icon: <VscFeedback size={20} />,
      permission: true
    },
  ];

  const panelStyle = {
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };

  const mouseLeave = () => {
    setIsMouseInside(true);
    setActiveKey('');
  };

  return (
    <div
      className={`${isActive ? '' : 'invisible'} md:visible bg-[#55A853] sidebar ${!isMouseInside ? 'expand' : ''}`}
      onMouseEnter={() => !isActive ? isMouseInside ? setIsMouseInside(false) : null : {}}
      onMouseLeave={() => !isActive ? mouseLeave() : ''}
    >
      <div className="menu">
        <div className="max-md:hidden flex justify-center top_section mb-5">
          <img src={img_logo_plis} alt="logo" />
        </div>
        {
          menuItem.filter(v => v.permission).map((item, index) => (
            <div key={index}>
              {item.subMenu?.length ?
                <Collapse
                  className={item.subMenu.find(v => v.path === pathname) ? 'active-collapse' : ''}
                  bordered={false}
                  expandIcon={({ isActive }) => <CaretRightOutlined className='text-[#55A853] text-lg' rotate={isActive ? 90 : 0} />}
                  expandIconPosition='end'
                  activeKey={activeKey}
                  onChange={() => activeKey === `${item.name}1` ? setActiveKey('') : setActiveKey(`${item.name}1`)}
                  accordion
                  ghost
                >
                  <Panel
                    header={
                      <div className='ant-collapse-expand-icon text-white truncate'>
                        <div className="icon mr-6">{item.icon}</div>
                        <span className=''>{item.name}</span>
                      </div>
                    }
                    key={item.name + '1'}
                    style={panelStyle}
                  >
                    {
                      item.subMenu.filter(v => v.permission).map((subMenu, subIndex) => (
                        <>
                          {
                            subMenu.path === '/create-permit-request' ? (
                              <NavLink
                                reloadDocument
                                to={subMenu.path}
                                key={subIndex}
                                className={({ isActive }) =>
                                  isActive ? 'active link truncate' : 'link truncate'
                                }
                                onClick={() => setIsActive(false)}
                              >
                                <span className='pl-4'>{subMenu.name}</span>
                              </NavLink>
                            ) : (
                              <NavLink
                                to={subMenu.path}
                                key={subIndex}
                                className={({ isActive }) =>
                                  isActive ? 'active link truncate' : 'link truncate'
                                }
                                onClick={() => setIsActive(false)}
                              >
                                <span className='pl-4'>{subMenu.name}</span>
                              </NavLink>
                            )
                          }
                        </>
                      ))
                    }
                  </Panel>
                </Collapse>
                :
                <NavLink
                  to={item.path}
                  key={index}
                  className={({ isActive }) =>
                    isActive ? 'active link truncate' : 'link truncate'
                  }
                  onClick={() => setIsActive(false)}
                >
                  <div className="icon mr-6">{item.icon}</div>
                  <span>{item.name}</span>
                </NavLink>
              }
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default Sidebar;