import { Button, Form } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Toast from 'components/Toast';
import { toast } from 'react-toastify';
import { PlusOutlined } from '@ant-design/icons';
import { usePostPermitNameObligationMutation } from 'api/PermitName';
import { useLocation, useNavigate, useParams } from 'react-router';
import ObligationFormItem from '../ObligationFormItem';

const PermitNameObligationForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [form] = Form.useForm();
  const initialForm = {
    dynamicObligation: [
      {
        name: '',
        actions: [
          {
            name: '',
          },
        ],
      },
    ],
  };

  const [postPermitNameObligation, { isLoading: isLoadingSubmitObligation }] = usePostPermitNameObligationMutation();

  const addObligation = () => {
    const newObligation = {
      name: '',
      actions: [
        {
          name: '',
        },
      ],
    };
    const newDynamicObligations = [...form.getFieldsValue()?.dynamicObligation];
    form.setFieldsValue({
      ...form,
      dynamicObligation: [...newDynamicObligations, newObligation],
    });
  };

  const removeObligation = (index: number) => {
    const obligations = form.getFieldValue('dynamicObligation');
    obligations.splice(index, 1);
    form.setFieldsValue({ dynamicObligation: obligations });
  };

  const addAction = (obligationIndex: number) => {
    const obligations = form.getFieldValue('dynamicObligation');
    obligations[obligationIndex].actions.push({ name: '' });
    form.setFieldsValue({ dynamicObligation: obligations });
  };

  const removeAction = (obligationIndex: number, actionIndex: number) => {
    const obligations = form.getFieldValue('dynamicObligation');
    obligations[obligationIndex].actions.splice(actionIndex, 1);
    form.setFieldsValue({ dynamicObligation: obligations });
  };

  const backToTabObligation = () => {
    const currentPath = location.pathname;
    const newPath = currentPath.split('/').slice(0, -1).join('/');
    navigate(newPath);
  };

  const onFinish = async () => {
    const dataForm = form.getFieldsValue().dynamicObligation;
    if (!dataForm || dataForm.length < 1) {
      toast.error(
        <Toast
          message={t('general.error_text')}
          detailedMessage={t(
            'manage_group.fill_form_validation_text'
          )}
        />
      );
      return;
    }

    const filteredObligations = form.getFieldsValue().dynamicObligation.map((obligation: any) => ({
      ...obligation,
      actions: obligation.actions.filter((action: any) => action.name.trim() !== ''),
    }));

    const submitForm = {
      permit_name_obligations: filteredObligations.map((obligation: any) => ({
        name: obligation.name,
        permit_name_id: id,
        permit_name_obligation_actions: obligation.actions.map((action: any) => ({
          name: action.name,
        })),
      })),
    };

    await postPermitNameObligation(submitForm).unwrap()
      .then(() => {
        toast.success(
          <Toast
            message={t('general.success_text')}
            detailedMessage={`${t('permit_name.obligation.success_submit_add_obligation')}`}
          />
        );

        // back to add permit name page tab obligation
        backToTabObligation();
      })
      .catch(() => {
        toast.error(
          <Toast
            message={t('general.error_text')}
            detailedMessage={t('permit_name.obligation.failed_submit_add_obligation')}
          />
        );
      });
  };

  useEffect(() => {
    form.setFieldsValue(initialForm);
  }, []);

  return (
    <>
      <Form form={form} onFinish={onFinish} requiredMark={false}>
        <Form.List name="dynamicObligation">
          {(fields) => (
            <>
              <div className="flex justify-between items-center mb-4">
                <span className="font-bold text-lg">
                  {t('permit_name.obligation.add_obligation')}
                </span>
              </div>
              {fields.map(({ key, name, ...restField }, obligationIndex) => (
                <div key={key} className="mb-5">
                  <ObligationFormItem
                    restField={restField}
                    key={key}
                    name={name}
                    canDeleteObligation={fields.length > 1}
                    removeObligation={() => removeObligation(obligationIndex)}
                    addAction={() => addAction(obligationIndex)}
                    removeAction={(actionIndex: number) => removeAction(obligationIndex, actionIndex)}
                  />
                </div>
              )
              )}
            </>
          )}
        </Form.List>
        <div onClick={addObligation} className='mb-4 cursor-pointer rounded-lg gap-2 p-4 border-2 hover:bg-slate-100 border-[#55A853] border-dashed flex items-center justify-center'>
          <PlusOutlined className='text-[#55A853] font-bold' />
          <span className='text-[#55A853] font-bold'>{t('permit_name.obligation.add_obligation')}</span>
        </div>

        <div className='bg-[#d7d7d7] h-[1px] mb-4'/>

        <div className="flex justify-between items-center">
          <Button
            className='cancel-green bg-[#E5EDE5] text-[#55A853] border-[#55A853]'
            htmlType="button"
            onClick={backToTabObligation}
          >
            {t('general.cancel_btn') }
          </Button>
          <Button
            type="text"
            className="bg-[#55A853] text-white font-bold"
            htmlType="submit"
            disabled={isLoadingSubmitObligation}
          >
            {t('general.submit_btn')}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default PermitNameObligationForm;
