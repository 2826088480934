import { useRoutes } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FirebaseData from 'services/Firebase/firebasedata';

import Routes from 'routes';
import { Suspense } from 'react';
import LoadingComponent from 'components/LoadingComponent';

const App = () => {
  return (
    <FirebaseData>
      <Suspense fallback={
        <LoadingComponent/>
      }>
        <div>
          <ToastContainer 
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            draggable
            pauseOnHover
            theme="colored"/>
          {useRoutes(Routes())}
        </div>
      </Suspense>
    </FirebaseData>
  );
};

export default App;
