import { Base } from './Base';

const Menu = Base.injectEndpoints({
  endpoints: (build) => ({
    listMenu: build.query<any, void>({
      query: () => ({
        url: '/menus/permissions',
        method: 'GET'
      })
    }),
    getMenusByRoleId: build.mutation({
      query: (id: any) => ({
        url: `/menus/permissions/${id}`,
        method: 'GET'
      })
    }),
  })
});

export const { useListMenuQuery, useGetMenusByRoleIdMutation } = Menu;