import { Button, Form, Modal, Select, Spin, Empty, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { OptionType } from 'constant/Types/OptionType';
import { RISK_ASSESSMENT_OPTIONS } from 'constant';
import { useGetComplianceStatusMutation } from 'api/PermitMonitoring';
import { useGetStatusMutation } from 'api/ComplianceMonitoring';
import { useWorklocSearchMutation } from 'api/Options';

const ModalFilter = (props: any) => {
  const { modalAction, filter, filterAction } = props;
  const { t } = useTranslation();
  const [complianceStatus, setComplianceStatus] = useState<OptionType[]>([]);
  const [status, setStatus] = useState<OptionType[]>([]);
  const [locations, setLocations] = useState<OptionType[]>([]);

  const [complianceStatusList, {
    isLoading: isLoadingComplianceStatus
  }] = useGetComplianceStatusMutation();
  const [getStatus, {
    isLoading: isLoadingGetStatus
  }] = useGetStatusMutation();
  const [locationList, {
    isLoading: isLoadinglocation
  }] = useWorklocSearchMutation();

  const fetchComplianceStatus = async (value: any) => {
    if (value.length > 2 || !value) {
      let data = await complianceStatusList({}).unwrap();
      const newDataComplianceStatus = data.map((el: any) => {
        if (el) {
          return {
            label: el?.value,
            value: el?.value
          };
        }
        return null;
      });

      setComplianceStatus(newDataComplianceStatus.filter((v: any) => v));
    }
  };

  const fetchStatus = async (value: any) => {
    if (value.length > 2 || !value) {
      let data = await getStatus({}).unwrap();
      const newDataComplianceStatus = data.map((el: any) => {
        if (el) {
          return {
            label: el,
            value: el
          };
        }
        return null;
      });

      setStatus(newDataComplianceStatus.filter((v: any) => v));
    }
  };

  const fetchLocations = async (value: any) => {
    if (value.length > 2 || !value) {
      const payload = {
        search: value ?? '',
        searchFields: 'name'
      };
      let { rows } = await locationList(payload).unwrap();
      const newDataWorklocation = rows.map((el: any) => {
        if (el) {
          return {
            label: el?.name,
            value: el?.id
          };
        }
        return null;
      });

      setLocations(newDataWorklocation.filter((v: any) => v.label));
    }
  };

  useEffect(() => {
    fetchComplianceStatus('');
    fetchStatus('');
    fetchLocations('');
  }, []);

  return (
    <Modal
      title={null}
      open={modalAction.isModalOpen}
      footer={null}
      width={500}
      onOk={() => modalAction.setIsModalOpen(false)}
      onCancel={() => modalAction.setIsModalOpen(false)}
    >
      <div className="bg-white p-[10px] rounded-lg">
        <p className='font-bold text-[16px] mb-5'>
          {t('manage_group.filter_label')}
        </p>
        <Form layout="vertical">
          <div className='grid grid-cols-1 gap-1'>
            <div className='col-span-1 mb-3'>
              <Form.Item
                label={t('compliance_monitoring.permit_location')}
                className='font-bold'
              >
                <Select
                  mode="multiple"
                  allowClear
                  className='font-normal'
                  style={{ width: '100%' }}
                  options={locations}
                  placeholder={t('compliance_monitoring.permit_location_placeholder')}
                  onChange={(e) => filter.setFilter({...filter.filter, permit_location: e})}
                  value={filter.filter.permit_location}
                  onSearch={fetchLocations}
                  filterOption={false}
                  notFoundContent={isLoadinglocation ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                />
              </Form.Item>
            </div>
            <div className='col-span-1 mb-3'>
              <Form.Item
                label={t('compliance_monitoring.compliance_status')}
                className='font-bold'
              >
                <Select
                  mode="multiple"
                  allowClear
                  className='font-normal'
                  style={{ width: '100%' }}
                  placeholder={t('compliance_monitoring.compliance_status_placeholder')}
                  onChange={(e) => filter.setFilter({ ...filter.filter, compliance_status: e })}
                  options={complianceStatus}
                  value={filter.filter.compliance_status}
                  filterOption={false}
                  notFoundContent={isLoadingComplianceStatus ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                />
              </Form.Item>
            </div>
            <div className='col-span-1 mb-3'>
              <Form.Item
                label={t('compliance_monitoring.curent_risk_assesment')}
                className='font-bold'
              >
                <Select
                  mode="multiple"
                  allowClear
                  className='font-normal'
                  style={{ width: '100%' }}
                  placeholder={t('compliance_monitoring.curent_risk_assesment_placeholder')}
                  onChange={(e) => filter.setFilter({ ...filter.filter, current_risk_assesment: e })}
                  options={RISK_ASSESSMENT_OPTIONS}
                  value={filter.filter.current_risk_assesment}
                  filterOption={false}
                />
              </Form.Item>
            </div>
            <div className='col-span-1 mb-3'>
              <Form.Item
                label={t('compliance_monitoring.status')}
                className='font-bold'
              >
                <Select
                  mode="multiple"
                  allowClear
                  className='font-normal'
                  style={{ width: '100%' }}
                  placeholder={t('compliance_monitoring.status_placeholder')}
                  onChange={(e) => filter.setFilter({ ...filter.filter, status: e })}
                  options={status}
                  value={filter.filter.status}
                  filterOption={false}
                  notFoundContent={isLoadingGetStatus ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                />
              </Form.Item>
            </div>
            <div className='col-span-1 mb-3'>
              <Form.Item
                label={t('compliance_monitoring.submitted_date')}
                className='font-bold'
              >
                <DatePicker.RangePicker
                  className='font-normal'
                  style={{ width: '100%' }}
                  onChange={(value) => {
                    filter.setFilter({ ...filter.filter, submitted_date: value });
                  }}
                  value={filter.filter.submitted_date}
                  placeholder={[t('compliance_monitoring.start_date'), t('compliance_monitoring.start_date')]}
                />
              </Form.Item>
            </div>
            <div className='grid grid-cols-2 gap-4 content-end mt-4'>
              <Button
                type='text'
                className='bg-white border-[#55A853] text-[#55A853] font-bold'
                onClick={filterAction.resetFilter}
                disabled={filterAction.isLoadingFilter}
              >
                {filterAction.isLoadingFilter ? <Spin></Spin> : t('manage_group.reset_btn') || ''}
              </Button>
              <Button
                type='text'
                className='bg-[#55A853] text-white font-bold'
                onClick={() => {
                  filterAction.applyFilter();
                  modalAction.setIsModalOpen(false);
                }}
                disabled={filterAction.isLoadingFilter}
              >
                {filterAction.isLoadingFilter ? <Spin></Spin> : t('manage_group.apply_btn') || ''}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ModalFilter;