import { useTranslation } from 'react-i18next';
import FeedbackDocument from './FeedbackDocument';
import { Segmented, DatePicker } from 'antd';
import { useState } from 'react';
import dayjs from 'dayjs';
import FeedbackApplication from './FeedbackApplication';

const SettingFeedback = () => {
  const { t } = useTranslation();
  const [validYear, setValidYear] = useState(dayjs());
  const [selectedFeedbackType, setSelectedFeedbackType] = useState(1);
  const feedbackType = [
    {
      value: 1,
      label: t('feedback.feedback_document')
    }, 
    {
      value: 2,
      label: t('feedback.feedback_application')
    }
  ];

  const handleChangeValidYear = async (e: any) => {
    setValidYear(dayjs(e));
  };

  return(
    <div>
      <div className="flex gap-4 items-center mb-8">
        <span>{t('feedback.valid_year')}</span>
        <DatePicker
          className="font-normal"
          picker="year"
          onSelect={handleChangeValidYear}
          value={validYear}
          allowClear={false}
        />
      </div>
      <div className='bg-white px-3 py-5'>
        <Segmented 
          value={selectedFeedbackType}
          onChange={(e:any) => setSelectedFeedbackType(e)}
          className='mb-5' 
          options={feedbackType} 
          size='large' 
          block 
        />
        {
          selectedFeedbackType === 1 ? <FeedbackDocument validYear={validYear} /> : <FeedbackApplication validYear={validYear} />
        }
      </div>
    </div>
  );
};

export default SettingFeedback;