
import { Form, Select, DatePicker, Spin, Empty, ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import PermitByLocationBar from './PermitByLocationBar';
import IssuerInstitutionBar from './IssuerInstitutionBar';
import ByMonthBar from './ByMonthBar';
import { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { ListPayloadType as ListPayloadPermitType } from 'constant/Types/PermitType';
import { useListPermitTypePaginationMutation } from 'api/PermitType';
import Toast from 'components/Toast';
import { transformError } from 'utils/ErrorTransformer';
import { toast } from 'react-toastify';
import { useWorklocSearchMutation } from 'api/Options';
import { useAppSelector } from 'store/Hooks';
import { 
  usePermitByLocationMutation,
  useIssuerInstitutionMutation,
  useByMonthMutation,
  usePermitTierMutation,
  usePermitBySectorMutation,
  useComplianceStatusMutation,
  useComplianceActivityMutation
} from 'api/Dashboard';
import { useLocation, useNavigate } from 'react-router';
import { hasPermission } from 'utils/Permission';
import Companies from './Companies';
import Block from './Block';
import PermitBySector from './PermitBySector';
import BarChart from './BarChart';

const Dashboard = () => {
  const { user } = useAppSelector((state) => state.auth);
  const {t} = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  
  const [permitTypes, setPermitTypes] = useState<any[]>([]);
  const [worklocations, setWorklocations] = useState<any[]>([]);
  const [erLocations, setErLocations] = useState<any[]>([]);
  const [isAdminView, setIsAdminView] = useState(false);
  const ref = useRef<any>(null);

  const [permitTierData, setPermitTierData] = useState({
    high: 0,
    medium: 0,
    low: 0
  });
  const [permitByLocation, setPermitByLocation] = useState({
    series: [],
    categories: []
  });
  const [issuerInstitutions, setIssuerInstitutions] = useState({
    series: [],
    categories: []
  });
  const [byMonth, setByMonth] = useState({
    series: [],
    categories: []
  });
  const [complianceStatus, setComplianceStatus] = useState({
    colors: ['#71B670', '#E3E31D', '#F5222D'],
    series: [],
    categories: []
  });
  const [complianceActivity, setComplianceActivity] = useState({
    colors: ['#4185F5', '#EB4434', '#FABE01', '#35A94C'],
    series: [],
    categories: []
  });
  const [permitBySector, setPermitBySector] = useState({
    series: [],
    categories: []
  });
  const [filter, setFilter] = useState({
    year: dayjs(),
    permit_type_id: '',
    location: '',
    er_pic_loc: '',
  });

  const [permitTypeList, {
    error: errorPermitTypeList,
    isError: isErrorPermitTypeList,
    isLoading: isLoadingPermitTypeList,
  }] = useListPermitTypePaginationMutation();

  const [worklocationList, {
    error: errorWorklocation,
    isError: isErrorWorklocation,
    isLoading: isLoadingWorklocation
  }] = useWorklocSearchMutation();
  
  const [getPermitByLocation, {
    error: errorGetPermitByLocation,
    isError: isErrorGetPermitByLocation,
    isLoading: isLoadingGetPermitByLocation
  }] = usePermitByLocationMutation();
  
  const [getIssuerInstitutions, {
    error: errorGetIssuerInstitutions,
    isError: isErrorGetIssuerInstitutions,
    isLoading: isLoadingGetIssuerInstitutions
  }] = useIssuerInstitutionMutation();
  
  const [getByMonth, {
    error: errorGetByMonth,
    isError: isErrorGetByMonth,
    isLoading: isLoadingGetByMonth
  }] = useByMonthMutation();
  
  const [getPermitTier, {
    error: errorGetPermitTier,
    isError: isErrorGetPermitTier,
    isLoading: isLoadingGetPermitTier
  }] = usePermitTierMutation();
  
  const [getPermitBySector, {
    error: errorGetPermitBySector,
    isError: isErrorGetPermitBySector,
    isLoading: isLoadingGetPermitBySector
  }] = usePermitBySectorMutation();
  
  const [getComplianceStatus, {
    error: errorGetComplianceStatus,
    isError: isErrorGetComplianceStatus,
    isLoading: isLoadingGetComplianceStatus
  }] = useComplianceStatusMutation();
  
  const [getComplianceActivity, {
    error: errorGetComplianceActivity,
    isError: isErrorGetComplianceActivity,
    isLoading: isLoadingGetComplianceActivity
  }] = useComplianceActivityMutation();

  const fetchPermitType = async (value: any) => {
    if (value.length > 2 || !value) {
      setPermitTypes([]);
      const payload:ListPayloadPermitType = {
        search: value ?? '',
        searchFields: 'name'
      }; 
      let listPermitType = await permitTypeList(payload).unwrap();
      const allData = [
        {
          label: 'All',
          value: ''
        }
      ];
      listPermitType = listPermitType?.rows?.map((v: any) => {
        return { label: v?.name, value: v?.id};
      });
      setPermitTypes(allData.concat(listPermitType));
    }
  };

  const fetchWorklocations = async (value: any) => {
    if (value?.length > 2 || !value) {
      const payload = {
        search: value ?? '',
        searchFields: 'name'
      }; 
      let { rows } = await worklocationList(payload).unwrap();
      const allData = [
        {
          label: 'All',
          value: ''
        }
      ];
      let newDataWorklocation = rows.map((el: any) => {
        if (el) {
          return {
            label: el?.name,
            value: el?.id
          };
        }
        return null;
      });

      setWorklocations(allData.concat(newDataWorklocation.filter((v: any) => v)));
    }
  };

  const fetchErLocation = async (value: any) => {
    if (value?.length > 2 || !value) {
      const payload = {
        search: value ?? '',
        searchFields: 'name'
      }; 
      let { rows } = await worklocationList(payload).unwrap();
      const allData = [
        {
          label: 'All',
          value: ''
        }
      ];
      let newDataErlocation = rows.map((el: any) => {
        if (el) {
          return {
            label: el?.name,
            value: el?.id
          };
        }
        return null;
      });

      setErLocations(allData.concat(newDataErlocation.filter((v: any) => v)));
    }
  };

  const fetchPermitByLocation = async (payload: any) => {
    const data = await getPermitByLocation(payload).unwrap();
    setPermitByLocation(data);
  };

  const fetchIssuerInstitutions = async (payload: any) => {
    const data = await getIssuerInstitutions(payload).unwrap();
    setIssuerInstitutions(data);
  };

  const fetchByMonth = async (payload: any) => {
    const data = await getByMonth(payload).unwrap();
    setByMonth(data);
  };

  const fetchPermitTier = async (payload:any) => {
    const data = await getPermitTier(payload).unwrap();
    setPermitTierData(data);
  };

  const fetchPermitBySector = async (payload:any) => {
    const data = await getPermitBySector(payload).unwrap();
    setPermitBySector(data);
  };

  const fetchComplianceStatus = async (payload:any) => {
    const data = await getComplianceStatus(payload).unwrap();
    setComplianceStatus({
      ...complianceStatus,
      series: data?.series,
      categories: data?.categories
    });
  };

  const fetchComplianceActivity = async (payload:any) => {
    const data = await getComplianceActivity(payload).unwrap();
    setComplianceActivity({
      ...complianceActivity,
      series: data?.series,
      categories: data?.categories
    });
  };

  const refetchData = (key:any, data:any) => {
    const payload:any = {
      year: dayjs(filter.year).format('YYYY'),
      permit_type_id: filter.permit_type_id,
      location_id: filter.location,
      er_pic_location_id: filter.er_pic_loc
    };

    payload[key] = key === 'year' ? dayjs(data).format('YYYY') : data;
    fetchPermitByLocation(payload);
    fetchIssuerInstitutions(payload);
    fetchByMonth(payload);
    fetchPermitTier({year: payload.year});
    fetchPermitBySector({year: payload.year});
    fetchComplianceStatus({year: payload.year});
    fetchComplianceActivity({year: payload.year});
  };

  const initialDashboard = async () => {
    const payload = {
      year: dayjs(filter.year).format('YYYY'),
      permit_type_id: filter.permit_type_id,
      location_id: filter.location,
      er_pic_location_id: filter.er_pic_loc
    };

    fetchPermitByLocation(payload);
    fetchIssuerInstitutions(payload);
    fetchByMonth(payload);
    fetchPermitTier({year: payload.year});
    fetchPermitBySector({year: payload.year});
    fetchComplianceStatus({year: payload.year});
    fetchComplianceActivity({year: payload.year});
  };

  const setView = () => {
    const isAdminViewData = user?.roles?.some((el:any) => el?.is_admin || el?.is_vip);
    setIsAdminView(isAdminViewData);
  };

  useEffect(() => {
    const paths = pathname.split('/');
    if (!hasPermission(paths[1]).can_view) {
      navigate('/403');
    }
    setView();
    fetchPermitType('');
    fetchWorklocations('');
    fetchErLocation('');

    initialDashboard();
  }, []);

  useEffect(() => {
    if (isErrorPermitTypeList){
      const errorMsg:any = errorPermitTypeList;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [errorPermitTypeList, isErrorPermitTypeList]);
  
  useEffect(() => {
    if (isErrorWorklocation){
      const errorMsg:any = errorWorklocation;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorWorklocation]);
  
  useEffect(() => {
    if (isErrorGetPermitByLocation){
      const errorMsg:any = errorGetPermitByLocation;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorGetPermitByLocation]);
  
  useEffect(() => {
    if (isErrorGetIssuerInstitutions){
      const errorMsg:any = errorGetIssuerInstitutions;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorGetIssuerInstitutions]);
  
  useEffect(() => {
    if (isErrorGetByMonth){
      const errorMsg:any = errorGetByMonth;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorGetByMonth]);
  
  useEffect(() => {
    if (isErrorGetPermitTier){
      const errorMsg:any = errorGetPermitTier;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorGetPermitTier]);
  
  useEffect(() => {
    if (isErrorGetPermitBySector){
      const errorMsg:any = errorGetPermitBySector;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorGetPermitBySector]);
  
  useEffect(() => {
    if (isErrorGetComplianceStatus){
      const errorMsg:any = errorGetComplianceStatus;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorGetComplianceStatus]);
  
  useEffect(() => {
    if (isErrorGetComplianceActivity){
      const errorMsg:any = errorGetComplianceActivity;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorGetComplianceActivity]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#55A853',
        },
      }}>
      <Form layout='vertical' className='mb-6'>
        <div className='grid md:grid-cols-3 gap-10 bg-white rounded-lg p-5'>
          <Form.Item
            label={t('dashboard.year')}
            className='font-bold'
          >
            <DatePicker
              className='font-normal'
              placeholder={t('dashboard.year_placeholder') || ''} 
              picker="year" 
              style={{ width: '100%' }}
              onSelect={(e) => {
                setFilter({...filter, year: dayjs(e)});
                refetchData('year', e);
              }}
              value={filter.year}
              allowClear={false}
            />
          </Form.Item>
        </div>
      </Form>
      <div className='bg-white rounded-lg p-5 mb-6'>
        <p className='text-[22px] font-bold mb-3'>{t('dashboard.companies.title')}</p>
        <Companies year={dayjs(filter.year).format('YYYY')}/>
      </div>
      <div className='bg-white rounded-lg p-5 mb-6 grid lg:grid-cols-2 gap-5 overflow-auto'>
        <Block data={permitTierData} isLoading={isLoadingGetPermitTier}/>
        <div className='w-[1000px] md:w-[100%]'>
          <p className='font-bold mb-2'>{t('dashboard.title_chart_1')}</p>
          {
            isLoadingGetComplianceStatus 
              ? <div className='mt-5 flex justify-center'>
                <Spin></Spin>
              </div>
              : <BarChart data={complianceStatus}/>
          }
        </div>
        <div className='w-[1000px] md:w-[100%]'>
          {
            isLoadingGetPermitBySector 
              ? <div className='mt-5 flex justify-center'>
                <Spin></Spin>
              </div>
              : <PermitBySector data={permitBySector}/>
          }
        </div>
        <div className='w-[1000px] md:w-[100%]'>
          <p className='font-bold mb-2'>{t('dashboard.title_chart_2') + ' ' + dayjs(filter.year).format('YYYY')}</p>
          {
            isLoadingGetComplianceActivity 
              ? <div className='mt-5 flex justify-center'>
                <Spin></Spin>
              </div>
              : <BarChart data={complianceActivity}/>
          }
        </div>
      </div>
      <Form layout='vertical' className='mb-6'>
        <div className='grid md:grid-cols-3 gap-10 bg-white rounded-lg p-5'>
          <Form.Item
            label={t('permit_request.request_information.permit_type')}
            className='font-bold'
          >
            <Select
              showSearch
              className='font-normal'
              style={{ width: '100%' }}
              placeholder={t('permit_request.request_information.permit_type_placeholder')}
              onChange={(e) => setFilter({...filter, permit_type_id: e})}
              onSearch={fetchPermitType}
              onSelect={(e) => refetchData('permit_type_id', e)}
              value={filter.permit_type_id}
              options={permitTypes}
              filterOption={false}
              notFoundContent={isLoadingPermitTypeList ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            />
          </Form.Item>
          <Form.Item
            label={t('dashboard.location')}
            className='font-bold'
          >
            <Select
              showSearch
              className='font-normal'
              style={{ width: '100%' }}
              placeholder={t('dashboard.location_placeholder')}
              onChange={(e) => setFilter({...filter, location: e})}
              onSearch={fetchWorklocations}
              onSelect={(e) => refetchData('location_id', e)}
              value={filter?.location}
              filterOption={false}
              options={worklocations}
              notFoundContent={isLoadingWorklocation ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            />
          </Form.Item>
          <Form.Item
            label={t('dashboard.er_pic_loc')}
            className='font-bold'
          >
            <Select
              showSearch
              className='font-normal'
              style={{ width: '100%' }}
              placeholder={t('dashboard.er_pic_loc_placeholder')}
              onChange={(e) => setFilter({...filter, er_pic_loc: e})}
              onSearch={fetchErLocation}
              onSelect={(e) => refetchData('er_pic_location_id', e)}
              value={filter?.er_pic_loc}
              filterOption={false}
              options={erLocations}
              notFoundContent={isLoadingWorklocation ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            />
          </Form.Item>
        </div>
      </Form>

      {
        isAdminView && <div className='grid md:grid-cols-3 mb-6'>
          <div className='bg-white col-span-1 mb-3 md:mb-0 md:mr-4 rounded-lg font-bold'>
            <p className='px-5 pt-5 text-[14px] font-bold pb-3 border-dashed border-b-2 border-gray-300'>
              {t('dashboard.permit_by_location')}
            </p>
            {
              isLoadingGetPermitByLocation 
                ? <div className='mt-5 flex justify-center'>
                  <Spin></Spin>
                </div>
                : <div className='h-[350px] overflow-y-auto pt-5'>
                  {
                    permitByLocation?.categories?.length
                      ? <PermitByLocationBar data={permitByLocation}/>
                      : <div className='flex justify-center items-center h-full'>
                        <div>
                          <img src='images/img_no_data.svg' alt='img-no-data'/>
                          <p className='mt-5 text-[#AAAAAA] text-[12px] text-center'>{t('general.no_data')}</p>
                        </div>
                      </div>
                  }
                </div>
            }
          </div>
          <div className='bg-white md:col-span-2 mb-3 md:mb-0 md:ml-2 rounded-lg font-bold'>
            <p className='px-5 pt-5 text-[14px] font-bold pb-3 border-dashed border-b-2 border-gray-300'>
              {t('dashboard.permit_issuer_institution')}
            </p>
            {
              isLoadingGetIssuerInstitutions 
                ? <div className='mt-5 flex justify-center'>
                  <Spin></Spin>
                </div>
                : <div ref={ref} className='h-[350px] w-[90vw] md:w-[100%] overflow-auto pt-5'>
                  {
                    issuerInstitutions?.categories?.length
                      ? <div className={'w-[1000px] md:w-[100%]'}><IssuerInstitutionBar data={issuerInstitutions}/></div>
                      : <div className='flex justify-center items-center h-full'>
                        <div>
                          <img src='images/img_no_data.svg' alt='img-no-data'/>
                          <p className='mt-5 text-[#AAAAAA] text-[12px] text-center'>{t('general.no_data')}</p>
                        </div>
                      </div>
                  }
                </div>
            }
          </div>
        </div>
      }

      {
        isAdminView && <div className='bg-white rounded-lg font-bold mb-6'>
          <p className='px-5 pt-5 text-[14px] font-bold pb-3 border-dashed border-b-2 border-gray-300'>
            {t('dashboard.by_month')}
          </p>
          {
            isLoadingGetByMonth 
              ? <div className='mt-5 flex justify-center'>
                <Spin></Spin>
              </div>
              : <div className='h-[350px] overflow-auto pt-5'>
                {
                  byMonth?.categories?.length
                    ? <div className='w-[1000px] md:w-[100%]'><ByMonthBar data={byMonth}/></div>
                    : <div className='flex justify-center items-center h-full'>
                      <div>
                        <img src='images/img_no_data.svg' alt='img-no-data'/>
                        <p className='mt-5 text-[#AAAAAA] text-[12px] text-center'>{t('general.no_data')}</p>
                      </div>
                    </div>
                }
              </div>
          }
        </div>
      }
    </ConfigProvider>
  );
};

export default Dashboard;