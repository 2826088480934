import Title from 'antd/es/typography/Title';

const Forbidden = () => {
  return(
    <div className="mt-5 text-center">
      <Title>Forbidden!</Title>
      <Title level={4}>Code 403</Title>
      <p>You don't have permission to access.</p>
    </div>
  );
};

export default Forbidden;