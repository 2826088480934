import { useTranslation } from 'react-i18next';
import { Button, Switch, Modal } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import DataTable from 'components/DataTable';
import { useEffect, useState } from 'react';
import { EyeOutlined, EditOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { ButtonDataTableTypeItems} from 'constant/Types/DataTableConfigType';
import { useLocation, useNavigate } from 'react-router';
import { ListPayloadType, UserListType } from 'constant/Types/UserType';
import { useActivateUserMutation, useUserListMutation } from 'api/User';
import Toast from 'components/Toast';
import { toast } from 'react-toastify';
import { transformError } from 'utils/ErrorTransformer';
import { OptionType } from 'constant/Types/OptionType';
import ModalFilter from './ModalFilter';
import { hasPermission } from 'utils/Permission';

const ManageUser = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { confirm } = Modal;
  const [companies, setCompanies] = useState<OptionType[]>([]);
  const [worklocations, setWorklocations] = useState<OptionType[]>([]);
  const [roles, setRoles] = useState<OptionType[]>([]);
  const [data, setData] = useState({
    total: 0,
    users: []
  });
  const [payload, setPayload] = useState<ListPayloadType>({
    page: 1,
    pageSize: 50,
    sort: 'username',
    searchFields: ['username email company_name work_location_name'],
    search: '',
    status: [],
    role_id: [],
    company_name: [],
    work_location_name: []
  });
  const [filter, setFilter] = useState({
    status: [],
    role_id: [],
    company_name: [],
    work_location_name: []
  });
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [canView, setCanView] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  
  const [userList, {
    error,
    isError,
    isLoading
  }] = useUserListMutation();

  const [activateUser, {
    error: errorActivate,
    isError: isErrorActivate,
    isSuccess: isSuccessActivate
  }] = useActivateUserMutation();

  const applyFilter = () => {
    setIsLoadingFilter(true);
    setPayload({
      ...payload,
      page: 1,
      role_id: filter.role_id,
      company_name: filter.company_name,
      work_location_name: filter.work_location_name,
      status: filter.status
    });
  };

  const resetFilter = () => {
    setIsLoadingFilter(true);
    setFilter({
      status: [],
      role_id: [],
      company_name: [],
      work_location_name: []
    });
    setPayload({
      ...payload,
      status: [],
      role_id: [],
      company_name: [],
      work_location_name: []
    });
  };

  const fetchList = async () => {
    let convertPayload:any = payload;
    let newPayload:any = {};
    for (const key in convertPayload) {
      if (convertPayload[key]){
        newPayload[key] = convertPayload[key];
      }
    }

    let users = await userList(newPayload).unwrap();
    users = {...users, users: users.rows.map((el: any, idx: any) => ({...el, key: idx}))};
    setData(users);
    setIsLoadingFilter(false);
  };

  const showModalConfirm = (data:any, value:any) => {
    const payloadActivate = {
      id: value.id,
      params: {
        id: value.id,
        is_active: !data
      }
    };

    confirm({
      title: `${t('manage_user.activate_confirm_text')} ${data ? 'Inactive' : 'Activate'} User ${value.display_name}`,
      icon: <ExclamationCircleFilled />,
      okText: t('manage_group.yes_btn'),
      okType: 'danger',
      cancelText: t('manage_group.no_btn'),
      async onOk() {
        await activateUser(payloadActivate).unwrap();
        setPayload({ ...payload });
      },
      onCancel() {
        setPayload({ ...payload });
      },
    });
  };

  useEffect(() => {
    const paths = pathname.split('/');
    if (!hasPermission(paths[1]).can_view) {
      navigate('/403');
    }
    setCanView(hasPermission(paths[1]).can_view);
    setCanEdit(hasPermission(paths[1]).can_update);
  }, []);

  useEffect(() => {
    fetchList();
  }, [payload]);

  useEffect(() => {
    if (isError) {
      const errorMsg:any = error;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccessActivate) {
      toast.success(<Toast message={t('general.success_text')} detailedMessage={`${t('manage_user.success_msg_activate')}`} />);
    }
  }, [isSuccessActivate]);

  useEffect(() => {
    if (isErrorActivate) {
      const errorMsg:any = errorActivate;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
    }
  }, [isErrorActivate]);

  const buttonConfig: ButtonDataTableTypeItems = [
    {
      name: t('manage_user.btn_add_user'),
      link: '',
      className: 'bg-[#55A853] text-white font-bold hidden',
      onClick: () => { navigate('/manage-user/create'); }
    }
  ];

  const columns: ColumnsType<UserListType> = [
    {
      title: 'Action',
      key: 'action',
      width: 120,
      render: (_, record) => (
        <div>
          {
            canView &&
            <Button type='text' className='shadow-md shadow-gray-400 rounded-sm mr-3 p-0'>
              <EyeOutlined style={{fontSize: '20px'}} className='text-[#55A853] p-1' onClick={() => navigate(`/manage-user/detail/${record.id}`)} />
            </Button>
          }
          {
            canEdit &&
            <Button type='text' className='shadow-md shadow-gray-400 rounded-sm mr-3 p-0'>
              <EditOutlined style={{fontSize: '20px'}} className='text-[#55A853] p-1' onClick={() => navigate(`/manage-user/edit/${record.id}`)} />
            </Button>
          }
        </div>
      ),
    },
    {
      title: 'Is Active',
      dataIndex: 'is_active',
      width: 100,
      key: 'is_active',
      render: (data, value) => {
        return (
          <div>
            {
              canEdit &&
              <Switch checked={data} className='bg-slate-300' onChange={() => showModalConfirm(data, value)}/>
            }
          </div>
        );
      },
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      width: 200,
      sorter: true,
      defaultSortOrder: 'ascend'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 360,
      key: 'email',
      sorter: true
    },
    {
      title: 'Role',
      dataIndex: 'roles',
      width: 400,
      key: 'roles',
      render: (data) => (
        <div className='w-80 grid grid-cols-1 md:grid-cols-2 gap-5'>
          {
            data?.map((data: any, idx: any) => (
              <div
                key={idx}
                className='bg-[#D3E3CE] text-[#1D6916] font-bold text-center rounded-lg truncate'>
                {data.name}
              </div>
            ))
          }
        </div>
      ),
    },
    {
      title: 'Company',
      dataIndex: 'company_name',
      key: 'company_name',
      sorter: true
    },
    {
      title: 'Work Location',
      dataIndex: 'work_location_name',
      key: 'work_location_name',
      sorter: true
    },
  ];

  return (
    <div>
      <ModalFilter
        modalAction={{
          isModalOpen: isModalOpen,
          setIsModalOpen: setIsModalOpen
        }}
        filter={{
          filter: filter,
          setFilter: setFilter
        }}
        filterAction={{
          isLoadingFilter: isLoadingFilter,
          resetFilter: resetFilter,
          applyFilter: applyFilter
        }}
        options={{
          companies: companies,
          setCompanies: setCompanies,
          worklocations: worklocations,
          setWorklocations: setWorklocations,
          roles: roles,
          setRoles: setRoles
        }}
      />
      <DataTable
        columns={columns}
        data={data.users}
        buttonConfig={buttonConfig}
        loading={isLoading}
        totalData={data.total}
        payload={{
          payload: payload,
          setPayload: setPayload
        }}
        modalOpen={{
          isModalOpen: isModalOpen,
          setIsModalOpen: setIsModalOpen
        }}
        scrollWidth={1700}
      />
    </div>
  );
};

export default ManageUser;