import { Button, Modal, ConfigProvider, Form, Input, Spin, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { UserOutlined, EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { DOMAIN_LIST } from 'constant';

const ModalLogin = (props: any) => {
  const { 
    openModal, 
    setOpenModal,
    onLogin,
    isLoading,
    form,
    onLoginFailed
  } = props;
  const { t } = useTranslation();
  const [isHide, setIsHide] = useState(true);
  const [paramModal, setParamModal] = useState({});

  const handleCancel = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
      setParamModal({
        width: 1000
      });
    } else {
      setParamModal({
        centered: true,
        width: 430
      });
    }
  }, []);

  return (
    <>
      <Modal
        {...paramModal}
        open={openModal}
        onCancel={handleCancel}
        footer={null}
        className='login'
      >
        <img
          src='images/img_logo_itm.svg' 
          alt='img-login'
          className='w-1/5'
        />
        <p className='mt-[30px] text-[24px] font-bold'>{t('login.text_classic')}</p>
        <p>{t('login.text_journey')}</p>
        <Form 
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
          className='pt-[40px]' 
          onFinish={onLogin} 
          onFinishFailed={onLoginFailed} 
          form={form}
        >
          <p className='text-[16px]'>{t('login.username_label')}</p>
          <Form.Item 
            name="username" 
            style={{ marginBottom: '15px' }} 
            rules={[{ required: true, message: t('login.username_label') + t('login.required_field') }]}
          >
            <Input
              size='large'
              suffix={<UserOutlined className='text-[#8897AD]' />} 
              placeholder={t('login.username_placeholder') || ''}
            /> 
          </Form.Item>
          <p className='text-[16px]'>{t('login.password_label')}</p>
          <Form.Item 
            name="password" 
            style={{ marginBottom: '15px' }} 
            rules={[{ required: true, message: t('login.password_label') + t('login.required_field') }]}
          >
            <Input
              size='large'
              type={isHide ? 'password' : 'text'}
              placeholder={t('login.password_placeholder') || ''}
              suffix={!isHide ? <EyeFilled className='cursor-pointer text-[#8897AD]' onClick={() => setIsHide(!isHide)}/> : <EyeInvisibleFilled className='cursor-pointer text-[#8897AD]' onClick={() => setIsHide(!isHide)} />}
            />
          </Form.Item>
          <p className='text-[16px]'>{t('login.domain_label')}</p>
          <Form.Item 
            name="domain" 
            style={{ marginBottom: '15px' }} 
            rules={[{ required: true, message: t('login.domain_label') + t('login.required_field') }]}
          >
            <Select
              size='large'
              options={DOMAIN_LIST}
              allowClear
            />
          </Form.Item>
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  colorPrimaryHover: '#1e3f1f',
                },
              },
            }}>
            <Button 
              className='mt-[40px] w-full h-[54px] rounded-xl text-[16px] text-white bg-gradient-to-r from-[#8DC58C] to-[#54A853]'
              type='text'
              htmlType="submit"
              disabled={isLoading}
            >
              {isLoading ? <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: 'white',
                  },
                }}
              >
                <Spin />
              </ConfigProvider> : t('login.login_btn') || ''}
            </Button>
          </ConfigProvider>
          <p className='lg:hidden text-[#818181] text-center mt-5' onClick={handleCancel}>{t('general.cancel_btn')}</p>
        </Form>
      </Modal>
    </>
  );
};

export default ModalLogin;