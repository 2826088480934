import { Table, Select, Input, Button, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';
import { ENTRIESLIST } from 'constant';
import { UserListType } from 'constant/Types/UserType';
import { SorterResult } from 'antd/es/table/interface';
import { useEffect, useState } from 'react';
import { FaFilter } from 'react-icons/fa';
import { getWindowDimensions } from 'utils/Utility';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = (props: RowProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: 'move',
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};

const DataTable = (props: any) => {
  const {
    columns,
    data,
    setData = () => {},
    buttonConfig = null,
    loading,
    totalData,
    payload = null,
    modalOpen,
    showHeader,
    notificationMarkAll,
    scrollWidth,
    expandable,
    withoutSearch,
    sortable = false,
    pagination = true,
    fixedHeader = true,
    callbackDrag = async () => {},
    rowKey = 'id',
    tableName
  } = props;
  const [search, setSearch] = useState('');
  const [windowHeight, setWindowHeight] = useState(0);
  const { t } = useTranslation();

  const onChangeSearch = (event: any) => {
    if ((event.target.value.length > 2 || event.target.value.length === 0) && payload){
      payload.setPayload({...payload.payload, search: event.target.value, page: 1});
    }
    setSearch(event.target.value);
  };

  const onChangeEntries = (event: any) => {
    if(payload){
      payload.setPayload({...payload.payload, pageSize: event, page: 1});
    }
  };

  const onChangeTable = (pagination: any, b: any, sorter: any) => {
    if(payload){
      const sort = sorter as SorterResult<UserListType>;
      const order = sort?.order ? (sort.order === 'ascend' ? '' : '-') : '';
      payload.setPayload({...payload.payload, page: pagination.current, sort: (order || '') + (sort?.order ? sort?.field || '' : '')});
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
        distance: 1,
      },
    }),
  );

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = data.findIndex((i: any) => i.id === active.id);
      const overIndex = data.findIndex((i: any) => i.id === over?.id);

      const previousData = [...data]; // Save a copy of the data before changes

      setData((prev: any) => {
        return arrayMove(prev, activeIndex, overIndex);
      });

      callbackDrag({
        id: active.id,
        from: activeIndex,
        to: overIndex,
      }).catch(() => {
        // If the callback fails, revert to previous data
        setData(previousData);
      });
    }
  };

  const paginationConfig = pagination && payload ? {
    current: payload.payload.page,
    pageSize: payload.payload.pageSize,
    total: totalData,
    showTotal: (total: number, range: [number, number]) => (
      <span className='md:absolute md:left-0'>
        {`${t('data_table.show_text')} ${range[0]}-${range[1]} ${t('data_table.of_text')} ${total} ${t('data_table.entries_text')}`}
      </span>
    ),
  } : false;
  
  useEffect(() => {
    setWindowHeight(getWindowDimensions()?.height || 0);
  }, []);

  const table = (
    <Table
      components={sortable ? { body: { row: Row } } : undefined}
      pagination={paginationConfig}
      className='rounded-lg'
      columns={columns}
      expandable={expandable}
      rowKey={rowKey}
      dataSource={data}
      loading={loading}
      onChange={onChangeTable}
      showHeader={showHeader}
      scroll={fixedHeader ? { x: (scrollWidth || 0), y: (windowHeight - (windowHeight/2.5)) } : undefined}
      rowClassName={(record, index) => {
        if (tableName === 'permit-monitoring-crm'){
          return '';
        }
        
        switch (record.days) {
          case 'P1':
            return 'bg-[#FFB9A3]';
          case 'P2':
            return 'bg-[#FBEB96]';
          case 'P3':
            return 'bg-[#C3ECAA]';
          default:
            return '';
        }
      }}
    />
  );

  return (
    <div className='px-5 pt-5'>
      <div className='grid grid-cols-1 md:grid-cols-3 justify-between mb-5'>
        <div className='mb-5 md:mb-0 flex'>
          {buttonConfig && buttonConfig.map((data: any, idx: any) => (
            <Button type='text' key={idx} className={data.className} onClick={data.onClick}>{data.name}</Button>
          ))}
          {
            notificationMarkAll ? (
              <div className='flex'>
                <Checkbox
                  checked={notificationMarkAll.notificationMarkAll}
                  onChange={(e) => notificationMarkAll.changeMarkAll(e)}
                  className='text-[#55A853] my-auto'
                />
                {
                  notificationMarkAll.notificationCheck.find((item:any) => item.isCheck) ?
                    <p
                      className='text-[#55A853] text-[12px] px-3 my-auto cursor-pointer'
                      onClick={notificationMarkAll.setMarkAsRead}>
                      {t('notification.mark_as_read')}
                    </p> :
                    <p
                      className='text-[#838090] text-[12px] px-3 my-auto cursor-disabled'>
                      {t('notification.mark_as_read')}
                    </p>
                }
              </div>
            ) : (<></>)
          }
        </div>
        {pagination && (
          <div className='flex items-center justify-center mb-5 md:mb-0'>
            <p className='text-[12px] font-normal mr-3'>{t('data_table.show_text')}</p>
            {payload && (
              <Select
                defaultValue={payload.payload.pageSize}
                style={{ width: 120 }}
                options={ENTRIESLIST}
                onChange={onChangeEntries}
              />
            )}
            <p className='text-[12px] font-normal ml-3'>{t('data_table.entries_text')}</p>
          </div>
        )}
        <div className='flex md:justify-end flex-row items-center'>
          {
            !withoutSearch  ?
              (
                <Input className='md:w-[70%]' value={search} onChange={onChangeSearch} placeholder={t('data_table.search_placeholder') || ''} prefix={<SearchOutlined className='text-[#55A853]' />} />
              ) : (<></>)
          }
          {
            modalOpen ?
              (
                <Button
                  type='text'
                  className='shadow-md rounded-lg p-0 bg-white ml-5'
                  onClick={() => modalOpen.setIsModalOpen(!modalOpen.isModalOpen)}
                  disabled={data.status === 'Draft'}
                >
                  <FaFilter style={{fontSize: '30px'}} className='text-[#55A853] p-1' />
                </Button>
              )
              : (<></>)
          }
        </div>
      </div>
      {
        sortable ? (
          <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
            <SortableContext
              items={data.map((item: any) => item.id)}
              strategy={verticalListSortingStrategy}
            >
              {table}
            </SortableContext>
          </DndContext>
        ) : (
          table
        )
      }
    </div>
  );
};

export default DataTable;
