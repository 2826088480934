import { Base } from './Base';
import { BodyClusterType } from 'constant/Types/Cluster';

const Cluster = Base.injectEndpoints({
  endpoints: (build) => ({
    list: build.query<any, void>({
      query: () => ({
        url: '/clusters',
        method: 'GET'
      })
    }),
    postCluster: build.mutation({
      query: (body: BodyClusterType) => ({
        url: '/clusters',
        method: 'POST',
        body
      })
    }),
    putCluster: build.mutation({
      query: (body: BodyClusterType) => ({
        url: '/clusters/'+body.id,
        method: 'PUT',
        body
      })
    }),
  })
});

export const { 
  useListQuery,
  usePostClusterMutation,
  usePutClusterMutation
} = Cluster;