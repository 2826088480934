import { useTranslation } from 'react-i18next';
import { FaChevronLeft } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router';
import { Empty, Button, ConfigProvider, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useGetPermitNameByPermitGuidanceIdMutation } from 'api/PermitGuidance';
import { toast } from 'react-toastify';
import Toast from 'components/Toast';
import { transformError } from 'utils/ErrorTransformer';

const PermitFlowProcessChildView = () => {
  const { t, i18n } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState<PermitFlowProcessChild[]>([]);

  interface PermitFlowProcessChild {
    id: string,
    image: string,
    name: any,
    guidance_url: string
  }

  const [getPermitNameByPermitGuidanceIdMutation, {
    error: errorGetPermitNameByPermitGuidanceIdMutation,
    isError: isErrorGetPermitNameByPermitGuidanceIdMutation,
    isLoading
  }] = useGetPermitNameByPermitGuidanceIdMutation();

  const fetchList = async () => {
    const data = await getPermitNameByPermitGuidanceIdMutation(id).unwrap();
    setData(data);
  };

  useEffect(() => {
    fetchList();
  }, []);

  useEffect(() => {
    if (isErrorGetPermitNameByPermitGuidanceIdMutation) {
      const errorMsg: any = errorGetPermitNameByPermitGuidanceIdMutation;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorGetPermitNameByPermitGuidanceIdMutation, errorGetPermitNameByPermitGuidanceIdMutation]);

  return(
    <div>
      <div className='flex gap-1 items-center mb-4 cursor-pointer text-sm text-[#939aa5] mb-5' onClick={() => navigate('/permit-flow-process')}>
        <FaChevronLeft />
        <span>
          {t('general.back')}
        </span>
      </div>
      {
        isLoading ? (
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#55A853',
              },
            }}
          >
            <Spin className="flex justify-center mt-5" size="large" />
          </ConfigProvider>) :
          !data.length ? (
            <div className="flex justify-center mt-5 pt-5">
              <Empty/>
            </div>
          ) : 
            (<div className='grid grid-cols-1 place-items-center md:grid-cols-3 md:gap-5 lg:grid-cols-4 lg:gap-4'>
              {
                data.map((el:PermitFlowProcessChild) => (
                  <div className='flex justify-center bg-white p-3 rounded-lg mb-3 h-full w-full'>
                    <div>
                      <div className='h-[280px]'>
                        <img 
                          src={el.image} 
                          width={280} 
                          alt=''
                          loading='lazy'
                        />
                      </div>
                      <p className='font-bold mt-5 mb-2 text-center'>{el.name[getLanguage()]}</p>
                      <div className='text-center mt-5'>
                        <Button
                          className={'bg-[#55A853] text-white w-full'}
                          htmlType="button"
                          onClick={() => el?.guidance_url ? window.open(el.guidance_url) : ''}
                          disabled={!el.guidance_url}
                        >
                          {t('permit_flow_process.btn_view_guide')}
                        </Button>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>)
      }
    </div>
  );
};

export default PermitFlowProcessChildView;