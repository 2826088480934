import DataTable from 'components/DataTable';
import type { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { ListPayloadType } from 'constant/Types/NotificationType';
import Checkbox from 'antd/es/checkbox/Checkbox';
import { useNotificationListMutation, useMarkReadMutation, useTotalUnreadMutation } from 'api/Notification';
import moment from 'moment-timezone';
import Toast from 'components/Toast';
import { transformError } from 'utils/ErrorTransformer';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Modal } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { setTotalUnreadGeneral } from 'store/Notification';
import { useAppDispatch } from 'store/Hooks';
import { store } from 'store';

const Notification = () => {
  const { confirm } = Modal;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = store.getState().auth.user;
  const [data, setData] = useState({
    total: 0,
    rows: []
  });
  const [payload, setPayload] = useState<ListPayloadType>({
    page: 1,
    pageSize: 50,
    search: '',
    searchFields: ['name remark status request_number'],
    sort: '-created_at'
  });
  const [notificationMarkAll, setNotificationMarkAll] = useState(false);
  const [notificationCheck, setNotificationCheck] = useState<any[]>([]);
  const [getListNotif, {
    error: errorGetListNotif,
    isError: isErrorGetListNotif,
    isLoading: isLoadinGetListNotif
  }] = useNotificationListMutation();

  const [markAsRead, {
    error: errorMarkAsRead,
    isError: isErrorMarkAsRead,
    isLoading: isLoadingMarkAsRead
  }] = useMarkReadMutation();

  const [getTotalUnread] = useTotalUnreadMutation();

  interface DataType {
    key: any;
    id: any,
    message: any
  }

  const fetchTotalUnread = async () => {
    await getTotalUnread({}).unwrap().then((resp: any) => {
      dispatch(setTotalUnreadGeneral(resp?.total));
    });
  };

  const setMarkAsRead = () => {
    let ids:any = [];
    for (let i=0; i<notificationCheck.length; i++){
      if (notificationCheck[i]?.isCheck){
        ids.push(notificationCheck[i]?.id);
      }
    }

    confirm({
      title: t('notification.confirm_text'),
      icon: <ExclamationCircleFilled />,
      okText: t('manage_group.yes_btn'),
      okType: 'danger',
      cancelText: t('manage_group.no_btn'),
      async onOk() {
        const payload = {
          ids: ids
        };
        markAsRead(payload).unwrap().then((resp:any) => {
          toast.success(<Toast message={t('general.success_text')} detailedMessage={''} />);
          setNotificationMarkAll(false);
          setNotificationCheck(notificationCheck.map(el => ({...el, isCheck: false})));
          fetchTotalUnread();
          fetchNotifList();
        });
      },
    });
  };

  const changeMarkAll = (e:any) => {
    setNotificationCheck(notificationCheck.map(el => ({...el, isCheck: !notificationMarkAll})));
    setNotificationMarkAll(!notificationMarkAll);
  };

  const changeSelectTable = (id: any, e: any) => {
    setNotificationMarkAll(false);
    const found = notificationCheck.findIndex((el:any) => el?.id === id);
    let newNotificationCheck = ([...notificationCheck]);
    newNotificationCheck[found].isCheck = !newNotificationCheck[found].isCheck;
    setNotificationCheck([...newNotificationCheck]);

    // check mark all as read
    let count = 0;
    for(let i = 0; i < newNotificationCheck.length; i++) {
      if (newNotificationCheck[i].isCheck){
        count++;
      }
    }

    if (count === newNotificationCheck.length){
      setNotificationMarkAll(!notificationMarkAll);
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: '',
      key: 'id',
      render: (_, record:any) => {
        const found = notificationCheck.findIndex((v: any) => v?.id === record?.id);
        return (
          <div key={record?.id} className='flex'>
            <Checkbox
              checked={notificationCheck[found].isCheck}
              onChange={(e) => changeSelectTable(record?.id, e)}
              className='my-auto'
            />
            <div className='px-5 cursor-pointer' onClick={() => handleNotifClick(record)}>
              <p className='text-[14px]'>
                <span>{record?.remark} </span>
                <span className={`${!record?.is_read ? 'font-bold' : ''}`}>{record?.status} </span>
                <span>{record?.connector} </span>
                <span className={`${!record?.is_read ? 'font-bold' : ''} text-[#55A853]`}>{record?.request_number} </span>
              </p>
              <p className='text-[12px] text-[#909090]'>{
                moment(new Date(record?.created_at)).tz(user?.timezone?.identifier).utc(true).fromNow().includes('days ago') ?
                  moment(new Date(record?.created_at)).tz(user?.timezone?.identifier).utc(true).format('DD MMMM YYYY HH:mm:ss') :
                  moment(new Date(record?.created_at)).fromNow()
              }</p>
            </div>
          </div>
        );
      },
    }
  ];

  const handleNotifClick = async (data: any) => {
    if (data?.name){
      switch(data?.name){
        case 'Permit_Request':
          navigate(`/permit-request/detail/${data?.permit_request_id}`);
          break;
        case 'Conversation':
          navigate(`/permit-request/detail/${data?.permit_request_id}?conversation=true`);
          break;
        case 'Product_Doc_Conversation':
          navigate(`/permit-monitoring-crm/edit/${data?.product_doc_id}?tab=3`);
          break;
        case 'Product_Doc_Obligation_Action':
          navigate(`/permit-monitoring-crm/edit/${data?.product_doc_id}?tab=2`);
          break;
        case 'Obligation':
          navigate(`/permit-monitoring-crm/edit/${data?.product_doc_id}/obligation/edit/${data?.obligation_id}`);
          break;
      }
    }

    const payload = {
      ids: [data?.id]
    };
    markAsRead(payload).unwrap().then((resp:any) => {
      setNotificationMarkAll(false);
      setNotificationCheck(notificationCheck.map(el => ({...el, isCheck: false})));
      fetchTotalUnread();
    });
  };

  const fetchNotifList = async () => {
    await getListNotif(payload).unwrap().then((resp:any) => {
      setData(resp);
      setNotificationCheck([...resp?.rows?.map((el:any) => ({id: el?.id, isCheck: false}))]);
    });
  };

  useEffect(() => {
    fetchNotifList();
  }, []);

  useEffect(() => {
    fetchNotifList();
  }, [payload]);

  useEffect(() => {
    if (isErrorGetListNotif){
      const errorMsg:any = errorGetListNotif;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorGetListNotif]);

  useEffect(() => {
    if (isErrorMarkAsRead){
      const errorMsg:any = errorMarkAsRead;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorMarkAsRead]);

  return(
    <DataTable
      columns={columns}
      data={data.rows}
      loading={isLoadinGetListNotif || isLoadingMarkAsRead}
      totalData={data.total}
      buttonConfig={[]}
      payload={{
        payload: payload,
        setPayload: setPayload
      }}
      showHeader={false}
      notificationMarkAll={{
        notificationMarkAll: notificationMarkAll,
        notificationCheck: notificationCheck,
        setNotificationMarkAll: setNotificationMarkAll,
        changeMarkAll: changeMarkAll,
        setMarkAsRead: setMarkAsRead,
      }}
    />
  );
};

export default Notification;
