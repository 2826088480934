import { Base } from './Base';

const ApplicationProfile = Base.injectEndpoints({
  endpoints: (build) => ({
    listApplicationProfile: build.mutation({
      query: (params: any) => ({
        url: 'app-settings',
        method: 'GET',
        params
      })
    }),
    idApplicationProfile: build.mutation({
      query: (id: any) => ({
        url: `app-settings/${id}`,
        method: 'GET'
      })
    }),
    editApplicationProfile: build.mutation({
      query: (body: any) => ({
        url: `app-settings/${body?.id}`,
        method: 'PUT',
        body
      })
    }),
  })
});

export const { 
  useListApplicationProfileMutation, 
  useIdApplicationProfileMutation ,
  useEditApplicationProfileMutation
} = ApplicationProfile;