import { Button, Form, Modal, Select, Spin, Empty } from 'antd';
import { STATUS } from 'constant';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { useCompanySearchMutation, useWorkLocationHrisMutation } from 'api/Options';
import { useListRolePaginationMutation } from 'api/Role';

const ModalFilter = (props: any) => {
  const { modalAction, filter, filterAction, options } = props;
  const { t } = useTranslation();

  const [roleList, {
    isLoading: isLoadingRole
  }] = useListRolePaginationMutation();
  const [companyList, {
    isLoading: isLoadingCompany
  }] = useCompanySearchMutation();
  const [worklocationList, {
    isLoading: isLoadingWorklocation
  }] = useWorkLocationHrisMutation();
  
  const fetchRoles = async (value: any) => {
    if (value.length > 2 || !value) {
      const payload = {
        search: value ?? '',
        searchFields: 'name',
        status: 'active'
      }; 
      let { rows } = await roleList(payload).unwrap();
      const newDataRole = rows.map((el: any, idx: any) => {
        if (el) {
          return {
            label: el?.name,
            value: el?.id,
            key: idx
          };
        }
        return null;
      });

      options.setRoles(newDataRole.filter((v: any) => v.label));
    }
  };

  const fetchCompany = async (value: any) => {
    if (value.length > 2 || !value) {
      const payload = {
        filters: `company@=${value}`
      }; 
      let { data } = await companyList(payload).unwrap();
      const newDataCompany = data.map((el: any) => {
        if (el) {
          return {
            label: el?.company,
            value: el?.company
          };
        }
        return null;
      });

      options.setCompanies(newDataCompany.filter((v: any) => v.label));
    }
  };

  const fetchWorklocations = async (value: any) => {
    if (value.length > 2 || !value) {
      const payload = {
        filters: `workLocation@=${value}`
      }; 
      let { data } = await worklocationList(payload).unwrap();
      const newDataWorklocation = data.map((el: any) => {
        if (el) {
          return {
            label: el?.workLocation,
            value: el?.workLocation
          };
        }
        return null;
      });

      options.setWorklocations(newDataWorklocation.filter((v: any) => v.label));
    }
  };

  useEffect(() => {
    fetchRoles('');
    fetchCompany('');
    fetchWorklocations('');
  }, []);

  return (
    <Modal
      title={null}
      open={modalAction.isModalOpen}
      footer={null}
      width={1000}
      onOk={() => modalAction.setIsModalOpen(false)}
      onCancel={() => modalAction.setIsModalOpen(false)}
    >
      <div className="bg-white p-[16px] md:p-[24px] rounded-lg mb-5">
        <p className='font-bold text-[16px] mb-5'>
          {t('manage_group.filter_label')}
        </p>
        <Form layout="vertical">
          <div className='grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 gap-5'>
            <div className='col-span-1'>
              <Form.Item
                label={t('manage_user.role_label')}
              >
                <Select
                  allowClear
                  options={options.roles}
                  style={{ width: '100%' }}
                  placeholder={t('manage_user.role_placeholder')}
                  onChange={(e) => filter.setFilter({...filter.filter, role_id: e})}
                  value={filter.filter.role_id}
                  showSearch
                  filterOption={false}
                  onSearch={fetchRoles}
                  notFoundContent={isLoadingRole ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                />
              </Form.Item>
            </div>
            <div className='col-span-1'>
              <Form.Item
                label={t('manage_user.company_label')}
              >
                <Select
                  allowClear
                  options={options.companies}
                  style={{ width: '100%' }}
                  placeholder={t('manage_user.company_placeholder')}
                  onChange={(e) => filter.setFilter({...filter.filter, company_name: e})}
                  value={filter.filter.company_name}
                  showSearch
                  filterOption={false}
                  onSearch={fetchCompany}
                  notFoundContent={isLoadingCompany ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                />
              </Form.Item>
            </div>
            <div className='col-span-1'>
              <Form.Item
                label={t('manage_user.work_location_label')}
              >
                <Select
                  allowClear
                  options={options.worklocations}
                  style={{ width: '100%' }}
                  placeholder={t('manage_user.work_location_placeholder')}
                  onChange={(e) => filter.setFilter({...filter.filter, work_location_name: e})}
                  value={filter.filter.work_location_name}
                  showSearch
                  filterOption={false}
                  onSearch={fetchWorklocations}
                  notFoundContent={isLoadingWorklocation ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                />
              </Form.Item>
            </div>
            <div className='col-span-1'>
              <Form.Item
                label='Status'
              >
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  options={STATUS}
                  placeholder={t('manage_group.status_placeholder')}
                  onChange={(e) => filter.setFilter({...filter.filter, status: e})}
                  value={filter.filter.status}
                />
              </Form.Item>
            </div>
            <div className='grid grid-cols-2 gap-4 content-end'>
              <Button
                type='text'
                className='bg-white border-[#55A853] text-[#55A853] font-bold'
                onClick={filterAction.resetFilter}
                disabled={filterAction.isLoadingFilter}
              >
                {filterAction.isLoadingFilter ? <Spin></Spin> : t('manage_group.reset_btn') || ''}
              </Button>
              <Button
                type='text'
                className='bg-[#55A853] text-white font-bold'
                onClick={() => {
                  filterAction.applyFilter();
                  modalAction.setIsModalOpen(false);
                }}
                disabled={filterAction.isLoadingFilter}
              >
                {filterAction.isLoadingFilter ? <Spin></Spin> : t('manage_group.apply_btn') || ''}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ModalFilter;
