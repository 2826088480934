import { Button, Modal, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Toast from 'components/Toast';
import { usePostReviseActionMutation } from 'api/PermitMonitoring';
import { formatDate } from 'utils/Date';

const ModalRevise = (props:any) => {
  const { 
    open,
    setOpen,
    data,
    callbackSuccess,
  } = props;

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const initialForm = {
    reason: '',
  };

  const [postReviseAction, {isLoading: isLoadingReviseAction}] = usePostReviseActionMutation();

  const handleCancel = () => {
    setOpen(false);
    form.setFieldsValue(initialForm);
  };

  const handleFinishFailed = () => {
    toast.error(<Toast message={t('general.error_text')} detailedMessage={t('manage_group.fill_form_validation_text')} />);
  };

  const handleSubmit = async () => {
    await postReviseAction({id: data?.id, body: {reason: form.getFieldValue('reason')}}).unwrap().then(() => {
      toast.success(
        <Toast
          message={t('general.success_text')}
          detailedMessage={`${t('permit_monitoring.obligation.success_revise_action')}`}
        />
      );
      handleCancel();
      callbackSuccess();
    })
      .catch(() => {
        toast.error(
          <Toast
            message={t('general.error_text')}
            detailedMessage={t('permit_monitoring.obligation.error_revise_action')}
          />
        );
      });
  };

  const getBadgeClassname = (status: string) => {
    const statusLower = status?.toLowerCase();
    switch (statusLower) {
      case 'in progress':
        return 'border-[#FF7B31] text-[#FF7B31] bg-[#FF7B31]/20';
      case 'completed':
        return 'border-[#659711] text-[#659711] bg-[#659711]/20';
      default:
        return '';
    }
  };

  return(
    <Modal
      title={null}
      open={open}
      footer={null}
      width={500}
      onCancel={handleCancel}
      closable={false}
    >
      <div className='rounded border-[#e4e7ea] border-2 px-4 py-2 flex justify-between items-center'>
        <div>
          <p className='font-bold'>{t('permit_monitoring.permit_name')}</p>
          <p className='text-sm'>{data?.registration_number} · {data?.last_update ? formatDate(data?.last_update, 'DD MMM YYYY') : '-'}</p>
        </div>
        <span className={`py-1 px-2  rounded-lg border border-solid text-xs text-center ${getBadgeClassname(data?.status)}`}>{data?.status || '-'}</span>
      </div>
      <div className='mt-4'>
        <div className='flex items-center gap-1 text-lg'>
          <span className='font-bold'>{t('general.revise')}</span>
          <span className='text-[#659711]'>{data?.name}</span>
        </div>
        <div className='flex items-center gap-1'>
          <span>{t('permit_monitoring.obligation.pic')}</span>
          <span>{data?.pic}</span>
        </div>
        <p>{t('permit_monitoring.obligation.give_a_note')}</p>
        <Form
          layout="vertical"
          autoComplete="off"
          form={form}
          onFinish={handleSubmit}
          onFinishFailed={handleFinishFailed}
          name='form'
          className='mt-2'
        >
          <Form.Item
            name='reason'
            label={t('permit_monitoring.obligation.reason')}
            rules={[{ required: true, message: `${t('permit_monitoring.obligation.reason')} ${t('general.required_field')}` }]}
          >
            <Input.TextArea autoSize={false} placeholder={t('permit_monitoring.obligation.reason_placeholder') || ''} />
          </Form.Item>
          <Form.Item className='mt-5 flex justify-center items-center'>
            <Button
              className='cancel-green bg-[#E5EDE5] text-[#55A853] border-[#55A853] mr-2'
              htmlType="button"
              onClick={handleCancel}
              disabled={isLoadingReviseAction}
            >
              {t('general.cancel_btn')}
            </Button>
            <Button
              className='bg-[#55A853] text-white'
              htmlType="submit"
              disabled={isLoadingReviseAction}
            >
              {t('general.send_request')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default ModalRevise;