import SimpleDataTable from 'components/SimpleDataTable';
import type { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import ModalFilter from './ModalFilter';
import { FaCircle } from 'react-icons/fa';
import { useListPermitRequestReportByWorkLocationMutation } from 'api/PermitRequestReport';
import { PayloadPermitRequestByWorkLocation } from 'constant/Types/PermitRequestReport';
import { useParams } from 'react-router';
import moment from 'moment';
import Toast from 'components/Toast';
import { transformError } from 'utils/ErrorTransformer';
import { toast } from 'react-toastify';

const PermitStatusTab = () => {
  const { t } = useTranslation();
  const { id, year } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState<any>({
    issuer_institution: [],
    indicator: [],
  });
  const [payload, setPayload] = useState<PayloadPermitRequestByWorkLocation>(
    {
      id: id,
      year: year,
      indicator: [],
      issuer_institution_id: []
    }
  );

  const [getList, {
    error: errorGetList,
    isError: isErrorGetList,
    isLoading: isLoadingGetList
  }] = useListPermitRequestReportByWorkLocationMutation();

  const columns: ColumnsType = [
    {
      title: t('dashboard.companies.permit_status.indicator_label'),
      dataIndex: 'indicator',
      key: 'indicator',
      sorter: false,
      width: 100,
      render: (data, record) => {
        return (<div>{data?.hexacolor ? <FaCircle className='m-auto text-[23px]' style={{color: data?.hexacolor}}/> : '-'}</div>);
      }
    },
    {
      title: t('dashboard.companies.permit_status.item_label'),
      dataIndex: 'item',
      key: 'item',
      sorter: false,
      width: 500,
      render: (data, record) => {
        return (<div>{data || (data === 0 ? 0 : '-')}</div>);
      }
    },
    {
      title: t('dashboard.companies.permit_status.last_update'),
      dataIndex: 'last_update',
      key: 'last_update',
      sorter: false,
      render: (data, record:any) => {
        return (
          <div>
            <div>{data?.date ? moment(new Date(data?.date)).format('DD-MMM-YYYY') : '-'}</div>
            <div>{data?.update || '-'}</div>
          </div>
        );
      }
    },
    {
      title: t('dashboard.companies.permit_status.exp_granted'),
      dataIndex: 'expected_date',
      key: 'expected_date',
      sorter: false,
      width: 150,
      render: (data, record) => {
        return (
          <div>{data ? moment(new Date(data)).format('DD-MMM-YYYY') : '-'}</div>);
      }
    },
  ];

  const applyFilter = () => {
    setIsLoadingFilter(true);
    setPayload({
      ...payload,
      indicator: filter.indicator,
      issuer_institution_id: filter.issuer_institution
    });
  };

  const resetFilter = () => {
    setIsLoadingFilter(true);
    setFilter({
      issuer_institution: [],
      indicator: []
    });
    setPayload({
      ...payload,
      indicator: [],
      issuer_institution_id: []
    });
  };

  const fetchList = async () => {
    const data = await getList(payload).unwrap();
    setData(data);
    setIsLoadingFilter(false);
  };

  useEffect(() => {
    fetchList();
  }, []);

  useEffect(() => {
    fetchList();
  }, [payload]);

  useEffect(() => {
    if (isErrorGetList) {
      const errorMsg: any = errorGetList;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorGetList]);

  return(
    <div>
      <SimpleDataTable
        columns={columns}
        dataSource={data}
        loading={isLoadingGetList}
        scrollWidth={1000}
        modalOpen={{
          isModalOpen: isModalOpen,
          setIsModalOpen: setIsModalOpen
        }}
      />
      <ModalFilter
        modalAction={{
          isModalOpen: isModalOpen,
          setIsModalOpen: setIsModalOpen
        }}
        filter={{
          filter: filter,
          setFilter: setFilter
        }}
        filterAction={{
          isLoadingFilter: isLoadingFilter,
          resetFilter: resetFilter,
          applyFilter: applyFilter
        }}
      />
    </div>
  );
};

export default PermitStatusTab;