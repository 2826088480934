import type { CredentialType } from 'constant/Types/CredentialType';
import { AuthLoginResponse } from 'constant/Types/AuthType';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery
} from '@reduxjs/toolkit/query/react';
import { RootState } from 'store';
import * as App from '../index';
import { loginRequest } from 'authConfig';

import { logout, saveAuthToDb } from 'store/Auth/Thunk';
import {
  DB_CREDENTIAL_TOKEN,
  EMPTY
} from 'constant';

const interceptor = () => {
  const baseQuery = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}`,
    timeout: 120000,
    prepareHeaders: async (headers, { getState, endpoint }) => {
      if (endpoint !== 'login') {
        const { token } = (getState() as RootState).auth;
        let accessToken = token;
        const instance = App.msalInstance;
        const accounts = instance.getAllAccounts();

        if (accounts.length) {
          const accessTokenResponse = await instance
            .acquireTokenSilent({
              ...loginRequest,
              forceRefresh: true,
              account: accounts[0]
            });

          if (endpoint === 'loginAzure') {
            accessToken = accessTokenResponse?.accessToken;
          }
        }

        headers.set('Authorization', `Bearer ${accessToken}`);


        return headers;
      }

      return headers;
    },
  });

  const reAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =
      async (args: any, api, extraOptions) => {
        let result = await baseQuery(args, api, extraOptions);
        
        if (result.error && result.error.status === 401 && !args.url.endsWith('/auth/login')) {
          const { token } = (api.getState() as RootState).auth;
          const refreshTokenResult = await baseQuery({
            url: `/auth/resolve-token?token=${token}`,
            method: 'GET'
          }, api, extraOptions);

          if (refreshTokenResult.error
              && parseInt(refreshTokenResult.error.status.toString()) >= 400) {
            await api.dispatch(logout()).unwrap();
            window.location.replace('/login');
          }

          if (refreshTokenResult.data) {
            const { token } = refreshTokenResult.data as AuthLoginResponse;
            const secretToken: CredentialType = {
              _id: DB_CREDENTIAL_TOKEN,
              value: token || EMPTY.STRING
            };

            await api.dispatch(saveAuthToDb(secretToken)).unwrap();

            result = await baseQuery(args, api, extraOptions);
          }
        }

        return result;
      };

  return reAuth;
};

export const Base = createApi({
  reducerPath: 'api',
  baseQuery: interceptor(),
  endpoints: () => ({})
});

export const transformError = (response: any) => ({
  code: response.status,
  message: response.data
});