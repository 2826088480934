import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  totalUnreadGeneral: 0
};

export const notification = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setTotalUnreadGeneral: (state, action) => {
      state.totalUnreadGeneral = action.payload;
    },
  }
});

export const { setTotalUnreadGeneral } = notification.actions;

export default notification.reducer;
