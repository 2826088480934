import { Outlet, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { MenuProps, Popover } from 'antd';
import { Dropdown, Space, Breadcrumb, Avatar, Spin, Badge, Button, ConfigProvider  } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import { BellOutlined } from '@ant-design/icons';
import { useAppSelector } from 'store/Hooks';
import { getAuthFromDb } from 'store/Auth/Thunk';
import { useTimezoneListMutation } from 'api/Options';
import {
  DB_CREDENTIAL_REFRESH_TOKEN,
  DB_CREDENTIAL_TOKEN,
  DB_CREDENTIAL_USER,
  LANGOPT
} from 'constant';
import { useAppDispatch } from 'store/Hooks';
import { logout } from 'store/Auth/Thunk';
import NavigationBottom from 'components/NavBottom';
import Sidebar from 'components/Sidebar';
import { isEmpty } from 'lodash';
import { hasPermission } from 'utils/Permission';
import { useTotalUnreadMutation, useMarkReadMutation } from 'api/Notification';
import Toast from 'components/Toast';
import ToastNotification from 'components/ToastNotification';
import { transformError } from 'utils/ErrorTransformer';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import { setTotalUnreadGeneral } from 'store/Notification';
import { useSelector } from 'react-redux';
import useFirebaseMessaging from '@useweb/firebase/useFirebaseMessaging';
import { useProfileTimezoneMutation, useMeMutation } from 'api/User';
import img_logo_plis from 'icon/icon_logo_plis.svg';
import { useParams } from 'react-router';
import icon_id from 'icon/icon_indonesia.svg';
import icon_en from 'icon/icon_english.svg';

const Base = () => {
  const totalUnreadGeneral = useSelector((state: any) => state.notif.totalUnreadGeneral);
  const { user } = useAppSelector((state) => state.auth);
  const { request_number } = useAppSelector((state) => state.permit_request);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();
  const { t, i18n } = useTranslation();
  const breadcrumbPermitRequestCondition = ['Detail Permit Request', 'Edit Permit Request'];
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const items: MenuProps['items'] = LANGOPT;
  const [isLoading, setIsLoading] = useState(false);
  const [isPrepare, setIsPrepare] = useState(true);
  const [content, setContent] = useState((<div></div>));
  const [openNotifWeb, setOpenNotifWeb] = useState(false);
  const [openNotifMobile, setOpenNotifMobile] = useState(false);
  const [isMouseInside, setIsMouseInside] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [currentTimezone, setCurrentTimezone] = useState<any>({});
  const [listNotification, setListNotification] = useState<any[]>([]);
  const { pathname } = useLocation();
  const [itemsTimezone, setItemsTimezone] = useState<any['items']>([{
    key:'',
    label: ''
  }]);

  const [getTotalUnread, {
    isLoading: isLoadingGetTotalUnread
  }] = useTotalUnreadMutation();

  const [markAsRead, {
    error: errorMarkAsRead,
    isError: isErrorMarkAsRead,
  }] = useMarkReadMutation();

  const [timezoneList, {
    isLoading: isLoadingTimezoneList
  }] = useTimezoneListMutation();

  const [changeProfileTimezone, {
    error: errorChangeProfileTimezone,
    isError: isErrorChangeProfileTimezone,
    isLoading: isLoadingChangeProfileTimezone
  }] = useProfileTimezoneMutation();
  
  const [getMe, {
    isError: isErrorMe,
    error: errorMe
  }] = useMeMutation();

  const firebaseMessaging = useFirebaseMessaging({
    onMessage: (message) => {
      fetchTotalUnread();
      const data = JSON.parse(message?.data?.body);
      toast.info(
        <ToastNotification
          message={message?.notification?.title} 
          detailedMessage={message?.notification?.body}
          handleOnClickNotif={() => handleNotifClick(data)}
        />, 
        {
          autoClose: 5000,
          theme: 'light',
        });
    },
  });

  const contentNotification = () => {
    return (
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#55A853',
          },
        }}
      >
        <Spin tip="Loading" size="large" spinning={isLoadingGetTotalUnread}>
          <div className='w-[280px] lg:w-[471px] max-h-[320px] xl:max-h-[491px] p-5'>
            <div className='border-b-2 border-b-zinc-200 pb-2 mb-2 flex justify-between items-center'>
              <div className='text-[18px] font-bold'>{t('notification.title')}</div>
              <div className='text-[14px] text-[#909090] font-bold'>{totalUnreadGeneral}</div>
            </div>
            <div className='max-h-[200px] lg:max-h-[210px] xl:max-h-[351px] overflow-auto'>
              {
                listNotification?.map(el => (
                  <div className='py-2 border-b-2 border-b-zinc-200 cursor-pointer' onClick={() => handleNotifClick(el)}>
                    <p className='text-[14px]'>
                      <span>{el?.remark} </span>
                      <span className={`${!el?.is_read ? 'font-bold' : ''}`}>{el?.status} </span>
                      <span>{el?.connector} </span>
                      <span className={`${!el?.is_read ? 'font-bold' : ''} text-[#55A853]`}>{el?.request_number} </span>
                    </p>
                    <p className='text-[12px] text-[#909090]'>{
                      moment(new Date(el?.created_at)).tz(user?.timezone?.identifier).utc(true).fromNow().includes('days ago') ?
                        moment(new Date(el?.created_at)).tz(user?.timezone?.identifier).utc(true).format('DD MMMM YYYY HH:mm:ss') :
                        moment(new Date(el?.created_at)).fromNow()
                    }</p>
                  </div>)
                )
              }
            </div>
            <div className='grid grid-cols-1 content-end justify-self-end mt-4 xl:mt-5 lg:pl-[150px]'>
              <Button
                type='text'
                className='bg-white border-[#55A853] text-[#55A853] font-bold'
                onClick={() => {
                  navigate('/notification');
                  setOpenNotifWeb(false);
                  setOpenNotifMobile(false);
                }}
              >
                {t('notification.btn_show_all')}
              </Button>
            </div>
          </div>
        </Spin>
      </ConfigProvider>
    );
  };

  const fetchTimezoneList = async (value: any) => {
    if (value.length > 2 || !value) {
      const payload = {
        pageSize: 50,
        search: value ?? '',
        searchFields: 'timezone',
        sort: 'timezone',
      }; 
      let { rows } = await timezoneList(payload).unwrap();
      const newTimezones = rows.map((el: any) => {
        if (el) {
          return {
            label: el?.timezone,
            key: el?.id
          };
        }
        return null;
      });

      setItemsTimezone(newTimezones.filter((v: any) => v));
    }
  };

  const handleNotifClick = async (data: any) => {
    if (data?.name){
      switch(data?.name){
        case 'Permit_Request':
          navigate(`/permit-request/detail/${data?.permit_request_id}`);
          if (pathname.includes('permit-request/detail') || pathname.includes('permit-request/edit')){
            window.location.reload();
          }
          break;
        case 'Conversation':
          navigate(`/permit-request/detail/${data?.permit_request_id}?conversation=true`);
          break;
        case 'Product_Doc_Conversation':
          navigate(`/permit-monitoring-crm/edit/${data?.product_doc_id}?tab=3`);
          break;
        case 'Product_Doc_Obligation_Action':
          navigate(`/permit-monitoring-crm/edit/${data?.product_doc_id}?tab=2`);
          break;
        case 'Obligation':
          navigate(`/permit-monitoring-crm/edit/${data?.product_doc_id}/obligation/edit/${data?.obligation_id}`);
          break;
      }
    }
    setOpenNotifMobile(false);
    setOpenNotifWeb(false);

    const payload = {
      ids: [data?.id]
    };
    markAsRead(payload).unwrap().then(() => {
      fetchTotalUnread();
    });
  };

  const onLogout = async () => {
    try {
      setIsLoading(true);
      await dispatch(logout()).unwrap();

      navigate('/');
      localStorage.clear();
    } catch (err: any) {
      setIsLoading(false);
    }
  };

  const dispatchTokenState = (callback: Function) => {
    dispatch(getAuthFromDb(DB_CREDENTIAL_TOKEN))
      .unwrap()
      .then((data) => {
        dispatch(getAuthFromDb(DB_CREDENTIAL_REFRESH_TOKEN)).unwrap();
        callback();
        setTimeout(() => {
          setIsPrepare(false);
        }, 10);
      })
      .catch(() => {
        setIsPrepare(false);
      });
  };

  const dispatchUserState = () => {
    dispatch(getAuthFromDb(DB_CREDENTIAL_USER))
      .unwrap()
      .then((data) => {
        if (isEmpty(data)){
          navigate('/');
        }
      })
      .catch(() => {
        setIsPrepare(false);
      });
  };

  const onClick: MenuProps['onClick'] = ({ key }) => {
    i18n.changeLanguage(key);
    localStorage.setItem('lang', key);
  };

  const onClickTimezone: MenuProps['onClick'] = async ({ key }) => {
    if (key !== currentTimezone?.key){
      const payload = {
        timezone: {
          timezone_id: key
        }
      };

      await changeProfileTimezone(payload).unwrap().then(async () => {
        await getMe({}).unwrap();
      });
    }
  };

  const fetchTotalUnread = async () => {
    await getTotalUnread({}).unwrap().then((resp: any) => {
      setListNotification(resp?.notifications);
      dispatch(setTotalUnreadGeneral(resp?.total));
    });
  };

  useEffect(() => {
    dispatchTokenState(dispatchUserState);
    firebaseMessaging.init();

    const NO_REDIRECTS = [
      'login',
      '403',
      'feedback'
    ];
    const paths = pathname.split('/');
    if(!NO_REDIRECTS.includes(paths[1]) && !hasPermission(paths[1]).can_view && (paths[1] !== 'notification')){
      navigate('/403');   
    }

    const lang = localStorage.getItem('lang');
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, []);

  useEffect(() => {
    moment.locale(getLanguage());
  }, [getLanguage()]);

  useEffect(() => {
    if (user?.id) {
      setContent((
        <div className='w-48'>
          <div className='text-gray-400'>
            {
              user.roles?.map((v: any) => v.name).join(', ')
            }
          </div>
          <hr className='my-2' />
          <div>
            <Link
              className='block'
              to=''
              onClick={onLogout}
            >
              {isLoading ? <Spin /> : t('header.logout_text')}
            </Link>
          </div>
        </div>
      ));

      fetchTotalUnread();
      fetchTimezoneList('');
    }
  }, [user]);

  useEffect(() => {
    if (user && itemsTimezone){
      const currentTimezoneUser = itemsTimezone.filter((el:any) => el.key === user?.timezone_id)[0] || {};
      const regex = /\((GMT\+\d+)\)/;
      const match = currentTimezoneUser?.label?.match(regex);
  
      setCurrentTimezone({
        ...currentTimezoneUser,
        name: match?.[1] || '-'
      });
    }
  }, [user, itemsTimezone]);

  useEffect(() => {
    if (isErrorChangeProfileTimezone){
      const errorMsg:any = errorChangeProfileTimezone;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    };
  }, [isErrorChangeProfileTimezone]);

  useEffect(() => {
    if (isErrorMe){
      const errorMsg:any = errorMe;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    };
  }, [isErrorMe]);

  useEffect(() => {
    if (isErrorMarkAsRead){
      const errorMsg:any = errorMarkAsRead;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorMarkAsRead]);

  const breadcrumbNameMap: Record<string, string> = {
    '/dashboard': 'Dashboard',
    '/my-activity': 'My Activity',
    '/tracker': 'Permit Tracker',
    '/user-log': 'User Log',
    '/manage-group': 'Manage Group List',
    '/manage-user': 'Manage User List',
    '/403': 'Forbidden',
    '/manage-user/edit': 'Edit User',
    '/manage-user/detail': 'Detail User',
    '/manage-group/create': 'Add New Group',
    '/manage-group/edit': 'Edit Group',
    '/manage-group/detail': 'Detail Group',
    '/create-permit-request': 'Add Permit Request',
    '/permit-request': 'Permit Request',
    '/permit-request/detail': 'Detail Permit Request',
    '/permit-request/edit': 'Edit Permit Request',
    '/notification': 'Notification',
    '/setting-permit-name': 'Setting Permit Name',
    '/setting-permit-name/create': 'Add Permit Name',
    '/setting-permit-name/detail': 'Detail Permit Name',
    '/setting-permit-name/edit': 'Edit Permit Name',
    '/setting-permit-type': 'Setting Permit Type',
    '/setting-feedback': 'Setting Feedback',
    '/permit-monitoring': 'Permit Monitoring ER',
    '/application-profile': 'Application Profile',
    '/report-feedback': 'Report Feedback',
    '/report-er-performance': 'Report ER Performance',
    '/clusters': 'Cluster List',
    '/permit-monitoring-crm': 'Permit Monitoring CRM',
    '/permit-monitoring-crm/edit': 'Edit Permit Monitoring CRM',
    '/permit-monitoring-crm/detail': 'Detail Permit Monitoring CRM',
    '/permit-flow-process': 'Permit Flow Process',
    '/permit-flow-process/deep': 'Permit Flow Process',
    '/dashboard/company-detail': 'Company Detail',
    '/manage-cronjob': 'Manage Cronjob',
    '/compliance-monitoring': 'Compliance Monitoring',
    '/compliance-monitoring/edit': 'Edit Compliance Monitoring',
    '/compliance-monitoring/detail': 'Detail Compliance Monitoring',
    '/initiate-permit-request': 'Initiate Permit Request',
    '/feedback': 'Feedback',
    '/email-notification': 'Email Notification'
  };
  const pathSnippets = pathname.split('/').filter((i, index) => i);

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const i = pathSnippets.length === 3 ? 0 : 1;
    const url = `/${pathSnippets.slice(0, index + i).join('/')}`;
    return !breadcrumbNameMap[url] ? {key:'',title:<div/>} : {
      key: url,
      title: (pathname.includes('/permit-flow-process/deep/') && index === 1) ? <p>{params?.name}</p> :
        index < 2 ? <Link to={url}>{ breadcrumbNameMap[url]}</Link> : 
          <p>{
            breadcrumbPermitRequestCondition.includes(breadcrumbNameMap[url]) ? 
              (request_number ? <span className='font-bold'>{request_number}</span> : breadcrumbNameMap[url])
              : breadcrumbNameMap[url]
          }</p>,
    };
  });

  const breadcrumbItems = [
    {
      title: <Link to="/">Home</Link>,
      key: 'home',
    },
  ].concat(extraBreadcrumbItems);

  return !isPrepare ? (
    <div>
      <div className="min-h-screen flex flex-col overflow-hidden">
        <div className="upper-main h-screen bg-gray-300">
          <Sidebar
            isMouseInside={isMouseInside}
            setIsMouseInside={setIsMouseInside}
            isActive={isActive}
            setIsActive={setIsActive}
          />
          <div className={`bg-[#55A853] container-main ${!isMouseInside ? 'active-side' : ''}`}>
            <header className='px-3 py-2 md:hidden flex flex-row items-center justify-between'>
              <img
                className='cursor-pointer w-[45px]'
                src={img_logo_plis}
                alt=""
                onClick={() => setIsActive(!isActive)}
              />
              <div className='flex flex-row items-center'>
                <Popover 
                  placement="bottom" 
                  content={contentNotification} 
                  trigger="click" 
                  open={openNotifMobile}
                  onOpenChange={(newOpen: any) => {
                    fetchTotalUnread();
                    setOpenNotifMobile(newOpen);
                  }}
                >
                  <div className='mr-6'>
                    <Badge size='small' count={totalUnreadGeneral}>
                      <div className='cursor-pointer bg-white p-2 rounded-xl'>
                        <BellOutlined className='text-[16px] text-[#55A853]' />
                      </div>
                    </Badge>
                  </div>
                </Popover>
                <Popover placement="bottomLeft" title={''} content={content} trigger="click">
                  <div className='cursor-pointer' onClick={(e) => e.preventDefault()}>
                    <Space>
                      <Space direction="vertical" size={16}>
                        <Space wrap size={16}>
                          {
                            user?.picture 
                              ? <Avatar size={30} src={user?.picture} />
                              : <Avatar size={30}>{user?.display_name?.charAt(0)}</Avatar>
                          }
                        </Space>
                      </Space>
                    </Space>
                  </div>
                </Popover>
                <Dropdown className='ml-5 cursor-pointer' menu={{ items, onClick }} trigger={['click']}>
                  <div className='flex item-center'>
                    <Space>
                      {
                        getLanguage() === 'id'
                          ? <img className='rounded-full' src={icon_id} alt="id" />
                          : <img className='rounded-full' src={icon_en} alt="en" />
                      }
                    </Space>
                  </div>
                </Dropdown>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: '#55A853',
                    },
                  }}
                >
                  <Dropdown 
                    className='ml-5 cursor-pointer' 
                    menu={{ 
                      items: itemsTimezone, 
                      onClick:onClickTimezone,
                      selectedKeys: [`${user?.timezone_id}`]
                    }} 
                    trigger={['click']}
                  >
                    <div className='flex bg-white item-center rounded-xl bg-[#659711]/20 py-1 px-3 border-2 border-[#659711] text-[#659711]'>
                      <Space>
                        {!user || isLoadingTimezoneList || isLoadingChangeProfileTimezone? <Spin></Spin> : currentTimezone?.name}
                      </Space>
                    </div>
                  </Dropdown>
                </ConfigProvider>
              </div>
            </header>
            <header className='md:rounded-tl-3xl bg-[#F4F5FD] px-5 pt-5'>
              <div className='flex flex-row justify-between'>
                <div>
                  <div className='font-bold text-lg'>
                    {breadcrumbNameMap[breadcrumbItems.length === 4 ? breadcrumbItems[3].key : (breadcrumbItems.length > 4 ? breadcrumbItems[2].key : pathname)]}
                  </div>
                  <Breadcrumb params={'id'} items={breadcrumbItems.filter(v => v.key !== '')} />
                </div>
                <div className='max-md:hidden flex flex-row items-center'>
                  <Popover 
                    placement="bottomRight" 
                    content={contentNotification} 
                    trigger="click"
                    open={openNotifWeb}
                    onOpenChange={(newOpen: any) => {
                      fetchTotalUnread();
                      setOpenNotifWeb(newOpen);
                    }}
                  >
                    <div className='mr-8'>
                      <Badge count={totalUnreadGeneral}>
                        <div className='cursor-pointer bg-white p-2 rounded-xl'>
                          <BellOutlined className='text-[25px] text-[#55A853]' />
                        </div>
                      </Badge>
                    </div>
                  </Popover>
                  <Popover placement="bottomLeft" title={''} content={content} trigger="click">
                    <div className='cursor-pointer' onClick={(e) => e.preventDefault()}>
                      <Space>
                        <Space direction="vertical" size={16}>
                          <Space wrap size={16}>
                            {
                              user?.picture 
                                ? <Avatar size={45} src={user?.picture} />
                                : <Avatar size={45}>{user?.display_name?.charAt(0)}</Avatar>
                            }
                          </Space>
                        </Space>
                        <div className='ml-3 min-w-[130px]'>
                          <div className='font-bold'>{user?.display_name}</div>
                          <div className='text-sm text-gray-400 max-w-[130px]'>
                            <p className='truncate'>
                              {user?.roles?.length ? user.roles.length > 1 ? 'Multirole' : user.roles[0].name : '-'}
                            </p>
                          </div>
                        </div>
                      </Space>
                    </div>
                  </Popover>
                  <Dropdown className='ml-5 cursor-pointer mt-2' menu={{ items, onClick }} trigger={['click']}>
                    <span>
                      <Space>
                        {
                          getLanguage() === 'id'
                            ? <img className='rounded-full' src={icon_id} alt="id" />
                            : <img className='rounded-full' src={icon_en} alt="en" />
                        }
                      </Space>
                    </span>
                  </Dropdown>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: '#55A853',
                      },
                    }}
                  >
                    <Dropdown 
                      className='ml-5 cursor-pointer' 
                      menu={{ 
                        items: itemsTimezone, 
                        onClick:onClickTimezone,
                        selectedKeys: [`${user?.timezone_id}`]
                      }} 
                      trigger={['click']}
                    >
                      <div className='flex item-center rounded-xl bg-[#659711]/20 py-1 px-3 border-2 border-[#659711] text-[#659711]'>
                        <Space>
                          {!user || isLoadingTimezoneList || isLoadingChangeProfileTimezone? <Spin></Spin> : currentTimezone?.name}
                        </Space>
                      </div>
                    </Dropdown>
                  </ConfigProvider>
                </div>
              </div>
              <hr className='hr-dashed mt-4' />
            </header>
            <main
              className='p-5 bg-[#F4F5FD]
                md:h-[89vh] max-md:h-[90vh]
                md:rounded-bl-3xl max-md:pb-52
                overflow-y-scroll w-full'
            >
              <Outlet />
            </main>
          </div>
        </div>
        <NavigationBottom
          path={pathname}
          className="fixed bottom-0 md:hidden"
        />
      </div>
    </div>
  ) : (<div></div>);
};

export default Base;