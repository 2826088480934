import type { ColumnsType } from 'antd/es/table';
import { Button, Spin } from 'antd';
import { PermitInititateRequestList, ListPayloadType } from 'constant/Types/PermitInitiate';
import { useEffect, useState } from 'react';
import { FolderFilled, FileAddOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import DataTable from 'components/DataTable';
import ModalFilter from './ModalFilter';
import { useAppSelector } from 'store/Hooks';
import { useGetByIdPermitInitiateMutation, useGetListPermitInitiateMutation } from 'api/PermitInitiate';
import Toast from 'components/Toast';
import { transformError } from 'utils/ErrorTransformer';
import { toast } from 'react-toastify';
import { hasPermission } from 'utils/Permission';
import { useLocation, useNavigate } from 'react-router';
import ModalInitiatePermitRequest from './ModalInitiatePermitRequest';
import moment from 'moment';

const InitiatePermitRequest = () => {
  const { pathname } = useLocation();
  const { user } = useAppSelector((state) => state.auth);
  const { t , i18n } = useTranslation();
  const navigate = useNavigate();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const [canUpdate, setCanUpdate] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenModalInitiate, setIsOpenModalInitiate] = useState(false);
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [mode, setMode] = useState('');
  const [detail, setDetail] = useState({});
  const [data, setData] = useState({
    total: 0,
    permitInitiates: []
  });
  const [payload, setPayload] = useState<ListPayloadType>({
    page: 1,
    pageSize: 50,
    status: []
  });
  const [filter, setFilter] = useState({
    status: []
  });

  const [getListPermitInitiate, {
    error: errorGetListPermitInitiate,
    isError: isErrorGetListPermitInitiate,
    isLoading
  }] = useGetListPermitInitiateMutation();

  const [getByIdPermitInitiate, {
    isLoading: isLoadingGetById
  }] = useGetByIdPermitInitiateMutation();

  const columns: ColumnsType<PermitInititateRequestList> = [
    {
      title: t('manage_group.column.action'),
      key: 'action',
      width: 180,
      render: (data, record) => (
        <div>
          {
            canUpdate && <Button
              type='text'
              className='shadow-md shadow-gray-400 rounded-sm mr-3 p-0'
              onClick={() => {handleModalInitiate(record.id, record.status);}}
              disabled={isLoadingGetById}
            >
              <FolderFilled style={{ fontSize: '20px' }} className='text-[#55A853] p-1' />
            </Button>
          }
          {
            ((user?.id === record.permit_holder.id) && !record?.status) &&
            <Button
              type='text'
              className='shadow-md shadow-gray-400 rounded-sm mr-3 p-0'
              disabled={isLoadingGetById}
              onClick={() => {navigate(`/create-permit-request?via=initiate&id=${record?.id}`);}}
            >
              {
                isLoadingGetById ? <Spin/> : <FileAddOutlined style={{ fontSize: '20px' }} className='text-[#55A853] p-1' />
              }
            </Button>
          }
        </div>
      ),
    },
    {
      title: t('permit_request.request_information.permit_name'),
      dataIndex: 'permit_name',
      key: 'permit_name',
      sorter: false,
      render: (data, record) => {
        return (<div>{data?.name[getLanguage()]}</div>);
      }
    },
    {
      title: t('initiate_permit_request.permit_holder_label'),
      dataIndex: 'permit_holder',
      key: 'permit_holder',
      sorter: false,
      render: (data, record) => {
        return (<div>{data?.display_name || '-'}</div>);
      }
    },
    {
      title: t('permit_request.request_information.reference_permit'),
      dataIndex: 'permit_reference',
      key: 'permit_reference',
      sorter: false,
      render: (data, record) => {
        return (<div>{data || '-'}</div>);
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: false,
      render: (data, record) => {
        return (
          <div
            className={
              `
                ml-3 py-1 px-2  rounded-lg border border-solid text-xs text-center
                ${!data ? 'border-gray-700 text-gray-800 bg-gray-700/20' : ''}
                ${data === 'Draft' ? 'border-[#3D81DB] text-[#3D81DB] bg-[#3D81DB]/20' : ''}
                ${data?.toLowerCase().includes('waiting') ? 'border-[#F7B648] text-[#F7B648] bg-[#F7B648]/20' : ''}
                ${data?.toLowerCase().includes('approved') || data?.toLowerCase().includes('close') ? 'border-[#659711] text-[#659711] bg-[#659711]/20' : ''}
                ${data?.toLowerCase() === 'assigned' ? 'border-[#6552B7] text-[#6552B7] bg-[#6552B7]/20' : ''}
                ${data?.toLowerCase().includes('open') || data?.toLowerCase().includes('processed') ? 'border-[#FF7B31] text-[#FF7B31] bg-[#FF7B31]/20' : ''}
                ${data?.toLowerCase().includes('rejected') ? 'border-[#930B16] text-[#930B16] bg-[#930B16]/20' : ''}
                ${data?.toLowerCase().includes('returned') ? 'border-[#ED3F3F] text-[#ED3F3F] bg-[#ED3F3F]/20' : ''}
              `
            }
          >
            {record?.status || 'Not Process'}
          </div>
        );
      }
    },
    {
      title: t('initiate_permit_request.date_label'),
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: false,
      render: (data, record) => {
        return (<div>{moment(data).tz(user?.timezone?.identifier).utc(true).format('DD-MMM-YYYY') || '-'}</div>);
      }
    },
    {
      title: t('initiate_permit_request.initiator_label'),
      dataIndex: 'created_by',
      key: 'created_by',
      sorter: false,
      render: (data, record) => {
        return (<div>{data || '-'}</div>);
      }
    },
  ];

  const handleModalInitiate = async (id: any, status: string) => {
    if (status) {
      setMode('view');
    } else {
      setMode('edit');
    }
    await getByIdPermitInitiate(id).unwrap().then((resp) => {
      setDetail(resp);
      setIsOpenModalInitiate(true);
    }).catch((errorMsg) => {
      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${errorMsg?.data?.message}`} />);
      return;
    });
  };

  const applyFilter = () => {
    setIsLoadingFilter(true);
    setPayload({
      ...payload,
      page: 1,
      status: filter.status,
    });
  };

  const resetFilter = () => {
    setIsLoadingFilter(true);
    setFilter({
      status: []
    });
    setPayload({
      ...payload,
      status: []
    });
  };

  const fetchList = async () => {
    let convertPayload: any = payload;
    let newPayload: any = {};
    for (const key in convertPayload) {
      if (Array.isArray(convertPayload[key])) {
        if (convertPayload[key].length) {
          newPayload[key] = convertPayload[key];
        }
      } else if (convertPayload[key]) {
        newPayload[key] = convertPayload[key];
      }
    }

    let listPermitInitiate = await getListPermitInitiate(newPayload).unwrap();

    listPermitInitiate = {
      ...listPermitInitiate,
      permitInitiates: listPermitInitiate?.rows.map((el: any, idx: any) => ({ ...el, key: idx }))
    };
    setData(listPermitInitiate);
    setIsLoadingFilter(false);
  };

  useEffect(() => {
    const paths = pathname.split('/');
    setCanUpdate(hasPermission(paths[1]).can_update);
    fetchList();
  }, []);

  useEffect(() => {
    setIsLoadingFilter(true);
    fetchList();
  }, [payload]);

  useEffect(() => {
    if (isErrorGetListPermitInitiate) {
      const errorMsg: any = errorGetListPermitInitiate;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorGetListPermitInitiate]);

  return(
    <div>
      <DataTable
        withoutSearch={true}
        className=''
        columns={columns}
        data={data.permitInitiates}
        buttonConfig={[]}
        loading={isLoading}
        totalData={data.total}
        payload={{
          payload: payload,
          setPayload: setPayload
        }}
        modalOpen={{
          isModalOpen: isModalOpen,
          setIsModalOpen: setIsModalOpen
        }}
        scrollWidth={1000}
      />
      <ModalFilter
        modalAction={{
          isModalOpen: isModalOpen,
          setIsModalOpen: setIsModalOpen
        }}
        filter={{
          filter: filter,
          setFilter: setFilter
        }}
        filterAction={{
          isLoadingFilter: isLoadingFilter,
          resetFilter: resetFilter,
          applyFilter: applyFilter
        }}
      />
      <ModalInitiatePermitRequest
        modalAction={{
          isModalOpen: isOpenModalInitiate,
          setIsModalOpen: setIsOpenModalInitiate
        }}
        detail={detail}
        mode={mode}
        fetchList={fetchList}
      />
    </div>
  );
};

export default InitiatePermitRequest;