import { useTranslation } from 'react-i18next';
import { Button, Spin } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import DataTable from 'components/DataTable';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FolderFilled } from '@ant-design/icons';
import { ButtonDataTableTypeItems} from 'constant/Types/DataTableConfigType';
import { useLocation, useNavigate } from 'react-router';
import { ListPayloadType, PermitRequestListType } from 'constant/Types/PermitRequest';
import Toast from 'components/Toast';
import { transformError } from 'utils/ErrorTransformer';
import { hasPermission } from 'utils/Permission';
import { useGetPermitNamesMutation } from 'api/PermitName';
import CardList from 'components/CardList';

const PermitName = () => {
  const [permitNameList, {
    error: errorPermitNameList,
    isError: isErrorPermitNameList,
    isLoading
  }] = useGetPermitNamesMutation();

  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const navigate = useNavigate();
  const [data, setData] = useState({
    total: 0,
    permitNames: []
  });
  const [dataMobile, setDataMobile] = useState({
    total: 0,
    permitNames: []
  });
  const [payload, setPayload] = useState<ListPayloadType>({
    lang: getLanguage(),
    page: 1,
    pageSize: 50,
    search: '',
    sort: 'name'
  });
  const [payloadMobile, setPayloadMobile] = useState<ListPayloadType>({
    lang: getLanguage(),
    page: 1,
    pageSize: 50,
    search: '',
    sort: 'name'
  });
  const [canView, setCanView] = useState(false);
  const [canCreate, setCanCreate] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [onSearchMobile, setOnSearchMobile] = useState(false);

  const fetchList = async () => {
    let convertPayload:any = payload;
    let newPayload:any = {};
    for (const key in convertPayload) {
      if (Array.isArray(convertPayload[key])) {
        if (convertPayload[key].length) {
          newPayload[key] = convertPayload[key];
        }
      } else if (convertPayload[key]){
        newPayload[key] = convertPayload[key];
      }
    }

    let listPermitName = await permitNameList(newPayload).unwrap();

    listPermitName = {
      ...listPermitName, 
      permitNames: listPermitName?.rows.map((el: any, idx: any) => ({...el, key: idx}))
    };
    setData(listPermitName);
  };

  const fetchListMobile = async () => {
    let convertPayload: any = payloadMobile;
    let newPayload: any = {};
    for (const key in convertPayload) {
      if (Array.isArray(convertPayload[key])) {
        if (convertPayload[key].length) {
          newPayload[key] = convertPayload[key];
        }
      } else if (convertPayload[key]) {
        newPayload[key] = convertPayload[key];
      }
    }

    newPayload.searchFields = payloadMobile.searchFields;
    let listPermitName = await permitNameList(newPayload).unwrap();

    const newPermitNames = listPermitName?.rows.map((el: any, idx: any) => ({ ...el, key: idx }));
    const concatNewPermitNames = [...dataMobile.permitNames, ...newPermitNames];

    listPermitName = {
      ...listPermitName,
      permitNames: (!onSearchMobile) ? concatNewPermitNames : newPermitNames
    };

    setOnSearchMobile(false);
    setDataMobile(listPermitName);
  };

  useEffect(() => {
    const paths = pathname.split('/');
    if (!hasPermission(paths[1]).can_view) {
      navigate('/403');
    }
    setCanView(hasPermission(paths[1]).can_view);
    setCanCreate(hasPermission(paths[1]).can_create);
    setCanEdit(hasPermission(paths[1]).can_update);
  }, []);

  useEffect(() => {
    fetchList();
  }, [payload]);

  useEffect(() => {
    fetchListMobile();
  }, [payloadMobile]);

  useEffect(() => {
    setPayload({
      ...payload,
      lang: getLanguage()
    });

    setDataMobile({
      total: 0,
      permitNames: []
    });
    setPayloadMobile({
      ...payloadMobile,
      lang: getLanguage()
    });
  }, [getLanguage()]);

  useEffect(() => {
    if (isErrorPermitNameList){
      const errorMsg:any = errorPermitNameList;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorPermitNameList]);

  const buttonConfig: ButtonDataTableTypeItems = [
    {
      name: t('general.add_permit_name'),
      link: '',
      className: 'bg-[#55A853] text-white font-bold',
      onClick: () => { navigate('/setting-permit-name/create'); }
    }
  ];

  const buttonConfigMobile: ButtonDataTableTypeItems = [
    {
      name: t('general.add_permit_name'),
      link: '',
      className: 'bg-[#55A853] text-white font-bold w-[90%]',
      onClick: () => { navigate('/setting-permit-name/create'); }
    }
  ];

  const columns: ColumnsType<PermitRequestListType> = [
    {
      title: 'Action',
      key: 'action',
      width: 100,
      render: (data, record) => (
        <div>
          {
            canView &&
            <Button
              type='text'
              className='shadow-md shadow-gray-400 rounded-sm mr-3 p-0'
              onClick={() => navigate(`/setting-permit-name/${canEdit ? 'edit' : 'detail'}/${data.id}`)}
            >
              <FolderFilled style={{fontSize: '20px'}} className='text-[#55A853] p-1' />
            </Button>
          }
        </div>
      ),
    },
    {
      title: 'Permit Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      defaultSortOrder: 'ascend',
      render: (data, record) => {
        return (<div>{data[getLanguage()]}</div>);
      }
    },
    {
      title: 'Permit Type',
      dataIndex: 'permit_type_name',
      key: 'permit_type_name',
      sorter: true,
      render: (data, record) => {
        return (<div>{data}</div>);
      }
    },
    {
      title: 'Issuer Institution',
      dataIndex: 'permit_issuer_institution_name',
      key: 'permit_issuer_institution_name',
      sorter: true,
      render: (data, record) => {
        return (<div>{data}</div>);
      }
    },
    {
      title: 'Processed By',
      dataIndex: 'processed_by',
      key: 'processed_by',
      sorter: true,
      render: (data, record) => {
        return (<div>{data || '-'}</div>);
      }
    },
    {
      title: 'Process Estimation Time',
      dataIndex: 'required_time',
      key: 'required_time',
      sorter: true,
      render: (data, record) => {
        return (<div>{data}</div>);
      }
    },
    {
      title: 'Number of Required Docs',
      dataIndex: 'required_documents',
      key: 'required_documents',
      sorter: true,
      render: (data, record) => {
        return (<div>{data || '-'}</div>);
      }
    }
  ];

  const viewCardList = () => {
    return (
      <Spin tip="Loading" size="large" spinning={isLoading}>
        {
          dataMobile?.permitNames?.map((el: any, idx: any) => (
            <div key={idx} className="p-0 mt-1 mb-5 shadow-md bg-white rounded-xl p-4" onClick={() => {
              navigate(`/setting-permit-name/${canEdit ? 'edit' : 'detail'}/${el?.id}`);
            }}>
              <p className='font-bold text-[#389E0D] mb-1'>{el?.permit_type_name || '-'}</p>
              <p className='font-bold text-[12px] pb-4 border-b-2'>{el?.name?.[getLanguage()] || '-'}</p>
              <div className='mt-4'>
                <p className='text-[#616161] text-[12px] mb-1'>{t('dashboard.issuer_institution')}</p>
                <p className='font-bold'>{el?.permit_issuer_institution_name || '-'}</p>
              </div>
              <div className='mt-3'>
                <p className='text-[#616161] text-[12px] mb-1'>{t('permit_name.processed')}</p>
                <p className='font-bold'>{el?.processed_by || '-'}</p>
              </div>
              <div className='mt-3 flex'>
                <div className='mr-10'>
                  <p className='text-[#616161] text-[12px] mb-1'>{t('permit_name.processed_est_time')}</p>
                  <p className='font-bold'>{el?.required_time + ' ' + (el?.required_time > 1 ? t('general.weeks_text') : t('general.week_text')) || '-'}</p>
                </div>
                <div className='ml-8'>
                  <p className='text-[#616161] text-[12px] mb-1'>{t('permit_name.required_docs')}</p>
                  <p className='font-bold'>{el?.required_documents || '-'}</p>
                </div>
              </div>
            </div>
          ))
        }
        {
          dataMobile?.permitNames?.length !== dataMobile?.total ?
            (
              <Button
                type='text'
                className='bg-[#55A853] text-white px-10 w-full mt-4'
                disabled={isLoading}
                onClick={() => {
                  setPayloadMobile({ ...payloadMobile, page: payloadMobile.page + 1 });
                }}
              >
                {isLoading ? <Spin /> : t('general.load_more') || ''}
              </Button>
            ) :
            (<div></div>)
        }
      </Spin>
    );
  };

  return (
    <div className='w-full'>
      <div className='md:hidden'>
        <CardList
          payloadMobile={{
            payloadMobile: payloadMobile,
            setPayloadMobile: setPayloadMobile
          }}
          onSearchMobile={{
            onSearchMobile: onSearchMobile,
            setOnSearchMobile: setOnSearchMobile
          }}
          viewCardList={viewCardList}
          buttonConfig={canCreate ? buttonConfigMobile : []}
        />
      </div>
      <div className='max-md:hidden'>
        <DataTable
          className=''
          columns={columns}
          data={data.permitNames}
          buttonConfig={canCreate ? buttonConfig : []}
          loading={isLoading}
          totalData={data.total}
          payload={{
            payload: payload,
            setPayload: setPayload
          }}
          modalOpen={false}
          scrollWidth={1600}
        />
      </div>
    </div>
  );
};

export default PermitName;