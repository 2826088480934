import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import DataTable from 'components/DataTable';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FolderFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import ModalFilter from './ModalFilter';
import Toast from 'components/Toast';
import { transformError } from 'utils/ErrorTransformer';
import { getStyleBadge, getStyleByColor } from 'utils/Utility';
import { useGetListComplianceMonitoringMutation } from 'api/ComplianceMonitoring';
import { ListPayloadType } from 'constant/Types/ComplianceMonitoring';
import moment from 'moment';
import { COLOR_STATUS_COMPLIANCE_MONITORING, DATA_COLOR_COMPLIANCE_STATUS } from 'constant';

const ComplianceMonitoring = () => {
  const [getListComplianceMonitoring, {
    error: errorGetListComplianceMonitoring,
    isError: isErrorGetListComplianceMonitoring,
    isLoading
  }] = useGetListComplianceMonitoringMutation();

  const { t, i18n } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const navigate = useNavigate();
  const [data, setData] = useState({
    total: 0,
    permitRequests: []
  });
  const [payload, setPayload] = useState<ListPayloadType>({
    page: 1,
    pageSize: 50,
    search: '',
  });
  const [filter, setFilter] = useState<any>({
    permit_location: [],
    compliance_status: [],
    current_risk_assesment: [],
    status: [],
    submitted_date: null
  });
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const applyFilter = () => {
    const submittedDate = filter?.submitted_date;
    setIsLoadingFilter(true);
    setPayload({
      ...payload,
      page: 1,
      permit_location: filter?.permit_location,
      compliance_status: filter?.compliance_status,
      current_risk_assessment: filter?.current_risk_assesment,
      status: filter?.status,
      submitted_date: (submittedDate?.[0] && submittedDate?.[1]) 
        ? `${submittedDate[0].format('YYYY-MM-DD')},${submittedDate[1].format('YYYY-MM-DD')}` 
        : ''
    });
  };

  const resetFilter = () => {
    setIsLoadingFilter(true);
    setFilter({
      permit_location: [],
      compliance_status: [],
      current_risk_assesment: [],
      status: [],
      submitted_date: null
    });
    setPayload({
      ...payload,
      permit_location: [],
      compliance_status: [],
      current_risk_assessment: [],
      status: [],
      submitted_date: null
    });
  };

  const fetchList = async () => {
    try {
      let convertPayload: any = payload;
      let newPayload: any = {};
      for (const key in convertPayload) {
        if (Array.isArray(convertPayload[key])) {
          if (convertPayload[key].length) {
            newPayload[key] = convertPayload[key];
          }
        } else if (convertPayload[key]) {
          newPayload[key] = convertPayload[key];
        }
      }

      let listPermitRequest = await getListComplianceMonitoring(newPayload).unwrap();

      listPermitRequest = {
        ...listPermitRequest,
        permitRequests: listPermitRequest?.rows.map((el: any, idx: any) => ({ ...el, key: idx }))
      };
      setData(listPermitRequest);
    } finally {
      setIsLoadingFilter(false);
    }
  };

  useEffect(() => {
    setIsLoadingFilter(true);
    fetchList();
  }, [payload]);

  useEffect(() => {
    if (isErrorGetListComplianceMonitoring) {
      const errorMsg: any = errorGetListComplianceMonitoring;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [errorGetListComplianceMonitoring, isErrorGetListComplianceMonitoring]);

  const columns: ColumnsType<any> = [
    {
      title: 'Action',
      key: 'action',
      width: 80,
      render: (_data, record) => (
        <div>
          <Button
            type='text'
            className='shadow-md shadow-gray-400 rounded-sm mr-3 p-0'
            onClick={() => {
              if(record?.id) navigate(`/compliance-monitoring/edit/${record.id}`);
            }}
          >
            <FolderFilled style={{ fontSize: '20px' }} className='text-[#55A853] p-1' />
          </Button>
        </div>
      ),
    },
    {
      title: 'Request No',
      dataIndex: 'request_number',
      key: 'request_number',
      width: 220,
      sorter: true,
      render: (_data, record) => {
        return (<div>{record?.request_number || '-'}</div>);
      }
    },
    {
      title: 'Location',
      dataIndex: 'permit_location',
      key: 'permit_location',
      sorter: true,
      render: (data) => {
        return (<div>{data?.name}</div>);
      }
    },
    {
      title: 'Permit Name',
      dataIndex: 'permit_name',
      key: 'permit_name',
      render: (_data, record) => {
        return (<div>{record?.permit_name?.name[getLanguage()]}</div>);
      }
    },
    {
      title: t('compliance_monitoring.compliance_status'),
      dataIndex: 'compliance_status',
      key: 'compliance_status',
      width: 200,
      sorter: true,
      render: (_data, record) => (
        <span
          style={getStyleBadge(record?.permit_request_compliances?.compliance_status?.toLowerCase() || '', DATA_COLOR_COMPLIANCE_STATUS) || {}}
          className="py-1 px-2 rounded-lg border border-solid text-xs text-center"
        >
          {record?.permit_request_compliances?.compliance_status || '-'}
        </span>
      )      
    },
    {
      title: t('compliance_monitoring.curent_risk_assesment'),
      dataIndex: 'curent_risk_assesment',
      key: 'curent_risk_assesment',
      width: 220,
      sorter: true,
      render: (_data, record) => {
        return (
          <span
            style={getStyleByColor(record?.permit_request_compliances?.current_risk_assessment?.hexacolor || '')}
            className={'py-1 px-2  rounded-lg border border-solid text-xs text-center'}
          >
            {record?.permit_request_compliances?.current_risk_assessment?.category || '-'}
          </span>
        );
      }
    },
    {
      title: t('compliance_monitoring.status'),
      dataIndex: 'grouping_status',
      key: 'grouping_status',
      width: 120,
      render: (_data, record) => {
        return (
          <span
            style={getStyleBadge(record?.grouping_status, COLOR_STATUS_COMPLIANCE_MONITORING)}
            className={'py-1 px-2  rounded-lg border border-solid text-xs text-center'}
          >
            {record?.grouping_status || '-'}
          </span>
        );
      }
    },
    {
      title: 'Submitted Date',
      dataIndex: 'submitted_date',
      key: 'submitted_date',
      width: 150,
      render: (_data, record) => {
        const data = record?.created_at;
        return (<div>{data ? moment(data).format('DD MMM YYYY') : '-'}</div>);
      }
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
      render: (_data, record) => {
        return (<div>{record?.permit_request_compliances?.remarks}</div>);
      }
    },
  ];

  return (
    <div className='w-full'>
      <ModalFilter
        modalAction={{
          isModalOpen: isModalOpen,
          setIsModalOpen: setIsModalOpen
        }}
        filter={{
          filter: filter,
          setFilter: setFilter
        }}
        filterAction={{
          isLoadingFilter: isLoadingFilter,
          resetFilter: resetFilter,
          applyFilter: applyFilter
        }}
      />
      <DataTable
        className=''
        columns={columns}
        data={data.permitRequests}
        loading={isLoading}
        totalData={data.total}
        payload={{
          payload: payload,
          setPayload: setPayload
        }}
        modalOpen={{
          isModalOpen: isModalOpen,
          setIsModalOpen: setIsModalOpen
        }}
        scrollWidth={1500}
      />
    </div>
  );
};

export default ComplianceMonitoring;