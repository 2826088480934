import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { menus } from 'constant/BottomNavigation';

const BottomNavigation = (props: any) => {
  const { className, path } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isCurrentUrl = (url: string) => url === path;

  const onMenuClick = (path: string) => navigate(path);
  
  return (
    <div className={`${className} w-full`}>
      <div className="p-2 grid grid-cols-4 gap-2 bg-[#55A853]">
        {
          menus.map((item: any) => (
            <div
              key={item.id}
              data-testid={`ContainerMenu${item.id}`}
              className={`flex flex-col items-center cursor-pointer rounded-md py-2 mx-3 ${isCurrentUrl(item.url) ? 'bg-[#F7B648]' : ''}`}
              onClick={() => onMenuClick(item.url)}
            >
              {item.icon(isCurrentUrl(item.url))}
              <p
                data-testid={`TextNavigation${item.id}`}
                className='text-white text-center'
              >
                {t(item.name)}
              </p>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default BottomNavigation;