import type { AuthLoginType, AuthLoginResponse, AuthAzure } from 'constant/Types/AuthType';
import type { CredentialType } from 'constant/Types/CredentialType';
import { Base, transformError } from './Base';
import { saveAuthToDb } from 'store/Auth/Thunk';

import {
  DB_CREDENTIAL_TOKEN,
} from 'constant';

const Auth = Base.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation({
      query: (body: AuthLoginType) => ({
        url: '/auth/login',
        method: 'POST',
        body
      }),
      transformResponse: (response: AuthLoginResponse) => response,
      transformErrorResponse: (response) => transformError(response),
      async onQueryStarted(_, { dispatch, queryFulfilled, }) {
        const { data } = await queryFulfilled;
        if (data.token) {
          const secretToken: CredentialType = {
            _id: DB_CREDENTIAL_TOKEN,
            value: data.token
          };

          await dispatch(saveAuthToDb(secretToken)).unwrap();
        }
      }
    }),
    loginAzure: build.mutation({
      query: (body: AuthAzure) => ({
        url: '/auth/login/azure',
        method: 'POST',
        body
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled, }) {
        const { data } = await queryFulfilled;
        if (data){
          if (data?.token) {
            const secretToken: CredentialType = {
              _id: DB_CREDENTIAL_TOKEN,
              value: data.token
            };
  
            await dispatch(saveAuthToDb(secretToken)).unwrap();
          }
        }
      }
    }),
  })
});

export const { useLoginMutation, useLoginAzureMutation } = Auth;