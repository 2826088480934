import { Button, Empty, Form, Modal, Select, Spin } from 'antd';
import { STATUS } from 'constant';
import { useTranslation } from 'react-i18next';

const ModalFilter = (props: any) => {
  const { modalAction, filter, filterAction, mapDataCluster } = props;
  const { t } = useTranslation();
  
  return (
    <Modal
      title={null}
      open={modalAction.isModalOpen}
      footer={null}
      width={1000}
      onOk={() => modalAction.setIsModalOpen(false)}
      onCancel={() => modalAction.setIsModalOpen(false)}
    >
      <div className="bg-white p-[16px] md:p-[24px] rounded-lg mb-5">
        <p className='font-bold text-[16px] mb-5'>{t('manage_group.filter_label')}</p>
        <Form layout='vertical'>
          <div className='grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 gap-5'>
            <div className='col-span-2'>
              <Form.Item
                label='Status'
              >
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  options={STATUS}
                  placeholder={t('manage_group.status_placeholder')}
                  onChange={(e) => filter.setFilter({...filter.filter, status: e})}
                  value={filter.filter.status}
                />
              </Form.Item>
            </div>
            <div className='col-span-2'>
              <Form.Item
                label={t('manage_group.cluster_label')}
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={t('manage_group.cluster_placeholder')}
                  options={mapDataCluster}
                  value={filter.filter.cluster}
                  onChange={(e) => filter.setFilter({...filter.filter, cluster: e})}
                  notFoundContent={filterAction.isLoadingCluster ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                />
              </Form.Item>
            </div>
            <div className='grid grid-cols-2 gap-4 content-end'>
              <Button type='text' className='bg-white border-[#55A853] text-[#55A853] font-bold' onClick={filterAction.resetFilter} disabled={filterAction.isLoadingFilter}>
                {filterAction.isLoadingFilter ? <Spin></Spin> : (t('manage_group.reset_btn') || '')}
              </Button>
              <Button
                type='text'
                className='bg-[#55A853] text-white font-bold'
                onClick={() => {
                  filterAction.applyFilter();
                  modalAction.setIsModalOpen(false);
                }}
                disabled={filterAction.isLoadingFilter}
              >
                {filterAction.isLoadingFilter ? <Spin></Spin> : t('manage_group.apply_btn') || ''}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ModalFilter;