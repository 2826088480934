import { Navigate } from 'react-router';
import * as React from 'react';
import Login from 'pages/Login';
import Base from 'pages/Base';
import Dashboard from 'pages/Dashboard';
import Activity from 'pages/Activity';
import ListPermitTracker from 'pages/DocumentLibrary/PermitTracker';
import UserLog from 'pages/UserLog';
import ManageGroup from 'pages/ManageGroup';
import ManageGroupForm from 'pages/ManageGroup/ManageGroupForm';
import ManageUser from 'pages/ManageUser';
import ManageUserForm from 'pages/ManageUser/Form';
import Splash from 'pages/Splash';
import Obligation from 'pages/Create/Obligation';
import ListPermitRequest from 'pages/DocumentLibrary/PermitRequest';
import Forbidden from 'pages/Forbidden';
import Notification from 'pages/Notification';
import PermitName from 'pages/Settings/PermitName';
import PermitNameForm from 'pages/Settings/PermitName/Form';
import Projects from 'pages/Projects';
import PermitMonitoring from 'pages/DocumentLibrary/PermitMonitoring';
import ApplicationProfile from 'pages/Settings/ApplicationProfile';
import ReportFeedback from 'pages/DocumentLibrary/ReportFeedback';
import SettingFeedback from 'pages/Settings/Feedback';
import ReportErPerformance from 'pages/DocumentLibrary/ReportErPerformance';
import Cluster from 'pages/Settings/Cluster';
import PermitMonitoringCrm from 'pages/DocumentLibrary/PermitMonitoringCrm';
import PermitNameObligationEditForm from 'pages/Settings/PermitName/Form/Obligation/Form/Edit';
import FormPermitMonitoringCrm from 'pages/DocumentLibrary/PermitMonitoringCrm/Create';
import PermitFlowProcess from 'pages/Create/PermitFlowProcess';
import PermitFlowProcessChildView from 'pages/Create/PermitFlowProcess/ChildView';
import PermitNameObligationForm from 'pages/Settings/PermitName/Form/Obligation/Form/Add';
import PermitMonitoringObligationForm from 'pages/DocumentLibrary/PermitMonitoringCrm/Create/Obligation/Form/Add';
import PermitMonitoringCrmObligationEditForm from 'pages/DocumentLibrary/PermitMonitoringCrm/Create/Obligation/Form/Edit';
import PermitMonitoringObligationAddActionOnEditForm from 'pages/DocumentLibrary/PermitMonitoringCrm/Create/Obligation/Form/Edit/Add';
import PermitType from 'pages/Settings/PermitType';
import DetailCompany from 'pages/Dashboard/Companies/Detail';
import ManageCronjob from 'pages/Settings/ManageCronjob';
import InitiatePermitRequest from 'pages/Create/InitiatePermitRequest';
import ComplianceMonitoring from 'pages/DocumentLibrary/ComplianceMonitoring';
import FormComplianceMonitoring from 'pages/DocumentLibrary/ComplianceMonitoring/Create';
import Feedback from 'pages/Feedback';
import EmailNotification from 'pages/Settings/EmailNotification';

const PermitLicenseRequest = React.lazy(() => import('../pages/Create/PermitLicenseRequest'));

const routes = () => {
  return [
    {
      path: '',
      element: <Navigate to="/splash" />
    },
    {
      path: 'splash',
      element: <Splash />
    },
    {
      path: 'projects',
      element: <Projects />
    },
    {
      path: 'login',
      name: 'Login',
      element: <Login />
    },
    {
      path: 'dashboard',
      element: <Base />,
      children: [
        {
          path: '',
          element: <Dashboard />
        },
        {
          path: 'company-detail/:id/:year',
          element: <DetailCompany />
        },
      ]
    },
    {
      path: 'my-activity',
      element: <Base />,
      children: [
        {
          path: '',
          element: <Activity />
        }
      ]
    },
    {
      path: 'tracker',
      element: <Base />,
      children: [
        {
          path: '',
          element: <ListPermitTracker />
        }
      ]
    },
    {
      path: 'user-log',
      element: <Base />,
      children: [
        {
          path: '',
          element: <UserLog />
        }
      ]
    },
    {
      path: 'manage-group',
      element: <Base />,
      children: [
        {
          path: '',
          element: <ManageGroup />
        },
        {
          path: 'create',
          element: <ManageGroupForm />
        },
        {
          path: 'edit/:id',
          element: <ManageGroupForm />
        },
        {
          path: 'detail/:id',
          element: <ManageGroupForm />
        },
      ]
    },
    {
      path: 'manage-user',
      element: <Base />,
      children: [
        {
          path: '',
          element: <ManageUser />
        },
        {
          path: 'edit/:id',
          element: <ManageUserForm />
        },
        {
          path: 'detail/:id',
          element: <ManageUserForm />
        },
      ]
    },
    {
      path: 'create-permit-request',
      element: <Base />,
      children: [
        {
          path: '',
          element: <PermitLicenseRequest />
        }
      ]
    },
    {
      path: 'obligation',
      element: <Base />,
      children: [
        {
          path: '',
          element: <Obligation />
        }
      ]
    },
    {
      path: 'permit-request',
      element: <Base />,
      children: [
        {
          path: '',
          element: <ListPermitRequest />
        },
        {
          path: 'detail/:id',
          element: <PermitLicenseRequest />
        },
        {
          path: 'edit/:id',
          element: <PermitLicenseRequest />
        },
      ]
    },
    {
      path: 'permit-monitoring',
      element: <Base />,
      children: [
        {
          path: '',
          element: <PermitMonitoring />
        }
      ]
    },
    {
      path: 'compliance-monitoring',
      element: <Base />,
      children: [
        {
          path: '',
          element: <ComplianceMonitoring />
        },
        {
          path: 'edit/:id',
          element: <FormComplianceMonitoring />
        },
        {
          path: 'detail/:id',
          element: <FormComplianceMonitoring />
        },
      ]
    },
    {
      path: 'notification',
      element: <Base />,
      children: [
        {
          path: '',
          element: <Notification />
        }
      ]
    },
    {
      path: 'setting-permit-name',
      element: <Base />,
      children: [
        {
          path: '',
          element: <PermitName />
        },
        {
          path: 'create',
          element: <PermitNameForm />
        },
        {
          path: 'detail/:id',
          element: <PermitNameForm />
        },
        {
          path: 'edit/:id',
          element: <PermitNameForm />,
        },
        {
          path: 'edit/:id/:tab',
          element: <PermitNameForm />,
        },
        {
          path: 'edit/:id/obligation/add',
          element: <PermitNameObligationForm />
        },
        {
          path: 'edit/:id/obligation/edit/:obligationId',
          element: <PermitNameObligationEditForm />
        },
      ]
    },
    {
      path: 'setting-permit-type',
      element: <Base />,
      children: [
        {
          path: '',
          element: <PermitType />
        }
      ]
    },
    {
      path: 'manage-cronjob',
      element: <Base />,
      children: [
        {
          path: '',
          element: <ManageCronjob />
        }
      ]
    },
    {
      path: 'setting-feedback',
      element: <Base />,
      children: [
        {
          path: '',
          element: <SettingFeedback />
        }
      ]
    },
    {
      path: 'application-profile',
      element: <Base />,
      children: [
        {
          path: '',
          element: <ApplicationProfile />
        }
      ]
    },
    {
      path: 'report-feedback',
      element: <Base />,
      children: [
        {
          path: '',
          element: <ReportFeedback />
        }
      ]
    },
    {
      path: 'report-er-performance',
      element: <Base />,
      children: [
        {
          path: '',
          element: <ReportErPerformance />
        }
      ]
    },
    {
      path: 'clusters',
      element: <Base />,
      children: [
        {
          path: '',
          element: <Cluster />
        }
      ]
    },
    {
      path: 'permit-monitoring-crm',
      element: <Base />,
      children: [
        {
          path: '',
          element: <PermitMonitoringCrm />
        },
        {
          path: 'edit/:id',
          element: <FormPermitMonitoringCrm />
        },
        {
          path: 'detail/:id',
          element: <FormPermitMonitoringCrm />
        },
        {
          path: 'edit/:id/obligation/add',
          element: <PermitMonitoringObligationForm />
        },
        {
          path: 'edit/:id/obligation/edit/:obligationId',
          element: <PermitMonitoringCrmObligationEditForm />
        },
        {
          path: 'edit/:id/obligation/edit/:obligationId/add',
          element: <PermitMonitoringObligationAddActionOnEditForm />
        },
      ]
    },
    {
      path: 'permit-flow-process',
      element: <Base />,
      children: [
        {
          path: '',
          element: <PermitFlowProcess />
        },
        {
          path: 'deep/:name/:id',
          element: <PermitFlowProcessChildView />
        }
      ]
    },
    {
      path: 'initiate-permit-request',
      element: <Base />,
      children: [
        {
          path: '',
          element: <InitiatePermitRequest />
        }
      ]
    },
    {
      path: 'email-notification',
      element: <Base />,
      children: [
        {
          path: '',
          element: <EmailNotification />
        }
      ]
    },
    {
      path: 'feedback',
      element: <Base />,
      children: [
        {
          path: '',
          element: <Feedback />
        }
      ]
    },
    {
      path: '403',
      element: <Base />,
      children: [
        {
          path: '',
          element: <Forbidden />
        }
      ]
    },
    {
      path: '*',
      element: <Navigate to='/' />
    }
  ];
};

export default routes;