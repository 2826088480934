import { useTranslation } from 'react-i18next';
import { Button, Spin } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Toast from 'components/Toast';
import { transformError } from 'utils/ErrorTransformer';
import { EyeOutlined, EditFilled } from '@ant-design/icons';
import SimpleDataTable from 'components/SimpleDataTable';
import ModalLog from './ModalLog';
import ModalExec from './ModalExec';
import { useLocation } from 'react-router';
import { hasPermission } from 'utils/Permission';
import { useGetCronjobMutation } from 'api/ManageCronjob';
import cronstrue from 'cronstrue';
import { isValidCron } from 'cron-validator';
import moment from 'moment';
import { getStyleBadge } from 'utils/Utility';
import { DATA_COLOR_HEALTH_STATUS_CRONJOB, DATA_COLOR_STATUS_CRONJOB } from 'constant';

interface ICronjob{
  id: string;
  cronName: string;
  integration: string;
  period: string;
  lastRun: string;
  status: 'Active' | 'Inactive';
  healthStatus: 'Health' | 'Not Healthy';
}

const ManageCronjob = () => {
  const { pathname } = useLocation();

  const [getCronjob, {
    error: errorGetCronjob,
    isError: isErrorGetCronjob,
    isLoading,
  }] = useGetCronjobMutation();

  const { t } = useTranslation();
  const [data, setData] = useState<ICronjob[]>([]);
  const [showModalExec, setShowModalExec] = useState(false);
  const [showModalLog, setShowModalLog] = useState(false);
  const [cronSelected, setCronSelected] = useState<any>(null);
  const [canUpdate, setCanUpdate] = useState(false);

  const fetchList = async () => {
    const listCronjobResponse = await getCronjob({}).unwrap();
    const filteredResponse: ICronjob[] = listCronjobResponse.map((cron: any) => ({
      id: cron?.id || '',
      cronName: cron?.description || '',
      integration: cron?.name || '',
      period: isValidCron(cron?.cron_time || '') ? cronstrue.toString(cron.cron_time, {use24HourTimeFormat: true}) : '',
      lastRun: cron?.last_run || '',
      status: cron?.is_active ? 'Active' : 'Inactive',
      healthStatus: cron?.health_status === 'Health' ? 'Health' : 'Not Healhy',
    }));
    setData(filteredResponse);
  };

  const handleShowModalExec = (record: any = null) => {
    setCronSelected(record);
    setShowModalExec(true);
  };

  const handleShowModalLog = (record: any = null) => {
    setCronSelected(record);
    setShowModalLog(true);
  };

  useEffect(() => {
    fetchList();
  }, []);

  useEffect(() => {
    if (isErrorGetCronjob){
      const errorMsg:any = errorGetCronjob;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorGetCronjob]);

  const columns: ColumnsType<any> = [
    {
      title: t('manage_cronjob.action'),
      key: 'action',
      width: 100,
      render: (_, record) => (
        <div className='flex'>
          <Button
            type='text'
            className='shadow-md shadow-gray-400 rounded-sm mr-3 p-0'
            onClick={() => handleShowModalExec(record)}
          >
            <EditFilled style={{ fontSize: '20px' }} className='text-[#55A853] p-1' />
          </Button>
          <Button
            type='text'
            className='shadow-md shadow-gray-400 rounded-sm mr-3 p-0'
            onClick={() => handleShowModalLog(record)}
          >
            <EyeOutlined style={{ fontSize: '20px' }} className='text-[#55A853] p-1' />
          </Button>
        </div>
      ),
    },
    {
      title: t('manage_cronjob.cron_name'),
      dataIndex: 'cronName',
      key: 'cronName',
    },
    {
      title: t('manage_cronjob.function_name'),
      dataIndex: 'integration',
      key: 'integration',
    },
    {
      title: t('manage_cronjob.period'),
      dataIndex: 'period',
      key: 'period',
    },
    {
      title: t('manage_cronjob.last_run'),
      dataIndex: 'lastRun',
      key: 'lastRun',
      width: 180,
      render: (data) => {
        return (<div>{data ? moment(data).format('D MMMM YYYY, HH:mm') : '-'}</div>);
      }
    },
    {
      title: t('manage_cronjob.health_status'),
      dataIndex: 'healthStatus',
      key: 'healthStatus',
      width: 120,
      render: (data) => {
        return (
          <span
            style={getStyleBadge(data, DATA_COLOR_HEALTH_STATUS_CRONJOB)}
            className={'py-1 px-2  rounded-lg border border-solid text-xs text-center'}
          >
            {data || '-'}
          </span>
        );
      }
    },
    {
      title: t('manage_cronjob.status'),
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (data) => {
        return (
          <span
            style={getStyleBadge(data, DATA_COLOR_STATUS_CRONJOB)}
            className={'py-1 px-2  rounded-lg border border-solid text-xs text-center'}
          >
            {data || '-'}
          </span>
        );
      }
    },
  ];

  useEffect(() => {
    const paths = pathname.split('/');
    const permissions = hasPermission(paths[1]);
    setCanUpdate(permissions.can_update);
  }, [pathname]);

  return (
    <Spin tip="Loading" size="large" spinning={isLoading}>
      <div className='w-full'>
        <SimpleDataTable
          columns={columns}
          dataSource={data}
          loading={isLoading}
        />
        <ModalExec
          modalAction={{
            showModal: showModalExec,
            setShowModal: setShowModalExec
          }}
          data={cronSelected}
          canUpdate={canUpdate}
          callback={fetchList}
        />
        <ModalLog
          modalAction={{
            showModal: showModalLog,
            setShowModal: setShowModalLog
          }}
          data={cronSelected}
        />
      </div>
    </Spin>
  );
};

export default ManageCronjob;