import { useEffect, useState } from 'react';
import Toast from 'components/Toast';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useParams } from 'react-router';
import { transformError } from 'utils/ErrorTransformer';
import { FaChevronLeft } from 'react-icons/fa';
import { useUserHrisListMutation } from 'api/Options';
import dayjs from 'dayjs';
import { Button, DatePicker, Empty, Form, Input, Modal, Select, Spin } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { useTranslation } from 'react-i18next';
import { LIST_ROLE_CRM, STATUS_OBLIGATION } from 'constant';
import Dragger from 'antd/es/upload/Dragger';
import { BsCloudUpload } from 'react-icons/bs';
import { downloadFile, downloadFileUrl, getBorderAttachmentStep, getColorAttachmentStep } from 'utils/Utility';
import { FileOutlined, CloseOutlined } from '@ant-design/icons';
import type { UploadFile } from 'antd';
import { TYPEUPLOAD, MAXFILESIZE } from 'constant';
import { useDelAttachmentActionObligationMutation, useDetailPermitMonitoringMutation, useGetObligationDetailMutation, usePostAttachmentActionObligationMutation, usePostCompleteActionMutation, usePutObligationMutation } from 'api/PermitMonitoring';
import { ExclamationCircleFilled } from '@ant-design/icons';
import ModalRevise from './ModalRevise';
import { useAppSelector } from 'store/Hooks';

const PermitMonitoringCrmObligationEditForm = () => {
  const { user } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = useLocation();
  const { id, obligationId } = useParams();
  const [form] = Form.useForm();
  const { confirm } = Modal;
  const initialForm = {
    name: null,
    is_from_permit_name: false,
    actions: [
      {
        name: null,
        pic: null,
        status: null,
        due_date: null,
        attachments: [],
      },
    ],
  };
  const [obligationDetail, setObligationDetail] = useState<any>(null);
  const [optionsList, setOptionsList] = useState<any[][]>([]);
  const [openModalRevise, setOpenModalRevise] = useState<boolean>(false);
  const [dataRevise, setDataRevise] = useState<any>(null);
  const [isCrm, setIsCrm] = useState<boolean>(false);

  const [getDetail, {
    error: errorDetail,
    isError: isErrorDetail,
    isLoading: isLoadingGetDetail,
  }] = useGetObligationDetailMutation();
  const [getDetailProductDoc] = useDetailPermitMonitoringMutation();
  const [userHrisList, {
    isLoading: isLoadingUserHris
  }] = useUserHrisListMutation();
  const [putObligation, { isLoading: isLoadingSubmitObligation }] = usePutObligationMutation();
  const [postTemplateAttachment] = usePostAttachmentActionObligationMutation();
  const [delTemplateAttachment] = useDelAttachmentActionObligationMutation();
  const [postCompleteAction, {isLoading: isLoadingCompleteAction}] = usePostCompleteActionMutation();

  const fetchDetail = async (id: any) => {
    let dataDetail = await getDetail(id).unwrap();
    setOptionsList(new Array(dataDetail?.length).fill([]));
    setObligationDetail({
      name: dataDetail?.[0]?.product_doc_obligation?.name,
      is_from_permit_name: dataDetail?.[0]?.product_doc_obligation?.permit_name_obligation_id !== null,
      actions: dataDetail?.map((action: any) => ({
        ...action,
        is_from_permit_name: action.permit_name_obligation_action_id !== null,
        name: action.name,
        pic_emp_id: action.pic?.hris_emp_id,
        status: action.status,
        due_date: action.due_date ? dayjs(action.due_date) : null,
        existing: action.attachments,
        attachments: []
      }))
    });
  };

  const fetchDetailProductDoc = async (id: string) => {
    let dataDetail = await getDetailProductDoc(id).unwrap();
    setDataRevise((prev: any) => ({
      ...prev,
      registration_number: dataDetail?.registration_number,
      last_update: dataDetail?.updated_at,
      status: 'Completed',
    }));
  };

  const addAction = () => {
    localStorage.setItem('obligation-tmp', JSON.stringify({
      id: obligationId,
      name: obligationDetail.name,
    }));
    navigate(`${pathname}/add`);
  };

  const removeAction = (actionIndex: number) => {
    const actions = form.getFieldValue('actions');
    actions.splice(actionIndex, 1);
    form.setFieldValue('actions', actions);
  };

  const backToTabObligation = () => {
    const currentPath = location.pathname;
    const newPath = currentPath.split('/').slice(0, -3).join('/');
    navigate(`${newPath}?tab=2`);
  };

  const onFinish = async () => {
    const dataForm = form.getFieldsValue();

    const getAttachmentsId = (action: any) => {
      let tempExisting = [];
      if (action?.existing?.length) {
        tempExisting = action?.existing?.map((elE: any) => ({
          id: elE?.id
        }));
      }

      let tempAttachment: object[] = [];
      if (action?.attachments?.length) {
        tempAttachment = action?.attachments?.map((elAtt: any) => ({
          id: elAtt?.id
        }));
      }

      return tempExisting.concat(tempAttachment);
    };

    const payload = {
      obligation_name: dataForm.name,
      product_doc_obligation_actions: dataForm.actions?.map((action: any) => ({
        id: action?.id,
        name: action?.name,
        pic: action?.pic_emp_id,
        status: action?.status,
        due_date: action?.due_date ? action.due_date.format('YYYY-MM-DD') : null,
        attachments: getAttachmentsId(action),
      }))
    };

    await putObligation({id: obligationId!!, body: payload}).unwrap().then(() => {
      toast.success(
        <Toast
          message={t('general.success_text')}
          detailedMessage={`${t('permit_monitoring.obligation.success_edit_obligation')}`}
        />
      );

      backToTabObligation();
    })
      .catch(() => {
        toast.error(
          <Toast
            message={t('general.error_text')}
            detailedMessage={t('permit_monitoring.obligation.error_edit_obligation')}
          />
        );
      });
  };

  const fetchUserHris = async (value: any, index: number) => {
    const payload = {
      filters: value ? `fullname@=${value}` : ''
    }; 
    const { data } = await userHrisList(payload).unwrap();
    const filteredData = data.filter((v: any) => v.actingStatus === 'ACTIVE' && !v.endDateSsis);
    setOptionsList(prevOptionsList => {
      const updatedOptionsList = [...prevOptionsList];
      updatedOptionsList[index] = filteredData;
      return updatedOptionsList;
    });
  };

  const fetchOptionList = async (actionList: any[]) => {
    const listTmp = await Promise.all(
      actionList.map(async (action: any) => {
        const payload = {
          filters: action?.pic?.display_name ? `fullname@=${action.pic.display_name}` : ''
        };
        const { data } = await userHrisList(payload).unwrap();
        return data.filter((v: any) => v.actingStatus === 'ACTIVE' && !v.endDateSsis);
      })
    );
    setOptionsList(listTmp);
  };

  const uploadImageReqDocs = async (options: any, idx: any) => {
    const reader = new FileReader();
    const { onSuccess, file } = options;
    let fileUpload;
    let newDynamicReqDocs = [...form.getFieldsValue()?.actions];

    if ((newDynamicReqDocs[idx]?.attachments?.length + newDynamicReqDocs[idx]?.existing?.length) >= 5) {
      toast.error(
        <Toast
          message={t('general.error_text')}
          detailedMessage={t('permit_request.product_doc.error_limit_file')}
        />);
      return false;
    }

    const idxC = newDynamicReqDocs[idx].attachments.length;
    // step 1: uploading, step 3: success
    newDynamicReqDocs[idx].attachments[idxC] = {file: file, step: 1, id: null, isLoading: true};
    form.setFieldsValue({
      ...form,
      actions: newDynamicReqDocs
    });
    setTimeout(() => {
      reader.onload = async (e) => {
        fileUpload = e?.target?.result || null;
        const payload = {
          product_doc_obligation_action_attachment: {
            product_doc_obligation_action_id: newDynamicReqDocs[idx].id,
            attachment_file: fileUpload,
            filename: file.name
          }
        };

        await postTemplateAttachment(payload).unwrap().then((response: any) => {
          let newDynamicReqDocsSuccess = [...form.getFieldsValue()?.actions];
          newDynamicReqDocsSuccess[idx].attachments[idxC].step = 3;
          newDynamicReqDocsSuccess[idx].attachments[idxC].id = response?.id;
          newDynamicReqDocsSuccess[idx].attachments[idxC].isLoading = false;
          form.setFieldsValue({
            ...form,
            actions: newDynamicReqDocsSuccess
          });
          onSuccess('Ok');
        }).catch((e: any) => {
          const errorMsg:any = e;
          toast.error(
            <Toast 
              message={`${t('permit_request.list_doc.failed_upload')} ${file?.name}`} 
              detailedMessage={`${transformError(errorMsg?.data).message}`} 
            />);

          let newDynamicReqDocsError = [...form.getFieldsValue()?.actions];
          newDynamicReqDocsError[idx].attachments?.splice(idxC, 1);
          newDynamicReqDocsError[idx].attachmentFileData?.fileList.splice(idxC, 1);
          if (!newDynamicReqDocsError[idx].attachmentFileData?.fileList.length) {
            newDynamicReqDocsError[idx].attachmentFileData = [];
          }
          form.setFieldsValue({
            ...form,
            actions: newDynamicReqDocsError
          });
        });
      };
      reader.readAsDataURL(file);
    }, 100);
  };

  const beforeUploadFile = (file: UploadFile) => {
    const { type, name, size } = file;
    let specifiecType = type?.substring((type?.lastIndexOf('/') || 0) + 1);
    if (specifiecType === '' || specifiecType === 'x-zip-compressed' || specifiecType?.includes('officedocument')) {
      specifiecType = name.split('.').pop();
    }
    const isAllowedType = TYPEUPLOAD.includes(specifiecType || '');
    if (!isAllowedType) {
      toast.error(
        <Toast
          message={t('general.error_text')}
          detailedMessage={`${t('permit_request.list_doc.upload_failed_type_one') + name + t('permit_request.list_doc.upload_failed_type_two')} (${TYPEUPLOAD})`}
        />);
      return false;
    }

    if ((size || 0) > MAXFILESIZE.VAL_ON_BYTE) {
      toast.error(
        <Toast
          message={t('general.error_text')}
          detailedMessage={`
            ${t('permit_request.list_doc.upload_failed_type_one') +
            name + t('permit_request.list_doc.upload_failed_type_three') +
            MAXFILESIZE.VAL_CONVERT_BYTE_TO_CURRENT +
            MAXFILESIZE.CURRENT_MEMORY}`
          }
        />);
      return false;
    }

    return true;
  };

  const delReqDocs = async (idx: any, idxC: any) => {
    let newDynamicReqDocs = [...form.getFieldsValue()?.actions];
    const idDocs = newDynamicReqDocs[idx].attachments[idxC].id;
    if (idDocs) {
      newDynamicReqDocs[idx].attachments[idxC].isLoading = true;
      await delTemplateAttachment(idDocs).unwrap().then((resp: any) => {
        newDynamicReqDocs[idx].attachments?.splice(idxC, 1);
        form.setFieldsValue({
          ...form,
          actions: newDynamicReqDocs
        });
      }).catch((e:any) => {
        newDynamicReqDocs[idx].attachments[idxC].isLoading = false;
        const errorMsg:any = e;
        toast.error(
          <Toast 
            message={`${t('permit_request.list_doc.failed_delete_file')} ${newDynamicReqDocs[idx].attachments[idxC]?.file?.name}`} 
            detailedMessage={`${transformError(errorMsg?.data).message}`} 
          />);
      });
    } else {
      newDynamicReqDocs[idx].attachments?.splice(idxC, 1);
      form.setFieldsValue({
        ...form,
        actions: newDynamicReqDocs
      });
    }
  };

  const delReqDocsExisting = async (idx: any, idxC: any) => {
    let newDynamicReqDocs = [...form.getFieldsValue()?.actions];
    const idDocs = newDynamicReqDocs[idx].existing[idxC].id;
    if (idDocs) {
      newDynamicReqDocs[idx].existing[idxC].isLoading = true;
      await delTemplateAttachment(idDocs).unwrap().then((resp: any) => {
        newDynamicReqDocs[idx].existing?.splice(idxC, 1);
        form.setFieldsValue({
          ...form,
          actions: newDynamicReqDocs
        });
      }).catch((e:any) => {
        newDynamicReqDocs[idx].existing[idxC].isLoading = false;
        const errorMsg:any = e;
        toast.error(
          <Toast 
            message={`${t('permit_request.list_doc.failed_delete_file')} ${newDynamicReqDocs[idx].existing[idxC]?.file?.name}`} 
            detailedMessage={`${transformError(errorMsg?.data).message}`} 
          />);
      });
    } else {
      newDynamicReqDocs[idx].existing?.splice(idxC, 1);
      form.setFieldsValue({
        ...form,
        actions: newDynamicReqDocs
      });
    }
  };

  const handleCompleteAction = async (id: string) => {
    confirm({
      title: t('permit_monitoring.obligation.complete_action'),
      icon: <ExclamationCircleFilled />,
      okText: t('manage_group.yes_btn'),
      okType: 'danger',
      cancelText: t('manage_group.no_btn'),
      async onOk() {
        await postCompleteAction(id).unwrap().then(() => {
          toast.success(
            <Toast
              message={t('general.success_text')}
              detailedMessage={`${t('permit_monitoring.obligation.success_complete_action')}`}
            />
          );
          fetchDetail(obligationId);
        })
          .catch(() => {
            toast.error(
              <Toast
                message={t('general.error_text')}
                detailedMessage={t('permit_monitoring.obligation.error_complete_action')}
              />
            );
          });
      },
      onCancel() { },
    });
  };

  const handleRevise = (record: any, index: number) => {
    const pic = optionsList?.[index]?.find((opt: any) => opt.employeeId === record.pic_emp_id);
    setDataRevise((prev: any) => ({
      ...prev,
      pic: pic?.fullName || '-',
      name: record?.name,
      id: record?.id || '',
    }));
    setOpenModalRevise(true);
  };

  useEffect(() => {
    if(obligationDetail){
      fetchOptionList(obligationDetail.actions);
      form.setFieldsValue(obligationDetail);
    }
    else{
      form.setFieldsValue(initialForm);
    }
  }, [obligationDetail]);

  useEffect(() => {
    if (obligationId) {
      fetchDetail(obligationId);
    }
  }, [obligationId]);

  useEffect(() => {
    if (id) {
      fetchDetailProductDoc(id);
    }
  }, [id]);

  useEffect(() => {
    if (isErrorDetail) {
      const errorMsg:any = errorDetail;
      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
    }
  }, [isErrorDetail]);

  useEffect(() => {
    setIsCrm(user?.roles?.some((v: any) => LIST_ROLE_CRM.includes(v.name)));
  }, []);

  return (
    <Spin tip="Loading" size="large" spinning={isLoadingGetDetail}>
      <div>
        <div className='flex gap-1 items-center mb-4 cursor-pointer text-sm text-[#939aa5]' 
          onClick={backToTabObligation}
        >
          <FaChevronLeft />
          <span>
            {t('general.back')}
          </span>
        </div>
        <Form form={form} onFinish={onFinish} requiredMark={false}>
          <div className="flex justify-between items-center mb-4">
            <span className="font-bold text-lg">
              {t('permit_name.obligation.edit_obligation')}
            </span>
          </div>
          <div className="mb-5">
            <div className=" bg-white rounded border-[#e4e7ea] border-2">
              <div className="bg-[#eceef0] p-3 flex items-center gap-4">
                <Form.Item
                  name={'name'}
                  rules={[
                    {
                      required: true,
                      message: `${t('permit_name.obligation.obligation_name')} ${t('general.required_field')}`,
                    },
                  ]}
                  label={t('permit_name.obligation.obligation_name')}
                  className="m-0 font-bold flex-grow"
                >
                  <Input
                    className="font-normal m-0"
                    placeholder={t('permit_name.obligation.obligation_name_placeholder') || ''}
                    disabled={obligationDetail?.is_from_permit_name}
                  />
                </Form.Item>
              </div>
              <div className='p-5'>
                <div className="flex justify-between items-center mb-4">
                  <Button
                    className="bg-[#55A853] text-white font-bold flex gap-1 items-center"
                    onClick={addAction}
                  >
                    <PlusOutlined className='font-bold' />
                    <span>{t('permit_name.obligation.add_action')}</span>
                  </Button>
                </div>
                <Form.List
                  name={'actions'}
                >
                  {(actionFields) => (
                    <div className="flex flex-col gap-6">
                      {actionFields.map(
                        (
                          {
                            key: actionKey,
                            name: actionName,
                            ...restActionField
                          },
                          actionIndex
                        ) => (
                          <div key={actionKey} className='border-2 border-[#e4e7ea] rounded p-4'>
                            <div className="flex flex-col md:flex-row md:items-center gap-2 mdgap-4">
                              <Form.Item
                                {...restActionField}
                                name={[actionName, 'name']}
                                label={t('permit_monitoring.obligation.obligation_action')}
                                className="flex-grow"
                                labelCol={{ span: 24 }}
                                rules={[{ required: true, message: `${t('permit_monitoring.obligation.obligation_action')} ${t('general.required_field')}` }]}
                              >
                                <TextArea
                                  autoSize
                                  placeholder={t('permit_name.obligation.action_placeholder') || ''}
                                  disabled={
                                    obligationDetail?.actions?.[actionIndex]?.is_from_permit_name ||
                                    form.getFieldsValue()?.actions?.[actionKey]?.status?.toLowerCase() === 'completed' ||
                                    obligationDetail?.actions?.[actionIndex]?.is_pic
                                  }
                                />
                              </Form.Item>
                              <Form.Item
                                {...restActionField}
                                name={[actionName, 'pic_emp_id']}
                                label={t('permit_monitoring.obligation.pic')}
                                className="w-full md:w-96"
                                labelCol={{ span: 24 }}
                                rules={[{ required: true, message: `${t('permit_monitoring.obligation.pic')} ${t('general.required_field')}` }]}
                              >
                                <Select
                                  showSearch
                                  placeholder={t('permit_monitoring.obligation.pic_placeholder') || ''}
                                  filterOption={false}
                                  onSearch={(value: any) => fetchUserHris(value, actionIndex)}
                                  notFoundContent={isLoadingUserHris ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                                  disabled={
                                    form.getFieldsValue()?.actions?.[actionKey]?.status?.toLowerCase() === 'completed' ||
                                    obligationDetail?.actions?.[actionIndex]?.is_pic
                                  }
                                >
                                  { optionsList?.[actionIndex]?.length && optionsList?.[actionIndex]?.map((v: any) => {
                                    return (
                                      <Select.Option key={v.employeeId} value={v.employeeId} label={v.fullName}>
                                        <div>{v.fullName}</div>
                                        <div className='text-gray-400 text-xs'>{v.emailOffice}</div>
                                        <div className='text-gray-400 text-xs'>{v.workLocation} {v.departmentName || '-'}</div>
                                      </Select.Option>
                                    );
                                  })
                                  }
                                </Select>
                              </Form.Item>
                              <Form.Item
                                {...restActionField}
                                name={[actionName, 'status']}
                                label={t('permit_monitoring.obligation.status')}
                                className="w-full md:w-40"
                                labelCol={{ span: 24 }}
                                rules={[{ required: true, message: `${t('permit_monitoring.obligation.status')} ${t('general.required_field')}` }]}
                              >
                                <Select
                                  placeholder={t('permit_monitoring.obligation.status_placeholder') || ''}
                                  options={STATUS_OBLIGATION}
                                  disabled={
                                    form.getFieldsValue()?.actions?.[actionKey]?.status?.toLowerCase() === 'completed' ||
                                    obligationDetail?.actions?.[actionIndex]?.is_pic
                                  }
                                />
                              </Form.Item>
                              <Form.Item
                                {...restActionField}
                                name={[actionName, 'due_date']}
                                label={t('permit_monitoring.obligation.due_date')}
                                className="w-full md:w-40"
                                labelCol={{ span: 24 }}
                                rules={[{ required: true, message: `${t('permit_monitoring.obligation.due_date')} ${form.getFieldsValue()?.actions?.[actionKey]?.name || ''} ${t('general.required_field')}` }]}
                              >
                                <DatePicker
                                  className='w-full'
                                  placeholder={t('permit_monitoring.obligation.due_date_placeholder') || ''}
                                  format={'DD-MMM-YYYY'}
                                  allowClear={false}
                                  disabled={
                                    form.getFieldsValue()?.actions?.[actionKey]?.status?.toLowerCase() === 'completed' || 
                                    obligationDetail?.actions?.[actionIndex]?.is_pic
                                  }
                                />
                              </Form.Item>
                              {(!obligationDetail?.actions?.[actionIndex]?.is_pic && form.getFieldsValue()?.actions?.[actionKey]?.status?.toLowerCase() === 'completed') && (
                                <Button
                                  type="text"
                                  className="bg-[#55A853] text-white font-bold mt-2 md:mt-0"
                                  onClick={() => handleRevise(obligationDetail?.actions?.[actionIndex], actionIndex)}
                                >
                                  {t('general.revise')}
                                </Button>
                              )}
                              {
                                obligationDetail?.actions?.[actionIndex]?.is_pic && 
                                (form.getFieldsValue()?.actions?.[actionIndex]?.existing?.length > 0 ||
                                form.getFieldsValue()?.actions?.[actionIndex]?.attachments?.length > 0) &&
                                obligationDetail?.actions?.[actionIndex]?.status === 'In Progress' && (
                                  <Button
                                    type="text"
                                    className="bg-[#55A853] text-white font-bold mt-2 md:mt-0"
                                    onClick={() => handleCompleteAction(obligationDetail?.actions?.[actionIndex]?.id)}
                                    disabled={isLoadingCompleteAction}
                                  >
                                    {t('general.complete_btn')}
                                  </Button>
                                )}
                              {
                                actionFields.length > 1 && 
                                !obligationDetail?.actions?.[actionIndex]?.is_from_permit_name &&
                                form.getFieldsValue()?.actions?.[actionIndex]?.existing?.length <= 0 &&
                                form.getFieldsValue()?.actions?.[actionIndex]?.attachments?.length <= 0 &&
                                !(form?.getFieldsValue().actions?.[actionKey]?.status?.toLowerCase() === 'completed') && 
                                (
                                  <div className='order-first md:order-last flex justify-end'>
                                    <DeleteFilled
                                      className={
                                        'text-[20px] text-[#f5222d] pb-[5px] cursor-pointer mt-2 md:mt-0'
                                      }
                                      onClick={() => removeAction(actionIndex)}
                                    />
                                  </div>
                                )
                              }
                            </div>
                            <Form.Item
                              className='mt-3'
                              name={[actionName, 'attachmentFileData']}
                              labelCol={{ span: 24 }}
                              label={`${t('permit_request.list_doc.attach_label')} (${t('permit_request.product_doc.accept_5_files')})`}
                            >
                              <Dragger
                                multiple
                                className='font-normal'
                                customRequest={(opt) => uploadImageReqDocs(opt, actionKey)}
                                showUploadList={false}
                                beforeUpload={beforeUploadFile}
                                fileList={form.getFieldsValue()?.actions?.[actionKey]?.attachmentFileData?.fileList}
                                disabled={obligationDetail?.actions?.[actionIndex]?.status === 'Completed'}
                              >
                                <div className='flex flex-col items-center gap-2'>
                                  <BsCloudUpload className='text-4xl text-[#55A853]' />
                                  <div>
                                    <span>{t('permit_request.list_doc.attach_two_placeholder')}
                                      <span className='font-bold text-[#55A853] underline underline-offset-2'>
                                        {t('permit_request.list_doc.browser_txt')}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </Dragger>
                            </Form.Item>
                            <div className='mt-5 grid grid-cols-3 md:grid-cols-5 xl:grid-cols-7 gap-5'>
                              {
                                form.getFieldsValue()?.actions?.[actionKey]?.existing?.map((dataC: any, idxC: any) => (
                                  <div key={idxC}>
                                    <div className={`border ${getBorderAttachmentStep(3)} rounded-md p-2`}>
                                      {obligationDetail?.actions?.[actionIndex]?.status !== 'Completed' && (
                                        <div className='flex justify-end'>
                                          {
                                            dataC?.isLoading ? (<Spin></Spin>) : (
                                              <CloseOutlined
                                                className={'text-[#ED3F3F] text-bold cursor-pointer'}
                                                onClick={() => delReqDocsExisting(actionKey, idxC)}
                                              />
                                            )
                                          }
                                        </div>
                                      )}
                                      <div className='cursor-pointer'>
                                        <div key={idxC} className='w-full flex justify-center'>
                                          <FileOutlined className={`text-[30px] text-[${getColorAttachmentStep(3)}]`} onClick={() => downloadFileUrl(dataC?.attachment?.downloadUrl)} />
                                        </div>
                                        <p className='text-center mt-3 text-[10px] text-[#8B849B] truncate'>
                                          {dataC?.attachment?.name}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              }
                              {
                                form.getFieldsValue()?.actions?.[actionKey]?.attachments?.map((dataC: any, idxC: any) => (
                                  <div key={idxC}>
                                    <div className={`border ${getBorderAttachmentStep(dataC?.step)} rounded-md p-2`}>
                                      {obligationDetail?.actions?.[actionIndex]?.status !== 'Completed' && (
                                        <div className='flex justify-end'>
                                          {
                                            dataC?.isLoading ? (<Spin></Spin>) : (
                                              <CloseOutlined
                                                className={'text-[#ED3F3F] text-bold cursor-pointer'}
                                                onClick={() => delReqDocs(actionKey, idxC)}
                                              />
                                            )
                                          }
                                        </div>
                                      )}
                                      <div className='cursor-pointer'>
                                        <div key={idxC} className='w-full flex justify-center'>
                                          <FileOutlined className={`text-[30px] text-[${getColorAttachmentStep(dataC?.step)}]`} onClick={() => downloadFile(dataC?.file)} />
                                        </div>
                                        <p className='text-center mt-3 text-[10px] text-[#8B849B] truncate'>
                                          {dataC?.step === 1 ? t('permit_request.list_doc.uploading') : dataC?.step === 2 ? t('permit_request.list_doc.failed') : dataC?.file?.name}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              }
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  )}
                </Form.List>
              </div>
            </div>
          </div>

          <div className='bg-[#d7d7d7] h-[1px] mb-4'/>
          <div className="flex justify-between items-center">
            <Button
              className='cancel-green bg-[#E5EDE5] text-[#55A853] border-[#55A853]'
              htmlType="button"
              onClick={backToTabObligation}
            >
              {t('general.cancel_btn') }
            </Button>
            {isCrm && (
              <Button
                type="text"
                className="bg-[#55A853] text-white font-bold"
                htmlType="submit"
                disabled={isLoadingSubmitObligation}
              >
                {t('general.save_btn')}
              </Button>
            )}
          </div>
        </Form>
      </div>

      <ModalRevise
        open={openModalRevise}
        setOpen={setOpenModalRevise}
        data={dataRevise}
        callbackSuccess={() => fetchDetail(obligationId)}
      />
    </Spin>
  );
};

export default PermitMonitoringCrmObligationEditForm;
