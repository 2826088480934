import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import BarChart from '../BarChart';
import Toast from 'components/Toast';
import { transformError } from 'utils/ErrorTransformer';
import { toast } from 'react-toastify';
import { Spin } from 'antd';
import {
  useComplianceStatusMutation,
  useComplianceActivityMutation,
  useComplianceStatusPerTypeMutation
} from 'api/Dashboard';

const CrmTab = () => {
  const { id, year } = useParams();
  const { t } = useTranslation();
  const [complianceStatusQuartal, setComplianceStatusQuartal] = useState([]);
  const [complianceStatus, setComplianceStatus] = useState({
    colors: ['#71B670', '#E3E31D', '#F5222D'],
    series: [],
    categories: []
  });
  const [complianceActivity, setComplianceActivity] = useState({
    colors: ['#4185F5', '#EB4434', '#FABE01', '#35A94C'],
    series: [],
    categories: []
  });

  const [getComplianceStatus, {
    error: errorGetComplianceStatus,
    isError: isErrorGetComplianceStatus,
    isLoading: isLoadingGetComplianceStatus
  }] = useComplianceStatusMutation();
  
  const [getComplianceActivity, {
    error: errorGetComplianceActivity,
    isError: isErrorGetComplianceActivity,
    isLoading: isLoadingGetComplianceActivity
  }] = useComplianceActivityMutation();
  
  const [getComplianceStatusPerTypeMutation, {
    error: errorGetComplianceStatusPerTypeMutation,
    isError: isErrorGetComplianceStatusPerTypeMutation,
    isLoading: isLoadingGetComplianceStatusPerTypeMutation
  }] = useComplianceStatusPerTypeMutation();

  const initialDashboard = () => {
    const payload = {
      work_location_id: id,
      year
    };

    fetchComplianceStatusQuartal(payload);
    fetchComplianceStatus(payload);
    fetchComplianceActivity(payload);
  };

  const fetchComplianceStatusQuartal = async (payload:any) => {
    const data = await getComplianceStatusPerTypeMutation(payload).unwrap();
    const newData = data.map((el:any) => ({
      ...el,
      data: {
        ...el.data,
        colors: ['#52C41A', '#E3E31D', '#F5222D'],
      }
    }));
    setComplianceStatusQuartal(newData);
  };

  const fetchComplianceStatus = async (payload:any) => {
    const data = await getComplianceStatus(payload).unwrap();
    setComplianceStatus({
      ...complianceStatus,
      series: data?.series,
      categories: data?.categories
    });
  };

  const fetchComplianceActivity = async (payload:any) => {
    const data = await getComplianceActivity(payload).unwrap();
    setComplianceActivity({
      ...complianceActivity,
      series: data?.series,
      categories: data?.categories
    });
  };

  useEffect(() => {
    initialDashboard();
  }, []);

  useEffect(() => {
    if (isErrorGetComplianceStatus){
      const errorMsg:any = errorGetComplianceStatus;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorGetComplianceStatus]);
  
  useEffect(() => {
    if (isErrorGetComplianceActivity){
      const errorMsg:any = errorGetComplianceActivity;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorGetComplianceActivity]);
  
  useEffect(() => {
    if (isErrorGetComplianceStatusPerTypeMutation){
      const errorMsg:any = errorGetComplianceStatusPerTypeMutation;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorGetComplianceStatusPerTypeMutation]);

  return(
    <div className='grid lg:grid-cols-2 gap-6 my-5 overflow-auto'>
      <div>
        <p className='font-bold mb-2'>{t('dashboard.title_chart_1')} {year}</p>
        {
          isLoadingGetComplianceStatus 
            ? <div className='mt-5 flex justify-center'>
              <Spin></Spin>
            </div>
            : <BarChart data={complianceStatus} />
        }
      </div>
      <div>
        <p className='font-bold mb-2'>{t('dashboard.title_chart_2')} {year}</p>
        {
          isLoadingGetComplianceActivity 
            ? <div className='mt-5 flex justify-center'>
              <Spin></Spin>
            </div>
            :<BarChart data={complianceActivity} />
        }
      </div>
      {
        isLoadingGetComplianceStatusPerTypeMutation ? <Spin></Spin> : 
          complianceStatusQuartal.map((el:any) => (
            <div>
              <p className='font-bold mb-2'>{t('dashboard.title_chart_1')} {t('dashboard.title_period')} {el.name} {year}</p>
              <BarChart data={el.data}/>
            </div>
          ))
      }
    </div>
  );
};

export default CrmTab;