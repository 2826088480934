import { Button, Form, Modal, Select, Spin, DatePicker, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import Toast from 'components/Toast';
import { toast } from 'react-toastify';
import { OptionType } from 'constant/Types/OptionType';
import { useAppSelector } from 'store/Hooks';
import dayjs from 'dayjs';
import { useStatusPermitMonitoringMutation } from 'api/PermitMonitoring';
import { useWorklocSearchMutation } from 'api/Options';
import { PERMIT_TIER_TYPE } from 'constant';

const ModalFilter = (props: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const { modalAction, filter, filterAction } = props;
  const { t } = useTranslation();
  const [statuses, setStatuses] = useState<OptionType[]>([]);
  const [locations, setLocations] = useState<OptionType[]>([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [isExpiredDateValid, setIsExpiredDateValid] = useState(false);

  const listProcessedBy = [
    {
      value: 'ER Jakarta',
      label: 'ER Jakarta',
    },
    {
      value: 'Mine Site',
      label: 'Mine Site',
    }
  ];

  const listRoleAdmin = [
    'Administrator',
    'Super Admin',
    'ER Admin'
  ];

  const [statusList] = useStatusPermitMonitoringMutation();
  const [locationList, {
    isLoading: isLoadinglocation
  }] = useWorklocSearchMutation();

  const fetchStatus = async (value: any) => {
    if (value.length > 2 || !value) {
      let rows = await statusList({}).unwrap();
      const newDataStatus = rows.map((el: any) => {
        if (el) {
          const transformStatus = (el.value.toLowerCase() === 'not active' ? 'Inactive' : el.value);
          return {
            label: transformStatus.toUpperCase(),
            value: el.value
          };
        }
        return null;
      });

      setStatuses(newDataStatus.filter((v: any) => v.label));
    }
  };

  const fetchLocations = async (value: any) => {
    if (value.length > 2 || !value) {
      const payload = {
        search: value ?? '',
        searchFields: 'name'
      };
      let { rows } = await locationList(payload).unwrap();
      const newDataWorklocation = rows.map((el: any) => {
        if (el) {
          return {
            label: el?.name,
            value: el?.id
          };
        }
        return null;
      });

      setLocations(newDataWorklocation.filter((v: any) => v.label));
    }
  };

  const validateExpiredDate = (source:string, data:any) => {
    switch(source){
      case 'from':
        filter.setFilter({...filter.filter, expired_date_from: data});
        break;
      case 'to':
        filter.setFilter({...filter.filter, expired_date_to: data});
        break;
      default:
        break;
    }

    const expiredDateFrom = source === 'from' ? data : filter.filter?.expired_date_from;
    const expiredDateTo = source === 'to' ? data : filter.filter?.expired_date_to;
    if (expiredDateFrom && expiredDateTo){
      setIsExpiredDateValid(false);
      const isValid = dayjs(expiredDateTo).isAfter(dayjs(expiredDateFrom));
      if (!isValid) {
        setIsExpiredDateValid(true);
        toast.warning(<Toast message={t('general.warning_text')} detailedMessage={t('permit_tracker.date_error')} />);
      }
    }
  };

  useEffect(() => {
    fetchStatus('');
    fetchLocations('');
    setIsAdmin(user?.roles?.some((v: any) => listRoleAdmin.includes(v.name)));
    setIsUser(user?.roles?.some((v: any) => v.name === 'User'));
  }, []);
  
  return (
    <Modal
      title={null}
      open={modalAction.isModalOpen}
      footer={null}
      width={500}
      onOk={() => modalAction.setIsModalOpen(false)}
      onCancel={() => modalAction.setIsModalOpen(false)}
    >
      <div className="bg-white p-[10px] rounded-lg">
        <p className='font-bold text-[16px] mb-5'>
          {t('manage_group.filter_label')}
        </p>
        <Form layout="vertical">
          <div className='grid grid-cols-1 gap-1'>
            <div className='col-span-1 mb-3 grid grid-cols-1 lg:grid-cols-12'>
              <Form.Item
                label={t('permit_tracker.expired_date')}
                className='font-bold col-span-5'
              >
                <DatePicker
                  className={`w-full font-normal ${isExpiredDateValid ? 'border-red' : ''}`}
                  placeholder={t('permit_tracker.expired_date_from_placeholder') || ''}
                  format={'DD-MMM-YYYY'}
                  value={filter.filter.expired_date_from}
                  onChange={(e) => validateExpiredDate('from', e)}
                />
              </Form.Item>
              <p className='col-span-2 mx-auto my-2 lg:mt-8 font-bold'>{t('permit_tracker.to_txt')}</p>
              <Form.Item
                className='font-bold col-span-5 my-auto'
              >
                <DatePicker
                  className={`w-full font-normal ${isExpiredDateValid ? 'border-red' : ''}`}
                  placeholder={t('permit_tracker.expired_date_to_placeholder') || ''}
                  format={'DD-MMM-YYYY'}
                  value={filter.filter.expired_date_to}
                  onChange={(e) => validateExpiredDate('to', e)}
                />
              </Form.Item>
            </div>
            <div className='col-span-1 mb-3'>
              <Form.Item
                label={t('permit_monitoring.processed_by')}
                className='font-bold'
              >
                <Select
                  allowClear
                  className='font-normal'
                  style={{ width: '100%' }}
                  placeholder={t('permit_monitoring.processed_by_placeholder')}
                  onChange={(e) => filter.setFilter({...filter.filter, processed_by: e})}
                  value={filter.filter.processed_by}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={listProcessedBy}
                />
              </Form.Item>
            </div>
            { 
              (isAdmin || !isUser) && (
                <>
                  <div className='col-span-1 mb-3'>
                    <Form.Item
                      label='Status'
                      className='font-bold'
                    >
                      <Select
                        mode="multiple"
                        allowClear
                        className='font-normal'
                        style={{ width: '100%' }}
                        options={statuses}
                        placeholder={t('manage_group.status_placeholder')}
                        onChange={(e) => filter.setFilter({...filter.filter, status: e})}
                        value={filter.filter.status}
                      />
                    </Form.Item>
                  </div>
                  <div className='col-span-1 mb-3'>
                    <Form.Item
                      className='font-bold'
                      label={t('permit_request.request_information.permit_tier')}
                    >
                      <Select
                        mode='multiple'
                        className='font-normal'
                        allowClear
                        placeholder={t('permit_request.request_information.permit_tier_placeholder')}
                        options={PERMIT_TIER_TYPE}
                        onChange={(e) => filter.setFilter({...filter.filter, permit_tier: e})}
                        value={filter.filter.permit_tier}
                      />
                    </Form.Item>
                  </div>
                  <div className='col-span-1 mb-3'>
                    <Form.Item
                      label={t('permit_monitoring.permit_location')}
                      className='font-bold'
                    >
                      <Select
                        mode="multiple"
                        allowClear
                        className='font-normal'
                        style={{ width: '100%' }}
                        options={locations}
                        placeholder={t('permit_monitoring.permit_location_placeholder')}
                        onChange={(e) => filter.setFilter({...filter.filter, permit_location_id: e})}
                        value={filter.filter.permit_location_id}
                        onSearch={fetchLocations}
                        filterOption={false}
                        notFoundContent={isLoadinglocation ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                      />
                    </Form.Item>
                  </div>
                </> 
              )
            }
            <div className='grid grid-cols-2 gap-4 content-end mt-4'>
              <Button
                type='text'
                className='bg-white border-[#55A853] text-[#55A853] font-bold'
                onClick={filterAction.resetFilter}
                disabled={filterAction.isLoadingFilter}
              >
                {filterAction.isLoadingFilter ? <Spin></Spin> : t('manage_group.reset_btn') || ''}
              </Button>
              <Button
                type='text'
                className='bg-[#55A853] text-white font-bold'
                onClick={() => {
                  filterAction.applyFilter();
                  modalAction.setIsModalOpen(false);
                }}
                disabled={filterAction.isLoadingFilter}
              >
                {filterAction.isLoadingFilter ? <Spin></Spin> : t('manage_group.apply_btn') || ''}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ModalFilter;