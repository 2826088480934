import React, { ReactNode } from 'react';
import { FirebaseProvider } from '@useweb/firebase/useFirebase';
import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

import firebaseConfig from './firebase.config';

const firebaseApp = initializeApp(firebaseConfig);

// Messaging is not supported in ios or safari as of 2022
// const messagingIsSupported = await isSupported();
// const messaging = messagingIsSupported ? getMessaging(firebaseApp) : undefined;
let messaging: any;
try {
  messaging = getMessaging(firebaseApp);
} catch {

}

const envIsDev = process.env.NODE_ENV === 'development';
const vapidKey = process.env.REACT_APP_VAPID_KEY;

export default function FirebaseData({ children }: { children: ReactNode }) {
  return (
    <FirebaseProvider
      firebaseConfig={firebaseConfig}
      firebaseApp={firebaseApp}
      envIsDev={envIsDev}
      messaging={messaging}
      messagingOptions={{
        vapidKey,
      }}
    >
      {children}
    </FirebaseProvider>
  );
}