import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import LoadingComponent from 'components/LoadingComponent';

const Projects = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/');
    }, 2000);
  }, []);

  return(
    <LoadingComponent/>
  );
};
  
export default Projects;