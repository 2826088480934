import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

const BarChart = ({ data }:any) => {
  const { t } = useTranslation();

  const state = {
    options: {
      chart: {
        id: 'compliance_status',
      },
      plotOptions: {
        bar: {
          borderRadius: 5,
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        style: {
          colors: ['black']
        },
        offsetY: -15
      },
      xaxis: {
        labels: {
          show: true,
          rotate: 0,
          style: {
            colors: 'black',
            fontSize: '12px',
            fontFamily: 'Noto-Sans, sans-serif',
            fontWeight: 'bold',
          },
        },
        title: {
          text: t('dashboard.quartal'),
          offsetY: -5,
          offsetX: -20
        },
        categories: data?.categories
      },
      yaxis: {
        labels: {
          show: true,
          style: {
            colors: 'black',
            fontSize: '12px',
            fontFamily: 'Noto-Sans, sans-serif',
            fontWeight: 'bold',
          },
        },
      },
      legend: {
        show: true
      },
      colors: data?.colors
    },
    series: data?.series
  };
  
  return (
    <div className="app bg-[#F7F9FB] rounded-lg">
      <div className="row">
        <div className="mixed-chart">
          <Chart
            options={state.options}
            series={state.series}
            type="bar"
            height={300}
          />
        </div>
      </div>
    </div>
  );
};

export default BarChart;