import Icon from '@ant-design/icons';
import { Button, ConfigProvider, Spin, Form, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import { useLoginMutation } from 'api/Auth';
import { transformError } from 'utils/ErrorTransformer';
import Toast from 'components/Toast';
import { useAppDispatch } from 'store/Hooks';
import { getAuthFromDb } from 'store/Auth/Thunk';
import {
  DB_CREDENTIAL_TOKEN,
  DB_CREDENTIAL_USER,
  DB_CREDENTIAL_REFRESH_TOKEN
} from 'constant';
import { useMeMutation } from 'api/User';
import { fetchToken } from 'utils/Firebase';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import * as App from '../../index';
import { FaArrowRight } from 'react-icons/fa';
import ModalLogin from './ModalLogin';
import { loginRequest } from '../../authConfig';
import { useLoginAzureMutation } from 'api/Auth';
import { AuthAzure } from 'constant/Types/AuthType';

const Login = () => {
  const { msalInstance } = App;
  const { instance } = useMsal();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isCalledMe, setIsCalledMe] = useState(false);
  const accounts = msalInstance.getAllAccounts();
  const isAuthenticated = useIsAuthenticated();
  const [login, {
    error,
    isSuccess,
    isError
  }] = useLoginMutation();
  const [loginAzure, {
    error:errorLoadingAzure,
    isError:isErrorLoadingAzure
  }] = useLoginAzureMutation();
  const [getMe, {
    isError: isErrorMe
  }] = useMeMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButtonAzure, setIsLoadingButtonAzure] = useState(false);
  const [isLoadingAzure, setIsLoadingAzure] = useState(false);
  const [isPrepare, setIsPrepare] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [form] = Form.useForm();
  const [tokenInfo, setTokenInfo] = useState({
    isTokenFound: false,
    currentToken: '',
    errToken: {
      message: '',
      code: ''
    },
    isFirstTime: true,
  });

  const azureSvg = () => (
    <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.3125 6.13879L8.2 23.4013L2.5 23.3388L8.8625 12.3888L16.3125 6.13879ZM17.1875 7.50129L27.5 25.5388H8.425L20.05 23.4638L13.9625 16.2263L17.1875 7.50129Z" fill="white"/>
    </svg>

  );

  const handleModalLogin = () => {
    form.setFieldsValue({
      username: '',
      password: '',
      domain: 'banpuindo.co.id',
      fcm_token: ''
    });
    setOpenModal(true);
  };
  
  const onLoginFailed = () => {
    setIsLoading(false);
    toast.error(<Toast message={t('general.error_text')} detailedMessage={t('login.required_all_field')} />);
  };

  const onLogin = async () => {
    setIsLoading(true);
    const payload = {
      ...form.getFieldsValue(),
      fcm_token: tokenInfo.currentToken
    };

    await login(payload).unwrap();
    setTimeout(async () => {
      await getMe({}).unwrap();
      setIsCalledMe(true);
    }, 1000);
  };

  const handleLoginAzure = () => {
    setIsLoadingButtonAzure(true);
    instance.loginRedirect(loginRequest).catch((e:any) => {
      toast.error(<Toast message={t('general.error_text')} detailedMessage={e} />);
    });
  };

  const onLoginAzure = async (credential:AuthAzure) => {
    await loginAzure(credential).unwrap().then(async (resp: any) => {
      if (resp) {
        setTimeout(async () => {
          await getMe({}).unwrap().then(() => {
            localStorage.setItem('lang', 'en');
            localStorage.setItem('isLoginAzure', 'true');
            navigate('/my-activity');
          });
        }, 1000);
      } else {
        toast.error(<Toast message={t('general.error_text')} detailedMessage={t('permit_request.list_doc.failed')} />);
        localStorage.clear();
        navigate('/');
      }
    });
  };

  const dispatchTokenState = (callback: Function) => {
    dispatch(getAuthFromDb(DB_CREDENTIAL_TOKEN))
      .unwrap()
      .then((data) => {
        dispatch(getAuthFromDb(DB_CREDENTIAL_REFRESH_TOKEN)).unwrap();
        callback();
        setTimeout(() => {
          setIsPrepare(false);
        }, 10);
      })
      .catch(() => {
        setIsPrepare(false);
      });
  };

  const dispatchUserState = () => {
    dispatch(getAuthFromDb(DB_CREDENTIAL_USER))
      .unwrap()
      .then((data) => {
        if (!isEmpty(data)){
          navigate('/my-activity');
        }
      })
      .catch(() => {
        setIsPrepare(false);
      });
  };

  const handleLoginAzureSilent = () => {
    instance
      .ssoSilent(loginRequest)
      .then(() => {
        if (accounts.length === 0){
          return;
        }

        instance
          .acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
            forceRefresh: false
          })
          .then(response => {
            // for debugging you can console `response.accessToken`
            onLoginAzure({
              fcm_token: tokenInfo.currentToken
            });
          })
          .catch(error => {
            // for debugging you can console `error`
          });
      })
      .catch(error => {
        // for debugging you can console `error`
      });
  };

  const handleAutoLogin = () => {
    let isAutoLogin = process.env.REACT_APP_AUTO_LOGIN || '0';
    let isFlag = 1;
    if (isAutoLogin === '1' && !isAuthenticated && isFlag === 1){
      handleLoginAzureSilent();
      isFlag++;
    } else return;
  };

  useEffect(() => {
    dispatchTokenState(dispatchUserState);
    fetchToken(setTokenInfo, tokenInfo);
    handleAutoLogin();
  }, []);

  useEffect(() => {
    if (!tokenInfo.isFirstTime){
      if (isLoadingAzure){
        onLoginAzure({
          fcm_token: tokenInfo.currentToken
        });
        return;
      }

      form.setFieldsValue({...form.getFieldsValue(), fcm_token: tokenInfo.currentToken});
    }
  }, [tokenInfo]);

  useEffect(() => {
    if (accounts.length > 0 && !isAuthenticated) {
      setIsLoadingAzure(true);
      fetchToken(setTokenInfo, tokenInfo);
    }
  }, [accounts]);

  useEffect(() => {
    if (isError) {
      setIsLoading(false);

      const errorMsg:any = error;
      if (typeof errorMsg?.code === 'string' && errorMsg?.code?.toLowerCase() === 'fetch_error'){
        toast.error(<Toast message={t('general.error_text')} detailedMessage={t('login.constant_err.server_down')} />);
        return;
      }

      if (errorMsg?.message?.code === 40330 || errorMsg?.message?.code === 40340  || errorMsg?.message?.code === 40103){
        toast.warning(<Toast message={'Warning'} detailedMessage={`${transformError(errorMsg?.message).message}`} />);
        return;
      }

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.message).message}`} />);
    }
  }, [isError]);

  useEffect(() => {
    if (isErrorLoadingAzure) {
      const errorMsg:any = errorLoadingAzure;
      if (typeof errorMsg?.code === 'string' && errorMsg?.code?.toLowerCase() === 'fetch_error'){
        toast.error(<Toast message={t('general.error_text')} detailedMessage={t('login.constant_err.server_down')} />);
        return;
      }

      if (errorMsg?.message?.code === 40330 || errorMsg?.message?.code === 40340  || errorMsg?.message?.code === 40103){
        toast.warning(<Toast message={'Warning'} detailedMessage={`${transformError(errorMsg?.message).message}`} />);
        return;
      }

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.message).message}`} />);
      localStorage.clear();
      navigate('/');
    }
  }, [isErrorLoadingAzure]);

  useEffect(() => {
    setIsLoading(false);
  }, [isErrorMe]);

  useEffect(() => {
    if (isSuccess && isCalledMe) {
      setTimeout(async () => {
        setIsLoading(false);
        localStorage.setItem('lang', 'en');
        navigate('/my-activity');
      }, 1000);
    }
  }, [isCalledMe]);
  
  return !isPrepare ? (
    <div className='h-screen bg-login lg:grid lg:grid-cols-10'>
      <img 
        src='images/img_login.svg' 
        alt='img-login' 
        className='h-screen m-auto hidden lg:block lg:w-3/4 xl:w-4/6 lg:col-span-5 xl:col-span-6'
      />
      <div className='lg:col-span-5 xl:col-span-4 my-auto'>
        <div className='m-auto pt-5 lg:m-0 lg:pt-0 w-3/4'>
          <img
            src='images/img_logo_itm.svg' 
            alt='img-login'
            className='w-1/3 md:w-1/5'
          />
          <p className='mt-[40px] text-center lg:text-left font-bold md:font-normal text-[20px] md:text-[28px] xl:text-[30px]'>{t('login.welcome_text_two')}</p>
          <img 
            src='images/img_character_login.svg' 
            alt='img-character-login' 
            className='hidden lg:block mt-[30px] mx-auto w-4/6'
          />
          <img 
            src='images/img_login.svg' 
            alt='img-character-login' 
            className='lg:hidden mt-[30px] mx-auto w-8/9 md:w-2/4'
          />
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  colorPrimaryHover: '#1e3f1f',
                },
              },
            }}>
            <Button 
              className='mt-[40px] w-full h-[54px] rounded-xl text-[16px] text-white bg-gradient-to-r from-[#8DC58C] to-[#54A853]'
              onClick={handleLoginAzure}
            >
              {isLoadingButtonAzure ? <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: 'white',
                  },
                }}
              >
                <Spin />
              </ConfigProvider> : <div className='flex justify-center'>
                <Icon component={azureSvg}/>
                <span className='my-auto ml-5'>
                  {t('login.btn_sign_in_azure')}
                </span>
              </div>}
            </Button>
            <p 
              className='mt-[30px] text-center text-[#54A853] font-bold flex justify-center cursor-pointer hover:text-[#1e3f1f]'
              onClick={handleModalLogin}
            >
              {t('login.text_login_classic')}
              <FaArrowRight className='ml-2 my-auto' />
            </p>
          </ConfigProvider>
        </div>
      </div>
      <ModalLogin
        openModal={openModal}
        setOpenModal={setOpenModal}
        onLogin={onLogin}
        isLoading={isLoading}
        form={form}
        onLoginFailed={onLoginFailed}
      />
      {
        isLoadingAzure ? <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#54A853',
            },
          }}
        >
          <Modal
            open={true}
            centered
            width={430}
            footer={null}
            maskClosable={false}
            closable={false}
          >
            <div className='h-[300px] flex justify-center'>
              <Spin tip={t('login.text_loading_azure')} size='large' className='self-center'/>
            </div>
          </Modal>
        </ConfigProvider> : <></>
      }
    </div>
  ) : <></>;
};

export default Login;