import { useTranslation } from 'react-i18next';
import { Button, Spin, Avatar } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import DataTable from 'components/DataTable';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { EyeOutlined, FolderFilled, SyncOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router';
import ModalFilter from 'pages/DocumentLibrary/PermitRequest/ModalFilter';
import { useListPermitRequestPaginationMutation } from 'api/PermitRequest';
import { ListPayloadType, PermitRequestListType } from 'constant/Types/PermitRequest';
import Toast from 'components/Toast';
import { transformError } from 'utils/ErrorTransformer';
import { hasPermission } from 'utils/Permission';
import moment from 'moment-timezone';
import ModalHistory from 'pages/DocumentLibrary/PermitRequest/ModalHistory';
import CardList from 'components/CardList';
import { store } from 'store';

const ViewOthers = () => {
  const [permitRequestList, {
    error: errorPermitRequestList,
    isError: isErrorPermitRequestList,
    isLoading
  }] = useListPermitRequestPaginationMutation();

  const user = store.getState().auth.user;
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const navigate = useNavigate();
  const [data, setData] = useState({
    total: 0,
    permitRequests: []
  });
  const [dataMobile, setDataMobile] = useState({
    total: 0,
    permitRequests: []
  });
  const [payload, setPayload] = useState<ListPayloadType>({
    lang: getLanguage(),
    page: 1,
    pageSize: 50,
    search: '',
    sort: '-last_update',
    work_location_id: [],
    permit_type_id: [],
    work_location_name: [],
    status: [
      'Waiting for SUPERIOR to approve',
      'Waiting for ER HEAD to approve',
      'Waiting for doc revise',
      'Assigned',
      'Open',
      'Returned',
      'Processed'
    ],
    pic_id: []
  });
  const [payloadMobile, setPayloadMobile] = useState<ListPayloadType>({
    lang: getLanguage(),
    page: 1,
    pageSize: 50,
    search: '',
    searchFields: ['status permit_name request_number description'],
    sort: '-last_update',
    work_location_id: [],
    permit_type_id: [],
    work_location_name: [],
    status: [
      'Waiting for SUPERIOR to approve',
      'Waiting for ER HEAD to approve',
      'Waiting for doc revise',
      'Assigned',
      'Open',
      'Returned',
      'Processed'
    ],
    pic_id: []
  });
  const [filter, setFilter] = useState({
    work_location_id: [],
    permit_type_id: [],
    permit_location_id: [],
    status: [
      'Waiting for SUPERIOR to approve',
      'Waiting for ER HEAD to approve',
      'Waiting for doc revise',
      'Assigned',
      'Open',
      'Returned',
      'Processed'
    ],
    pic_name: []
  });
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenHistory, setIsOpenHistory] = useState(false);
  const [permitRequestId, setPermitRequestId] = useState(null);
  const [canView, setCanView] = useState(false);
  const [onSearchMobile, setOnSearchMobile] = useState(false);
  const [onFilterMobile, setOnFilterMobile] = useState(false);
  const [detailId, setDetailId] = useState({});
  let isEyeClick = false;

  const applyFilter = () => {
    setIsLoadingFilter(true);
    setPayload({
      ...payload,
      page: 1,
      permit_location_id: filter.permit_location_id,
      permit_type_id: filter.permit_type_id,
      work_location_id: filter.work_location_id,
      status: filter.status,
      pic_id: filter.pic_name
    });
    setPayloadMobile({
      ...payloadMobile,
      page: 1,
      permit_location_id: filter.permit_location_id,
      permit_type_id: filter.permit_type_id,
      work_location_id: filter.work_location_id,
      status: filter.status,
      pic_id: filter.pic_name
    });
  };

  const resetFilter = () => {
    setIsLoadingFilter(true);
    setFilter({
      work_location_id: [],
      permit_type_id: [],
      permit_location_id: [],
      status: [
        'Waiting for SUPERIOR to approve',
        'Waiting for ER HEAD to approve',
        'Waiting for doc revise',
        'Assigned',
        'Open',
        'Returned',
        'Processed'
      ],
      pic_name: []
    });
    setPayload({
      ...payload,
      work_location_id: [],
      permit_type_id: [],
      permit_location_id: [],
      status: [
        'Waiting for SUPERIOR to approve',
        'Waiting for ER HEAD to approve',
        'Waiting for doc revise',
        'Assigned',
        'Open',
        'Returned',
        'Processed'
      ],
      pic_id: []
    });
    setPayloadMobile({
      ...payloadMobile,
      page: 1,
      work_location_id: [],
      permit_type_id: [],
      permit_location_id: [],
      status: [
        'Waiting for SUPERIOR to approve',
        'Waiting for ER HEAD to approve',
        'Waiting for doc revise',
        'Assigned',
        'Open',
        'Returned',
        'Processed'
      ],
      pic_id: []
    });
  };

  const fetchList = async () => {
    let convertPayload: any = payload;
    let newPayload: any = {};
    for (const key in convertPayload) {
      if (Array.isArray(convertPayload[key])) {
        if (convertPayload[key].length) {
          newPayload[key] = convertPayload[key];
        }
      } else if (convertPayload[key]) {
        newPayload[key] = convertPayload[key];
      }
    }

    let listPermitRequest = await permitRequestList(newPayload).unwrap();

    listPermitRequest = {
      ...listPermitRequest,
      permitRequests: listPermitRequest?.rows.map((el: any, idx: any) => ({ ...el, key: idx }))
    };
    setData(listPermitRequest);
    setIsLoadingFilter(false);
  };

  const fetchListMobile = async () => {
    let convertPayload: any = payloadMobile;
    let newPayload: any = {};
    for (const key in convertPayload) {
      if (Array.isArray(convertPayload[key])) {
        if (convertPayload[key].length) {
          newPayload[key] = convertPayload[key];
        }
      } else if (convertPayload[key]) {
        newPayload[key] = convertPayload[key];
      }
    }

    newPayload.searchFields = payloadMobile.searchFields;
    let listPermitRequest = await permitRequestList(newPayload).unwrap();
    const newPermitRequest = listPermitRequest?.rows.map((el: any, idx: any) => ({ ...el, key: idx }));
    const concatNewPermitRequest = [...dataMobile.permitRequests, ...newPermitRequest];

    listPermitRequest = {
      ...listPermitRequest,
      permitRequests: (!onSearchMobile && !onFilterMobile) ? concatNewPermitRequest : newPermitRequest
    };

    setOnSearchMobile(false);
    setOnFilterMobile(false);
    setDataMobile(listPermitRequest);
    setIsLoadingFilter(false);
  };

  useEffect(() => {
    const paths = pathname.split('/');
    setCanView(hasPermission(paths[1]).can_view);
  }, []);

  useEffect(() => {
    setIsLoadingFilter(true);
    fetchList();
  }, [payload]);

  useEffect(() => {
    setIsLoadingFilter(true);
    fetchListMobile();
  }, [payloadMobile]);

  useEffect(() => {
    viewCardList();
  }, [dataMobile.permitRequests]);

  useEffect(() => {
    if (!isOpenHistory) {
      isEyeClick = false;
    }
  }, [isOpenHistory]);

  useEffect(() => {
    if (!isModalOpen) {
      setOnFilterMobile(false);
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (isErrorPermitRequestList) {
      const errorMsg: any = errorPermitRequestList;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [errorPermitRequestList, isErrorPermitRequestList]);

  useEffect(() => {
    setPayload({
      ...payload,
      lang: getLanguage()
    });

    setDataMobile({
      total: 0,
      permitRequests: []
    });
    setPayloadMobile({
      ...payloadMobile,
      lang: getLanguage()
    });
  }, [getLanguage()]);

  const columns: ColumnsType<PermitRequestListType> = [
    {
      title: 'Action',
      key: 'action',
      width: 150,
      render: (data, record) => (
        <div>
          {
            canView &&
            <Button
              type='text'
              className='shadow-md shadow-gray-400 rounded-sm mr-3 p-0'
              onClick={() => {
                setIsOpenHistory(true);
                setPermitRequestId(data.id);
                setDetailId(data);
              }}
              disabled={data.status === 'Draft'}
            >
              <EyeOutlined style={{ fontSize: '20px' }} className='text-[#55A853] p-1' />
            </Button>
          }
          {
            canView &&
            <Button
              type='text'
              className='shadow-md shadow-gray-400 rounded-sm mr-3 p-0'
              onClick={() => navigate(`/permit-request/${data.can_edit ? 'edit' : 'detail'}/${data.id}`)}
            >
              <FolderFilled style={{ fontSize: '20px' }} className='text-[#55A853] p-1' />
            </Button>
          }
        </div>
      ),
    },
    {
      title: 'Number',
      dataIndex: 'request_number',
      key: 'request_number',
      sorter: true,
      render: (data, record) => {
        return (<div>{data || '-'}</div>);
      }
    },
    {
      title: 'Permit Name',
      dataIndex: 'permit_name',
      key: 'permit_name',
      sorter: true,
      render: (data, record) => {
        return (<div>{data?.name[getLanguage()]}</div>);
      }
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      sorter: true,
      render: (data, record) => {
        return (<div>{data}</div>);
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (data, record) => {
        return (
          <div
            className={
              `
                ml-3 py-1 px-2  rounded-lg border border-solid text-xs text-center
                ${data === 'Draft' ? 'border-[#3D81DB] text-[#3D81DB] bg-[#3D81DB]/20' : ''}
                ${data?.toLowerCase().includes('waiting') ? 'border-[#F7B648] text-[#F7B648] bg-[#F7B648]/20' : ''}
                ${data?.toLowerCase().includes('approved') ? 'border-[#659711] text-[#659711] bg-[#659711]/20' : ''}
                ${data?.toLowerCase() === 'assigned' ? 'border-[#6552B7] text-[#6552B7] bg-[#6552B7]/20' : ''}
                ${data?.toLowerCase().includes('open') || data?.toLowerCase().includes('processed') ? 'border-[#FF7B31] text-[#FF7B31] bg-[#FF7B31]/20' : ''}
                ${data?.toLowerCase().includes('rejected') ? 'border-[#930B16] text-[#930B16] bg-[#930B16]/20' : ''}
                ${data?.toLowerCase().includes('returned') ? 'border-[#ED3F3F] text-[#ED3F3F] bg-[#ED3F3F]/20' : ''}
              `
            }
          >
            {record?.status_view}
          </div>
        );
      }
    },
    {
      title: 'Last Update',
      dataIndex: 'last_update',
      key: 'last_update',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (data, record) => {
        return (<div>{moment(data).tz(user?.timezone?.identifier).utc(true).format('DD-MMMM-YYYY') || '-'}</div>);
      }
    },
    {
      title: 'ER PIC',
      dataIndex: 'pic',
      key: 'pic',
      sorter: true,
      render: (data, record) => {
        return (<div>{data?.display_name || '-'}</div>);
      }
    }
  ];

  const viewCardList = () => {
    return (
      <Spin tip="Loading" size="large" spinning={isLoading}>
        {
          dataMobile?.permitRequests?.map((el: any, idx: any) => (
            <div key={idx} className="p-0 my-1 bg-white rounded-xl p-4" onClick={() => {
              if (!isEyeClick) {
                navigate(`/permit-request/${el?.can_edit ? 'edit' : 'detail'}/${el?.id}`);
              }
            }}>
              <div className='grid grid-cols-6'>
                <div className='m-auto col-span-1'>
                  {
                    el?.images ? (<img alt=''></img>) : (<Avatar size={40}>PL</Avatar>)
                  }
                </div>
                <div className='col-span-4 pl-4'>
                  <p className='font-bold'>{el?.permit_name?.name[getLanguage()]}</p>
                  <p>{el?.status_view || '-'}</p>
                  <p className='text-[#55A853]'>{el?.pic?.display_name || '-'}</p>
                  <div className='flex justify-between mb-2'>
                    <p className='text-[10px] text-[#808080] my-auto'>{el?.request_number || '-'}</p>
                    <p className='text-[12px] text-[#184BCF] font-bold'>{el?.required_time || '0'} {t('general.weeks_text')}</p></div>
                  <div className='flex text-[#BC31AE]'>
                    <SyncOutlined className='my-auto mr-2' />
                    <p>{el?.last_update ? moment(el?.last_update).tz(user?.timezone?.identifier).utc(true).format('DD-MMM-YYYY') : '-'}</p>
                  </div>
                </div>
                <div className='col-span-1 grid justify-end content-between'>
                  <p className='text-center text-[#1F5BCF] font-bold'>PL</p>
                  <Button
                    type='text'
                    className='shadow-md shadow-gray-400 rounded-sm p-0 bg-white'
                    onClick={() => {
                      isEyeClick = true;
                      setIsOpenHistory(true);
                      setPermitRequestId(el?.id);
                    }}
                  >
                    <EyeOutlined style={{ fontSize: '15px' }} className='text-[#55A853] p-1' />
                  </Button>
                </div>
              </div>
            </div>
          ))
        }
        {
          dataMobile?.permitRequests?.length !== dataMobile?.total ?
            (
              <Button
                type='text'
                className='bg-[#55A853] text-white px-10 w-full mt-4'
                disabled={isLoading}
                onClick={() => {
                  setPayloadMobile({ ...payloadMobile, page: payloadMobile.page + 1 });
                }}
              >
                {isLoading ? <Spin /> : t('general.load_more') || ''}
              </Button>
            ) :
            (<div></div>)
        }
      </Spin>
    );
  };

  return (
    <div className='w-full'>
      <ModalHistory
        id={permitRequestId}
        modalAction={{
          isOpenHistory: isOpenHistory,
          setIsOpenHistory: setIsOpenHistory
        }}
        detail={detailId}
      />
      <ModalFilter
        modalAction={{
          isModalOpen: isModalOpen,
          setIsModalOpen: setIsModalOpen
        }}
        filter={{
          filter: filter,
          setFilter: setFilter
        }}
        filterAction={{
          isLoadingFilter: isLoadingFilter,
          resetFilter: resetFilter,
          applyFilter: applyFilter
        }}
      />
      <div className='md:hidden'>
        <CardList
          payloadMobile={{
            payloadMobile: payloadMobile,
            setPayloadMobile: setPayloadMobile
          }}
          onSearchMobile={{
            onSearchMobile: onSearchMobile,
            setOnSearchMobile: setOnSearchMobile
          }}
          onFilterMobile={{
            onFilterMobile: onFilterMobile,
            setOnFilterMobile: setOnFilterMobile
          }}
          modalOpen={{
            isModalOpen: isModalOpen,
            setIsModalOpen: setIsModalOpen
          }}
          viewCardList={viewCardList}
        />
      </div>
      <div className='max-md:hidden'>
        <DataTable
          columns={columns}
          data={data.permitRequests}
          buttonConfig={[]}
          loading={isLoading}
          totalData={data.total}
          payload={{
            payload: payload,
            setPayload: setPayload
          }}
          modalOpen={{
            isModalOpen: isModalOpen,
            setIsModalOpen: setIsModalOpen
          }}
        />
      </div>
    </div>
  );
};

export default ViewOthers;
