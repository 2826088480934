import {
  FaUserAlt
} from 'react-icons/fa';
import { FiFileText, FiHome } from 'react-icons/fi';
import { BsListCheck } from 'react-icons/bs';

export const menus = [
  {
    id: 1,
    name: 'Home',
    url: '/dashboard',
    icon: () => 
      <FiHome
        size="20px"
        className='text-white'
      />
  },
  {
    id: 2,
    name: 'Activity',
    url: '/my-activity',
    icon: () =>
      <BsListCheck
        size="20px"
        fill="#fff"
      />
  },
  {
    id: 3,
    name: 'Tracker',
    url: '/tracker',
    icon: () =>
      <FiFileText
        size="20px"
        className='text-white'
      />
  },
  {
    id: 4,
    name: 'Log',
    url: '/user-log',
    icon: () =>
      <FaUserAlt
        size="20px"
        fill="#fff"
      />
  }
];