import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { truncateText } from 'utils/Utility';
import Parser from 'html-react-parser';

const Simple = (props:any) => {
  const { data, limit } = props;
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  return(
    <div>
      <div style={{ whiteSpace: 'pre-wrap' }}>
        {!isExpanded ? Parser(truncateText(data, limit)) : Parser(data)}
      </div>
      <p className='text-[#54A853] cursor-pointer' onClick={() => setIsExpanded(!isExpanded)}>
        {!isExpanded ? t('general.see_more') : t('general.less')}
      </p>
    </div>
  );
};

export default Simple;