import { ListPayloadType } from 'constant/Types/PermitType';
import { Base } from './Base';

const PermitType = Base.injectEndpoints({
  endpoints: (build) => ({
    listPermitTypePagination: build.mutation({
      query: (params: ListPayloadType) => ({
        url: '/permit-types',
        method: 'GET',
        params
      })
    }),
    postPermitType: build.mutation({
      query: (data: any) => ({
        url: '/permit-types',
        method: 'POST',
        body: data,
      }),
    }),
    putPermitType: build.mutation({
      query: (data: {id?: string, body: any}) => ({
        url: `/permit-types/${data.id}`,
        method: 'PUT',
        body: data.body
      })
    }),
    delPermitType: build.mutation({
      query: (id: any) => ({
        url: `/permit-types/${id}`,
        method: 'DELETE'
      }),
    }),
  })
});

export const { 
  useListPermitTypePaginationMutation,
  usePostPermitTypeMutation,
  usePutPermitTypeMutation,
  useDelPermitTypeMutation,
} = PermitType;