import { Form, Modal, DatePicker, Button, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

const ModalFilter = (props:any) => {
  const { modalAction, filter, filterAction } = props;
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;

  return(
    <Modal
      title={null}
      open={modalAction.isModalOpen}
      footer={null}
      width={500}
      onOk={() => modalAction.setIsModalOpen(false)}
      onCancel={() => modalAction.setIsModalOpen(false)}
    >
      <div className="bg-white p-[10px] rounded-lg">
        <p className='font-bold text-[16px] mb-5'>
          {t('manage_group.filter_label')}
        </p>
        <Form layout="vertical">
          <div className='grid grid-cols-1 gap-1'>
            <div className='col-span-1 mb-3'>
              <Form.Item
                label={t('email_notification.range_send_date')}
                className='font-bold'
              >
                <RangePicker 
                  className='w-full font-normal'
                  placeholder={[t('email_notification.start_date'), t('email_notification.end_date')]}
                  value={filter.filter.sent_date}
                  onChange={(e) => filter.setFilter({ ...filter.filter, sent_date: e })}
                />
              </Form.Item>
            </div>
            <div className='grid grid-cols-2 gap-4 content-end mt-4'>
              <Button
                type='text'
                className='bg-white border-[#55A853] text-[#55A853] font-bold'
                onClick={() => {
                  filterAction.resetFilter();
                }}
                disabled={filterAction.isLoadingFilter}
              >
                {filterAction.isLoadingFilter ? <Spin></Spin> : t('manage_group.reset_btn') || ''}
              </Button>
              <Button
                type='text'
                className='bg-[#55A853] text-white font-bold'
                onClick={() => {
                  filterAction.applyFilter();
                  modalAction.setIsModalOpen(false);
                }}
                disabled={filterAction.isLoadingFilter}
              >
                {filterAction.isLoadingFilter ? <Spin></Spin> : t('manage_group.apply_btn') || ''}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ModalFilter;