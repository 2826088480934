import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  request_number: ''
};

export const permit_request = createSlice({
  name: 'permit_request',
  initialState,
  reducers: {
    setPermitRequest: (state, action) => {
      state.request_number = action.payload.request_number;
    },
  }
});

export const { setPermitRequest } = permit_request.actions;

export default permit_request.reducer;
