import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ConfigProvider, Empty, Spin } from 'antd';
import { toast } from 'react-toastify';
import Toast from 'components/Toast';
import { transformError } from 'utils/ErrorTransformer';
import { useGetListPermitGuidanceMutation } from 'api/PermitGuidance';
import { useTranslation } from 'react-i18next';

const PermitFlowProcess = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [data, setData] = useState<PermitFlowProcessList[]>([]);
  const [defaultImage, setDefaultImage] = useState('');
  
  interface PermitFlowProcessList {
    id: string,
    image: string,
    name: string
  }

  const [getPermitGuidanceList, {
    error: errorGetPermitGuidanceList,
    isError: isErrorGetPermitGuidanceList,
    isLoading
  }] = useGetListPermitGuidanceMutation();

  const handleOnClick = (el: PermitFlowProcessList) => {
    navigate(`/permit-flow-process/deep/${el.name}/${el.id}`);
  };

  const fetchList = async () => {
    const {permit_types, default_image} = await getPermitGuidanceList({}).unwrap();
    setData(permit_types);
    setDefaultImage(default_image);
  };

  useEffect(() => {
    fetchList();
  }, []);

  useEffect(() => {
    if (isErrorGetPermitGuidanceList) {
      const errorMsg: any = errorGetPermitGuidanceList;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorGetPermitGuidanceList, errorGetPermitGuidanceList]);

  return(
    <div>
      {
        isLoading ? (
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#55A853',
              },
            }}
          >
            <Spin className="flex justify-center mt-5" size="large" />
          </ConfigProvider>) :
          !data.length ? (
            <div className="flex justify-center mt-5 pt-5">
              <Empty/>
            </div>
          ) : 
            (<div className='grid grid-cols-1 place-items-center gap-5 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
              {
                data.map((el:PermitFlowProcessList) => (
                  <div 
                    className='flex justify-center bg-white p-3 rounded-lg cursor-pointer mb-3 h-full w-full'
                    onClick={() => handleOnClick(el)}
                  >
                    <div>
                      <div className='h-[280px] flex items-center'>
                        <img 
                          src={el.image ? (process.env.REACT_APP_BASE_URL + 'images/' + el.image) : defaultImage} 
                          alt=''
                          loading='lazy'
                        />
                      </div>
                      <p className='font-bold mt-5 pt-3 text-center text-[24px]'>{el.name}</p>
                    </div>
                  </div>
                ))
              }
            </div>)
      }
    </div>
  );
};

export default PermitFlowProcess;