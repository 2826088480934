import { Button, Form, Modal, Select, Spin, Empty, DatePicker } from 'antd';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useErPicListMutation, useStatusPermitListWfeMutation, useWorklocSearchMutation } from 'api/Options';
import { useListPermitTypePaginationMutation } from 'api/PermitType';
import Toast from 'components/Toast';
import { toast } from 'react-toastify';
import { OptionType } from 'constant/Types/OptionType';
import { ListPayloadType as ListPayloadPermitType } from 'constant/Types/PermitType';
import dayjs from 'dayjs';

const ModalFilter = (props: any) => {
  const { modalAction, filter, filterAction } = props;
  const { t } = useTranslation();
  const [erPic, setErPic] = useState<OptionType[]>([]);
  const [permitTypes, setPermitTypes] = useState<any[]>([]);
  const [worklocations, setWorklocations] = useState<OptionType[]>([]);
  const [statuses, setStatuses] = useState<OptionType[]>([]);
  const [isPeriodDateValid, setIsPeriodDateValid] = useState(false);
  const [isExpiredDateValid, setIsExpiredDateValid] = useState(false);

  const [erPicList, {
    isLoading: isLoadingErPicList,
  }] = useErPicListMutation();

  const [permitTypeList, {
    isLoading: isLoadingPermitTypeList,
  }] = useListPermitTypePaginationMutation();

  const [worklocationList, {
    isLoading: isLoadingWorklocation
  }] = useWorklocSearchMutation();

  const [statusList] = useStatusPermitListWfeMutation();

  const fetchErPic = async (value: any) => {
    if (value.length > 2 || !value) {
      setErPic([]);
      const payload = {
        search: value ?? '',
        searchFields: 'display_name',
        id: ''
      };
      let data = await erPicList(payload).unwrap();

      const newDataErPic = data.map((el: any) => {
        if (el) {
          return {
            label: el?.display_name,
            value: el?.id
          };
        }
        return null;
      });
      setErPic([...newDataErPic]);
    }
  };

  const fetchPermitType = async (value: any) => {
    if (value.length > 2 || !value) {
      setPermitTypes([]);
      const payload: ListPayloadPermitType = {
        search: value ?? '',
        searchFields: 'name'
      };
      let listPermitType = await permitTypeList(payload).unwrap();
      listPermitType = listPermitType?.rows?.map((v: any) => {
        return { label: v?.name, value: v?.id };
      });
      setPermitTypes([...listPermitType]);
    }
  };

  const fetchWorklocations = async (value: any) => {
    if (value.length > 2 || !value) {
      const payload = {
        search: value ?? '',
        searchFields: 'name'
      };
      let { rows } = await worklocationList(payload).unwrap();
      const newDataWorklocation = rows.map((el: any) => {
        if (el) {
          return {
            label: el?.name,
            value: el?.id
          };
        }
        return null;
      });

      setWorklocations(newDataWorklocation.filter((v: any) => v.label));
    }
  };

  const fetchStatus = async (value: any) => {
    if (value.length > 2 || !value) {
      const payload = {
        search: value ?? '',
        searchFields: 'name',
        pageSize: 100
      };
      let { rows } = await statusList(payload).unwrap();
      const filterData = rows.reduce((acc: any, char: any) => acc.includes(char) ? acc : [...acc, char], []);
      const newDataStatus = filterData.map((el: any) => {
        if (el) {
          return {
            label: el?.status_to,
            value: el?.status_from
          };
        }
        return null;
      });

      setStatuses(newDataStatus.filter((v: any) => v.label));
    }
  };

  const validatePeriodDate = (source: string, data: any) => {
    switch (source) {
      case 'from':
        filter.setFilter({ ...filter.filter, period_date_from: data });
        break;
      case 'to':
        filter.setFilter({ ...filter.filter, period_date_to: data });
        break;
      default:
        break;
    }

    const periodDateFrom = source === 'from' ? data : filter.filter?.period_date_from;
    const periodDateTo = source === 'to' ? data : filter.filter?.period_date_to;
    if (periodDateFrom && periodDateTo) {
      setIsPeriodDateValid(false);
      const isValid = dayjs(periodDateTo).isAfter(dayjs(periodDateFrom));
      if (!isValid) {
        setIsPeriodDateValid(true);
        toast.warning(<Toast message={t('general.warning_text')} detailedMessage={t('permit_tracker.date_error')} />);
      }
    }
  };



  const validateExpiredDate = (source: string, data: any) => {
    switch (source) {
      case 'from':
        filter.setFilter({ ...filter.filter, expired_date_from: data });
        break;
      case 'to':
        filter.setFilter({ ...filter.filter, expired_date_to: data });
        break;
      default:
        break;
    }

    const expiredDateFrom = source === 'from' ? data : filter.filter?.expired_date_from;
    const expiredDateTo = source === 'to' ? data : filter.filter?.expired_date_to;
    if (expiredDateFrom && expiredDateTo) {
      setIsExpiredDateValid(false);
      const isValid = dayjs(expiredDateTo).isAfter(dayjs(expiredDateFrom));
      if (!isValid) {
        setIsExpiredDateValid(true);
        toast.warning(<Toast message={t('general.warning_text')} detailedMessage={t('permit_tracker.date_error')} />);
      }
    }
  };

  useEffect(() => {
    fetchPermitType('');
    fetchWorklocations('');
    fetchStatus('');
    fetchErPic('');
  }, []);

  return (
    <Modal
      title={null}
      open={modalAction.isModalOpen}
      footer={null}
      width={500}
      onOk={() => modalAction.setIsModalOpen(false)}
      onCancel={() => modalAction.setIsModalOpen(false)}
    >
      <div className="bg-white p-[10px] rounded-lg">
        <p className='font-bold text-[16px] mb-5'>
          {t('manage_group.filter_label')}
        </p>
        <Form layout="vertical">
          <div className='grid grid-cols-1 gap-1'>
            <div className='col-span-1 mb-3'>
              <Form.Item
                label={t('permit_request.request_information.permit_location')}
                className='font-bold'
              >
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  className='font-normal'
                  style={{ width: '100%' }}
                  placeholder={t('permit_request.request_information.permit_location_placeholder')}
                  onChange={(e) => filter.setFilter({ ...filter.filter, permit_location_id: e })}
                  onSearch={fetchWorklocations}
                  options={worklocations}
                  value={filter.filter.permit_location_id}
                  filterOption={false}
                  notFoundContent={isLoadingWorklocation ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                />
              </Form.Item>
            </div>
            <div className='col-span-1 mb-3'>
              <Form.Item
                label={t('permit_request.request_information.permit_type')}
                className='font-bold'
              >
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  className='font-normal'
                  style={{ width: '100%' }}
                  placeholder={t('permit_request.request_information.permit_type_placeholder')}
                  onChange={(e) => filter.setFilter({ ...filter.filter, permit_type_id: e })}
                  onSearch={fetchPermitType}
                  value={filter.filter.permit_type_id}
                  options={permitTypes}
                  filterOption={false}
                  notFoundContent={isLoadingPermitTypeList ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                />
              </Form.Item>
            </div>
            <div className='col-span-1 mb-3'>
              <Form.Item
                label={t('permit_request.request_information.er_pic_name')}
                className='font-bold'
              >
                <Select
                  mode="multiple"
                  allowClear
                  className='font-normal'
                  style={{ width: '100%' }}
                  placeholder={t('permit_request.request_information.er_pic_name_placeholder')}
                  onChange={(e) => filter.setFilter({ ...filter.filter, pic_name: e })}
                  value={filter.filter.pic_name}
                  onSearch={fetchErPic}
                  options={erPic}
                  showSearch
                  filterOption={false}
                  notFoundContent={isLoadingErPicList ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                />
              </Form.Item>
            </div>
            <div className='col-span-1 mb-3 grid grid-cols-1 lg:grid-cols-12'>
              <Form.Item
                label={t('permit_tracker.period_date')}
                className='font-bold col-span-5'
              >
                <DatePicker
                  className={`w-full font-normal ${isPeriodDateValid ? 'border-red' : ''}`}
                  placeholder={t('permit_tracker.period_date_from_placeholder') || ''}
                  format={'DD-MMM-YYYY'}
                  onChange={(e) => validatePeriodDate('from', e)}
                  value={filter.filter.period_date_from}
                />
              </Form.Item>
              <p className='col-span-2 mx-auto my-2 lg:mt-8 font-bold'>{t('permit_tracker.to_txt')}</p>
              <Form.Item
                className='font-bold col-span-5 my-auto'
              >
                <DatePicker
                  className={`w-full font-normal ${isPeriodDateValid ? 'border-red' : ''}`}
                  placeholder={t('permit_tracker.period_date_to_placeholder') || ''}
                  format={'DD-MMM-YYYY'}
                  onChange={(e) => validatePeriodDate('to', e)}
                  value={filter.filter.period_date_to}
                />
              </Form.Item>
            </div>
            <div className='col-span-1 mb-3 grid grid-cols-1 lg:grid-cols-12'>
              <Form.Item
                label={t('permit_tracker.expired_date')}
                className='font-bold col-span-5'
              >
                <DatePicker
                  className={`w-full font-normal ${isExpiredDateValid ? 'border-red' : ''}`}
                  placeholder={t('permit_tracker.expired_date_from_placeholder') || ''}
                  format={'DD-MMM-YYYY'}
                  value={filter.filter.expired_date_from}
                  onChange={(e) => validateExpiredDate('from', e)}
                />
              </Form.Item>
              <p className='col-span-2 mx-auto my-2 lg:mt-8 font-bold'>{t('permit_tracker.to_txt')}</p>
              <Form.Item
                className='font-bold col-span-5 my-auto'
              >
                <DatePicker
                  className={`w-full font-normal ${isExpiredDateValid ? 'border-red' : ''}`}
                  placeholder={t('permit_tracker.expired_date_to_placeholder') || ''}
                  format={'DD-MMM-YYYY'}
                  value={filter.filter.expired_date_to}
                  onChange={(e) => validateExpiredDate('to', e)}
                />
              </Form.Item>
            </div>
            <div className='col-span-1 mb-3'>
              <Form.Item
                label='Status'
                className='font-bold'
              >
                <Select
                  mode="multiple"
                  allowClear
                  className='font-normal'
                  style={{ width: '100%' }}
                  options={statuses}
                  placeholder={t('manage_group.status_placeholder')}
                  onChange={(e) => filter.setFilter({ ...filter.filter, status: e })}
                  value={filter.filter.status}
                />
              </Form.Item>
            </div>
            <div className='grid grid-cols-2 gap-4 content-end mt-4'>
              <Button
                type='text'
                className='bg-white border-[#55A853] text-[#55A853] font-bold'
                onClick={filterAction.resetFilter}
                disabled={filterAction.isLoadingFilter}
              >
                {filterAction.isLoadingFilter ? <Spin></Spin> : t('manage_group.reset_btn') || ''}
              </Button>
              <Button
                type='text'
                className='bg-[#55A853] text-white font-bold'
                onClick={() => {
                  filterAction.applyFilter();
                  modalAction.setIsModalOpen(false);
                }}
                disabled={filterAction.isLoadingFilter || isPeriodDateValid || isExpiredDateValid}
              >
                {filterAction.isLoadingFilter ? <Spin></Spin> : t('manage_group.apply_btn') || ''}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ModalFilter;
