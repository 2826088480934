import { Button, Form, Modal, Select, Spin, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Indicator } from 'constant/Types/IndicatorType';
import { useListPermitRequestReportIndicatorMutation } from 'api/PermitRequestReport';
import { FaCircle } from 'react-icons/fa';
import { useIssuerInstitutionsListMutation } from 'api/Options';
import { OptionType } from 'constant/Types/OptionType';

const ModalFilter = (props: any) => {
  const { modalAction, filter, filterAction } = props;
  const { t } = useTranslation();
  const { Option } = Select;
  const [indicators, setIndicators] = useState<Indicator[]>([]);
  const [issuerInstitutions, setIssuerInstitutions] = useState<OptionType[]>([]);

  const [getListPermitRequestReportIndicator, {
    isLoading: isLoadingGetListPermitRequestReportIndicator
  }] = useListPermitRequestReportIndicatorMutation();

  const [issuerInstitutionList, {
    isLoading: isLoadingIssuerInstitution
  }] = useIssuerInstitutionsListMutation();

  const fetchIndicator = async () => {
    const data = await getListPermitRequestReportIndicator({}).unwrap();
    setIndicators(data);
  };

  const fetchIssuerInstitutions = async (value: any) => {
    if (value?.length > 2 || !value) {
      const payload = {
        search: value ?? '',
        searchFields: 'name'
      }; 
      let { rows } = await issuerInstitutionList(payload).unwrap();
      const newDataIssuerInstitution = rows.map((el: any) => {
        if (el) {
          return {
            label: el.name,
            value: el.id
          };
        }
        return null;
      });

      setIssuerInstitutions(newDataIssuerInstitution.filter((v: any) => v));
    }
  };

  useEffect(() => {
    fetchIndicator();
    fetchIssuerInstitutions('');
  }, []);

  return (
    <Modal
      title={null}
      open={modalAction.isModalOpen}
      footer={null}
      width={500}
      onOk={() => modalAction.setIsModalOpen(false)}
      onCancel={() => modalAction.setIsModalOpen(false)}
    >
      <div className="bg-white p-[10px] rounded-lg">
        <p className='font-bold text-[16px] mb-5'>
          {t('manage_group.filter_label')}
        </p>
        <Form layout="vertical">
          <div className='grid grid-cols-1 gap-1'>
            <div className='col-span-1 mb-3'>
              <Form.Item
                label={t('dashboard.issuer_institution')}
                className='font-bold'
              >
                <Select
                  mode="multiple"
                  allowClear
                  className='font-normal'
                  style={{ width: '100%' }}
                  placeholder={t('dashboard.issuer_institution_placeholder')}
                  onChange={(e) => filter.setFilter({ ...filter.filter, issuer_institution: e })}
                  value={filter.filter.issuer_institution}
                  filterOption={false}
                  options={issuerInstitutions}
                  onSearch={fetchIssuerInstitutions}
                  onClear={() => { fetchIssuerInstitutions(''); }}
                  notFoundContent={isLoadingIssuerInstitution ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                />
              </Form.Item>
            </div>
            <div className='col-span-1 mb-3'>
              <Form.Item
                label={t('dashboard.companies.permit_status.indicator_label')}
                className='font-bold'
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  onChange={(e) => filter.setFilter({ ...filter.filter, indicator: e })}
                  placeholder={t('permit_request.history_conversation.report.indicator_placeholder')}
                  className='font-normal'
                  value={filter.filter.indicator}
                  filterOption={false}
                  notFoundContent={isLoadingGetListPermitRequestReportIndicator ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                >
                  {indicators.map(item => (
                    <Option key={item.key} value={item.key}>
                      <div className='flex'>
                        <FaCircle className={'my-auto'} style={{color: item.hexacolor}}/>
                        <p className='ml-2'>{item.value}</p>
                      </div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className='grid grid-cols-2 gap-4 content-end mt-4'>
              <Button
                type='text'
                className='bg-white border-[#55A853] text-[#55A853] font-bold'
                onClick={filterAction.resetFilter}
                disabled={filterAction.isLoadingFilter}
              >
                {filterAction.isLoadingFilter ? <Spin></Spin> : t('manage_group.reset_btn') || ''}
              </Button>
              <Button
                type='text'
                className='bg-[#55A853] text-white font-bold'
                onClick={() => {
                  filterAction.applyFilter();
                  modalAction.setIsModalOpen(false);
                }}
                disabled={filterAction.isLoadingFilter}
              >
                {filterAction.isLoadingFilter ? <Spin></Spin> : t('manage_group.apply_btn') || ''}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ModalFilter;