import { Table, Button } from 'antd';
import { useEffect, useState } from 'react';
import { FaFilter } from 'react-icons/fa';

const SimpleDataTable = ({ 
  columns, 
  dataSource, 
  loading, 
  modalOpen, 
  scrollWidth, 
  showHeader = true,
  expandable,
  rowKey = 'id',
}: any) => {
  const [data, setData] = useState(dataSource);

  const onChangeTable = (pagination: any, b: any, sorter: any) => {
    if (sorter.order) {
      const sortedData = [...data].sort((a, b) => {
        const valueA = a[sorter.field];
        const valueB = b[sorter.field];

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return sorter.order === 'ascend'
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        } else if (
          typeof valueA === 'number' &&
					typeof valueB === 'number'
        ) {
          return sorter.order === 'ascend'
            ? valueA - valueB
            : valueB - valueA;
        } else {
          return 0;
        }
      });
      setData(sortedData);
    } else {
      setData(dataSource);
    }
  };

  useEffect(() => {
    setData(dataSource);
  }, [dataSource]);

  return (
    <div>
      <div className='flex justify-end mb-3'>
        {
          modalOpen ?
            (
              <Button
                type='text'
                className='shadow-md rounded-lg p-0 bg-white ml-5'
                onClick={() => modalOpen.setIsModalOpen(!modalOpen.isModalOpen)}
                disabled={data.status === 'Draft'}
              >
                <FaFilter style={{fontSize: '30px'}} className='text-[#55A853] p-1' />
              </Button>
            )
            : (<></>)
        }
      </div>
      <Table
        className="rounded-lg"
        columns={columns}
        pagination={false}
        dataSource={data}
        loading={loading}
        onChange={onChangeTable}
        scroll={{ x: scrollWidth || 0 }}
        showHeader={showHeader}
        expandable={expandable}
        rowKey={rowKey}
      />
    </div>
  );
};

export default SimpleDataTable;
