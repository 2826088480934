import { Empty, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

const AlignmentTab = (props: any) => {
  const { t } = useTranslation();
  const { url, isLoading } = props;

  return(
    <Spin tip="Loading" size='large' spinning={isLoading}>
      {
        url ? 
          (
            <iframe 
              title="alignment" 
              className="h-screen w-full" 
              src={url}
            />
          ) 
          : 
          (
            <Empty 
              className='my-10'
              description={t('dashboard.companies.no_alignment')}
            />
          )
      }
    </Spin>
  );
};

export default AlignmentTab;