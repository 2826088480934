import { Progress } from 'antd';

const checkpoints = [0, 25, 50, 75, 100];

const ProgressBar = ({
  percent = 40,
  color,
  colorStart = '#00f',
  colorEnd = '#f00',
  showTextCheckpoint = true,
}: any) => {
  const strokeColor = color
    ? color
    : colorStart && colorEnd
      ? { '0%': colorStart, '100%': colorEnd }
      : '#000';
    
  return (
    <div className='mx-4'>
      <div style={{ position: 'relative', width: '100%' }}>
        <Progress percent={percent} showInfo={false} strokeColor={strokeColor} />
        {checkpoints.map((checkpoint, index) => (
          <div
            key={index}
            style={{
              position: 'absolute',
              left: `${checkpoint}%`,
              top: showTextCheckpoint ? '80%' : '43%',
              transform: 'translateX(-50%) translateY(-50%)',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width: '12px',
                height: '12px',
                backgroundColor: 'white',
                border: '1px solid black',
                borderRadius: '50%',
              }}
            />
            {showTextCheckpoint && <span style={{ marginTop: '4px', fontSize: '12px', color: 'black' }}>{`${checkpoint}%`}</span>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressBar;