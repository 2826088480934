import { ListPayloadType, UserData, UserEditForm, UserOrganization, ListPayloadUserLogType } from 'constant/Types/UserType';
import type { CredentialType } from 'constant/Types/CredentialType';
import { Base, transformError } from './Base';
import { saveAuthToDb } from 'store/Auth/Thunk';
import { setAuthentication } from 'store/Auth';
import { encodeBase64, encodeObjAES } from 'utils/Encoder';

import {
  DB_CREDENTIAL_USER, KEY_ME
} from 'constant';

const encryptedMeKey = encodeBase64(KEY_ME);

const User = Base.injectEndpoints({
  endpoints: (build) => ({
    me: build.mutation({
      query: () => ({
        url: '/users/me',
        method: 'GET'
      }),
      transformResponse: (response: UserData) => response,
      transformErrorResponse: (response) => transformError(response),
      async onQueryStarted(_, { dispatch, queryFulfilled, }) {
        const { data } = await queryFulfilled;
        const secretUser: CredentialType = {
          _id: DB_CREDENTIAL_USER,
          value: JSON.stringify(data)
        };

        const encryptedMe = encodeObjAES(data);
        localStorage.setItem(encryptedMeKey, encryptedMe);
        await dispatch(saveAuthToDb(secretUser)).unwrap();
        await dispatch(setAuthentication(true));
      }
    }),
    userOrganization: build.mutation({
      query: (body: UserOrganization) => ({
        url: '/users/organization',
        method: 'PATCH',
        body
      }),
      transformResponse: (response) => response,
      transformErrorResponse: (response) => transformError(response)
    }),
    userList: build.mutation({
      query: (params: ListPayloadType) => ({
        url: '/users',
        method: 'GET',
        params
      })
    }),
    activateUser: build.mutation({
      query: (body: any) => ({
        url: `/users/${body.id}`,
        method: 'PATCH',
        body: body.params
      })
    }),
    getUserById: build.mutation({
      query: (id: any) => ({
        url: `/users/detail/${id}`,
        method: 'GET'
      })
    }),
    putUser: build.mutation({
      query: (body: UserEditForm) => ({
        url: `/users/${body.user.id}`,
        method: 'PUT',
        body
      })
    }),
    userLog: build.mutation({
      query: (params: ListPayloadUserLogType) => ({
        url: '/users/log',
        method: 'GET',
        params
      })
    }),
    profileTimezone: build.mutation({
      query: (body: any) => ({
        url: '/users/profile-timezone',
        method: 'PATCH',
        body
      })
    }),
  })
});

export const { 
  useMeMutation, 
  useUserOrganizationMutation, 
  useUserListMutation, 
  useActivateUserMutation,
  useGetUserByIdMutation,
  usePutUserMutation,
  useUserLogMutation,
  useProfileTimezoneMutation
} = User;